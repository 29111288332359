export const nacionalidades = [
  { nome: "Brasileiro(a)", valor: "brasileiro" },
  { nome: "Afegão(ã)", valor: "afegao" },
  { nome: "Alemão(ã)", valor: "alemao" },
  { nome: "Angolano(a)", valor: "angolano" },
  { nome: "Argelino(a)", valor: "argelino" },
  { nome: "Argentino(a)", valor: "argentino" },
  { nome: "Armenio(a)", valor: "armenio" },
  { nome: "Australiano(a)", valor: "australiano" },
  { nome: "Azerbaijano(a)", valor: "azerbaijano" },
  { nome: "Bangladês(a)", valor: "banglades" },
  { nome: "Belga", valor: "belga" },
  { nome: "Boliviano(a)", valor: "boliviano" },
  { nome: "Búlgaro(a)", valor: "bulgaro" },
  { nome: "Camarônes(a)", valor: "camaroes" },
  { nome: "Canadense", valor: "canadense" },
  { nome: "Catariano(a)", valor: "catariano" },
  { nome: "Chileno(a)", valor: "chileno" },
  { nome: "Chinês(a)", valor: "chines" },
  { nome: "Colombiano(a)", valor: "colombiano" },
  { nome: "Coreano(a)", valor: "coreano" },
  { nome: "Costarriquenho(a)", valor: "costarriquenho" },
  { nome: "Cubano(a)", valor: "cubano" },
  { nome: "Dinamarquês(a)", valor: "dinamarques" },
  { nome: "Egípcio(a)", valor: "egipcio" },
  { nome: "Eslovaco(a)", valor: "eslovaco" },
  { nome: "Esloveno(a)", valor: "esloveno" },
  { nome: "Espanhol(a)", valor: "espanhol" },
  { nome: "Estadunidense", valor: "estadunidense" },
  { nome: "Etíope", valor: "etiope" },
  { nome: "Filipino(a)", valor: "filipino" },
  { nome: "Finlandês(a)", valor: "finlandes" },
  { nome: "Francês(a)", valor: "frances" },
  { nome: "Georgiano(a)", valor: "georgiano" },
  { nome: "Grego(a)", valor: "grego" },
  { nome: "Holandês(a)", valor: "holandes" },
  { nome: "Hondurenho(a)", valor: "hondurenho" },
  { nome: "Húngaro(a)", valor: "hungaro" },
  { nome: "Indiano(a)", valor: "indiano" },
  { nome: "Indonésio(a)", valor: "indonesio" },
  { nome: "Inglês(a)", valor: "ingles" },
  { nome: "Iraniano(a)", valor: "iraniano" },
  { nome: "Iraquiano(a)", valor: "iraquiano" },
  { nome: "Islandês(a)", valor: "islandes" },
  { nome: "Israelense", valor: "israelense" },
  { nome: "Italiano(a)", valor: "italiano" },
  { nome: "Japonês(a)", valor: "japones" },
  { nome: "Jordaniano(a)", valor: "jordaniano" },
  { nome: "Kazakh(a)", valor: "kazakh" },
  { nome: "Letão(ã)", valor: "letao" },
  { nome: "Libanês(a)", valor: "libanes" },
  { nome: "Lituano(a)", valor: "lituano" },
  { nome: "Malásio(a)", valor: "malasio" },
  { nome: "Marroquino(a)", valor: "marroquino" },
  { nome: "Mexicano(a)", valor: "mexicano" },
  { nome: "Mongol(a)", valor: "mongol" },
  { nome: "Neozelandês(a)", valor: "neozelandes" },
  { nome: "Nigeriano(a)", valor: "nigeriano" },
  { nome: "Norueguês(a)", valor: "noruegues" },
  { nome: "Paquistanês(a)", valor: "paquistanes" },
  { nome: "Paraguaio(a)", valor: "paraguaio" },
  { nome: "Peruano(a)", valor: "peruano" },
  { nome: "Polonês(a)", valor: "polones" },
  { nome: "Português(a)", valor: "portugues" },
  { nome: "Romeno(a)", valor: "romeno" },
  { nome: "Russo(a)", valor: "russo" },
  { nome: "Senegalês(a)", valor: "senegales" },
  { nome: "Singapurense", valor: "singapurense" },
  { nome: "Sul-Africano(a)", valor: "sulafricano" },
  { nome: "Sueco(a)", valor: "sueco" },
  { nome: "Suíço(a)", valor: "suico" },
  { nome: "Tailandês(a)", valor: "tailandes" },
  { nome: "Tanzaniano(a)", valor: "tanzaniano" },
  { nome: "Turco(a)", valor: "turco" },
  { nome: "Tunisiano(a)", valor: "tunisiano" },
  { nome: "Ucraniano(a)", valor: "ucraniano" },
  { nome: "Ugandês(a)", valor: "ugandes" },
  { nome: "Uruguaio(a)", valor: "uruguaio" },
  { nome: "Uzbeque", valor: "uzbeque" },
  { nome: "Venezuelano(a)", valor: "venezuelano" },
  { nome: "Vietnamita", valor: "vietnamita" },
  { nome: "Zambiano(a)", valor: "zambiano" },
  { nome: "Zimbabuano(a)", valor: "zimbabuano" },
  { nome: "Outro", valor: "outro" }
];


export const estados = [
  { nome: 'Acre', sigla: 'AC' },
  { nome: 'Alagoas', sigla: 'AL' },
  { nome: 'Amapá', sigla: 'AP' },
  { nome: 'Amazonas', sigla: 'AM' },
  { nome: 'Bahia', sigla: 'BA' },
  { nome: 'Ceará', sigla: 'CE' },
  { nome: 'Distrito Federal', sigla: 'DF' },
  { nome: 'Espírito Santo', sigla: 'ES' },
  { nome: 'Goiás', sigla: 'GO' },
  { nome: 'Maranhão', sigla: 'MA' },
  { nome: 'Mato Grosso', sigla: 'MT' },
  { nome: 'Mato Grosso do Sul', sigla: 'MS' },
  { nome: 'Minas Gerais', sigla: 'MG' },
  { nome: 'Pará', sigla: 'PA' },
  { nome: 'Paraíba', sigla: 'PB' },
  { nome: 'Paraná', sigla: 'PR' },
  { nome: 'Pernambuco', sigla: 'PE' },
  { nome: 'Piauí', sigla: 'PI' },
  { nome: 'Rio de Janeiro', sigla: 'RJ' },
  { nome: 'Rio Grande do Norte', sigla: 'RN' },
  { nome: 'Rio Grande do Sul', sigla: 'RS' },
  { nome: 'Rondônia', sigla: 'RO' },
  { nome: 'Roraima', sigla: 'RR' },
  { nome: 'Santa Catarina', sigla: 'SC' },
  { nome: 'São Paulo', sigla: 'SP' },
  { nome: 'Sergipe', sigla: 'SE' },
  { nome: 'Tocantins', sigla: 'TO' },
];

export const generos = [
  { label: "Masculino", value: "Masculino" },
  { label: "Feminino", value: "Feminino" },
];

export const moedas = [
  { sigla: "BRL", nome: "Real Brasileiro" },
  { sigla: "USD", nome: "Dólar Americano" },
  { sigla: "EUR", nome: "Euro" },
  { sigla: "JPY", nome: "Iene Japonês" },
  { sigla: "GBP", nome: "Libra Esterlina" },
  { sigla: "AUD", nome: "Dólar Australiano" },
  { sigla: "CAD", nome: "Dólar Canadense" },
  { sigla: "CHF", nome: "Franco Suíço" },
  { sigla: "CNY", nome: "Yuan Chinês" },
  { sigla: "HKD", nome: "Dólar de Hong Kong" },
  { sigla: "NZD", nome: "Dólar Neozelandês" },
  { sigla: "SEK", nome: "Coroa Sueca" },
  { sigla: "KRW", nome: "Won Sul-Coreano" },
  { sigla: "SGD", nome: "Dólar de Singapura" },
  { sigla: "NOK", nome: "Coroa Norueguesa" },
  { sigla: "MXN", nome: "Peso Mexicano" },
  { sigla: "INR", nome: "Rupia Indiana" },
  { sigla: "RUB", nome: "Rublo Russo" },
  { sigla: "ZAR", nome: "Rand Sul-Africano" },
  { sigla: "TRY", nome: "Lira Turca" },
  { sigla: "TWD", nome: "Novo Dólar Taiwanês" },
  { sigla: "DKK", nome: "Coroa Dinamarquesa" },
  { sigla: "PLN", nome: "Zloty Polonês" },
  { sigla: "THB", nome: "Baht Tailandês" },
  { sigla: "IDR", nome: "Rupia Indonésia" },
  { sigla: "HUF", nome: "Florim Húngaro" },
  { sigla: "CZK", nome: "Coroa Checa" },
  { sigla: "ILS", nome: "Novo Shekel Israelense" },
  { sigla: "CLP", nome: "Peso Chileno" },
  { sigla: "PHP", nome: "Peso Filipino" }
];

export const tiposDeConta = [
  { nome: "Conta Corrente", valor: "corrente" },
  { nome: "Conta Investimento", valor: "investimento" },
  { nome: "Conta Poupança", valor: "poupança" },
  { nome: "Conta Garantida", valor: "garantida" },
  { nome: "Dinheiro em Caixa", valor: "dinheiro" }
];

export const status = [
  { nome: "Ativo", valor: "ativo" },
  { nome: "Inativo", valor: "inativo" }
];

// Funções de cartão de crédito
export const funcoesCartao = [
  { nome: "Débito", valor: "debito" },
  { nome: "Crédito", valor: "credito" },
  { nome: "Alimentação", valor: "alimentacao" },
  { nome: "Múltiplo", valor: "multiplo" }
];

// Status do cartão de crédito
export const statusCartao = [
  { nome: "Ativo", valor: "ativo" },
  { nome: "Bloqueado", valor: "bloqueado" },
  { nome: "Inativo", valor: "inativo" }
];

// Adicione esta nova constante
export const tiposOperacao = [
  { valor: 'receitas', nome: 'Receitas' },
  { valor: 'despesas', nome: 'Despesas' },
  { valor: 'investimentos', nome: 'Investimentos' }
];

export const tiposTransacao = [
  { valor: 'receita', nome: 'Receita' },
  { valor: 'receita_parcelada', nome: 'Receita Parcelada' },
  { valor: 'despesa', nome: 'Despesa' },
  { valor: 'despesa_parcelada', nome: 'Despesa Parcelada' },
  { valor: 'transferencia', nome: 'Transferência entre Contas' },
  { valor: 'pagamento_cartao', nome: 'Pagamento de Cartão de Crédito' }
];

export const statusPagamento = [
  { valor: 'pago', nome: 'Pago' },
  { valor: 'agendado', nome: 'Agendado' },
  { valor: 'aberto', nome: 'Aberto' },
  { valor: 'cancelado', nome: 'Cancelado' },
  { valor: 'confirmado', nome: 'Confirmado' },
];

// Adicione esta nova constante
export const dateRanges = [
  { label: '', value: '' }, // Adicionando a opção vazia
  { label: 'Últimos 7 dias', value: 7 },
  { label: 'Últimos 15 dias', value: 15 },
  { label: 'Últimos 30 dias', value: 30 },
  { label: 'Últimos 60 dias', value: 60 },
  { label: 'Últimos 90 dias', value: 90 },
];

export const statusOrcamento = [
  { nome: "Orçamento", valor: "orcamento" },
  { nome: "Aprovado", valor: "aprovado" },
  { nome: "Concluído", valor: "concluido" },
  { nome: "Cancelado", valor: "cancelado" }
];
