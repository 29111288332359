import React, { useState, useEffect, useContext } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import { getUserData, getProfileDataByCpf } from '../services/profile/userProfileService';
import { getCompanyData } from '../services/profile/companyProfileService';
import { ProfileContext } from '../context/profileContext';
import { isProfileSelected } from '../utils/profileUtils';
import { getInitials } from '../utils/helpers';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; // Novo ícone de menos
import './profileSelector.css';

const ProfileSelector = () => {
  const { selectedProfiles, addProfile, removeProfile } = useContext(ProfileContext);
  const [personalProfiles, setPersonalProfiles] = useState([]);
  const [companyProfiles, setCompanyProfiles] = useState([]);
  const userCpf = localStorage.getItem('userCpf');
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const [hoveredProfile, setHoveredProfile] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const userData = await getUserData();
        
        const allCpfs = [userCpf, ...userData.perfil_compartilhado];

        const fetchedPersonalProfiles = await Promise.all(
          allCpfs.map(async (cpf) => {
            const personData = await getProfileDataByCpf(cpf);
            return {
              cpf,
              name: `${personData.nome} ${personData.sobrenome}`,
              image: personData.profile_image || null,
            };
          })
        );

        const fetchedCompanyProfiles = await Promise.all(
          userData.membro_empresa.map(async (cnpj) => {
            const companyData = await getCompanyData(cnpj);
            return {
              cnpj,
              name: companyData.razao_social,
              image: companyData.logo_image || null,
            };
          })
        );

        setPersonalProfiles(fetchedPersonalProfiles);
        setCompanyProfiles(fetchedCompanyProfiles);

        if (selectedProfiles.length === 0 && !initialCheckDone) {
          const userProfile = fetchedPersonalProfiles.find(profile => profile.cpf === userCpf);
          if (userProfile) {
            addProfile(userProfile);
          }
          setInitialCheckDone(true);
        }
      } catch (error) {
        console.error('Erro ao buscar perfis:', error);
      }
    };

    fetchProfiles();
  }, [userCpf, selectedProfiles.length, addProfile, initialCheckDone]);

  const handleSelectProfile = (profile) => {
    const isSelected = isProfileSelected(selectedProfiles, profile.cpf || profile.cnpj);
    if (isSelected) {
      removeProfile(profile.cpf || profile.cnpj);
    } else {
      addProfile(profile);
    }
  };

  const renderProfile = (profile, isSelected) => (
    <ListItem onClick={() => handleSelectProfile(profile)} key={profile.cpf || profile.cnpj}>
      <ListItemIcon>
        <div className="profileAvatarSmall">
          {profile.image ? (
            <img src={profile.image} alt={profile.name} />
          ) : (
            <div className="profileInitials">{getInitials(profile.name)}</div>
          )}
        </div>
      </ListItemIcon>
      <ListItemText primary={profile.name} />
      {isSelected && <span className="selectedIndicator">✓</span>}
    </ListItem>
  );

  return (
    <div className="profileSelector">
      <div className="selectedProfiles">
        {selectedProfiles.map(profile => (
          <div 
            key={profile.cpf || profile.cnpj} 
            className="selectedProfileWrapper"
            onMouseEnter={() => setHoveredProfile(profile.cpf || profile.cnpj)}
            onMouseLeave={() => setHoveredProfile(null)}
          >
            <div className="profileAvatarSmall">
              {hoveredProfile === (profile.cpf || profile.cnpj) ? (
                <div 
                  className="removeIconOverlay" 
                  onClick={() => removeProfile(profile.cpf || profile.cnpj)}
                >
                  <RemoveCircleOutlineIcon /> {/* Ícone de menos */}
                </div>
              ) : (
                <>
                  {profile.image ? (
                    <img src={profile.image} alt={profile.name} />
                  ) : (
                    <div className="profileInitials">{getInitials(profile.name)}</div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        <div className="addProfileContainer">
          <div className="addProfileIcon">+</div>
          <div className="profileSelectorDropdown">
            <List component="nav">
              <Box mb={2}>
                <strong>Perfis Pessoais</strong>
              </Box>
              {personalProfiles.map(profile =>
                renderProfile(profile, isProfileSelected(selectedProfiles, profile.cpf))
              )}
              <Box mt={4} mb={2}>
                <strong>Perfis Empresariais</strong>
              </Box>
              {companyProfiles.map(profile =>
                renderProfile(profile, isProfileSelected(selectedProfiles, profile.cnpj))
              )}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSelector;
