// src/components/shared/ListAccounts.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Box, Button, Switch, FormControlLabel, Stack
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ProfileContext } from '../../../../context/profileContext';
import { getInitials, formatCurrency } from '../../../../utils/helpers';

import { getAccountByIdentifier } from '../../../../services/finance/accountsService';

const ListAccounts = ({
  accounts,
  onSelectAccount,
  selectedAccountId,
  setAccounts,
  showRegisterButton = true,
  onRegisterNewAccount,
  showInactiveToggle = true,
  showManageLink = true,
  actionIcons = [],
}) => {
  const [localAccounts, setLocalAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showInactive, setShowInactive] = useState(false);
  const { selectedProfiles } = useContext(ProfileContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const fetchedAccounts = await Promise.all(
          selectedProfiles.map(profile =>
            getAccountByIdentifier(profile.cpf || profile.cnpj)
          )
        );
        const accountsData = fetchedAccounts.flat();
        setLocalAccounts(accountsData);
        if (setAccounts) setAccounts(accountsData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (selectedProfiles.length > 0) {
      fetchAccounts();
    } else {
      setLocalAccounts([]);
      setLoading(false);
    }
  }, [selectedProfiles, setAccounts]);

  useEffect(() => {
    if (accounts) {
      setLocalAccounts(accounts);
    }
  }, [accounts]);

  const handleShowInactiveChange = () => {
    setShowInactive(!showInactive);
  };

  const filteredAccounts = showInactive
    ? localAccounts
    : localAccounts.filter(account => account.status === 'ativo');

  const handleManageAccounts = () => {
    navigate('/bank-accounts');
  };

  const handleSelectAccount = accountId => {
    if (onSelectAccount) {
      onSelectAccount(accountId);
    }
  };

  return (
    <div className="dataCard">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" align="left">
          Contas Bancárias:
        </Typography>
        {showInactiveToggle && (
          <FormControlLabel
            control={<Switch checked={showInactive} onChange={handleShowInactiveChange} />}
            label={<Typography variant="body2">Exibir Contas Inativas</Typography>}
            labelPlacement="start"
            style={{ marginRight: 0 }}
          />
        )}
      </Stack>
      <Box my={2}></Box>
      {loading ? (
        <Typography variant="body2">Carregando contas...</Typography>
      ) : error ? (
        <Typography variant="body2">Erro ao carregar contas: {error.message}</Typography>
      ) : selectedProfiles.length === 0 ? (
        <Typography variant="body2">
          Selecione um perfil para visualizar as contas associadas.
        </Typography>
      ) : filteredAccounts.length === 0 ? (
        <Typography variant="body2">
          Nenhuma conta encontrada para os perfis selecionados.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Banco</TableCell>
                <TableCell>Ag/Conta</TableCell>
                <TableCell>Titular</TableCell>
                <TableCell>Saldo</TableCell>
                {actionIcons.length > 0 && <TableCell>Ações</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAccounts.map(account => (
                <TableRow
                  key={account.id}
                  onClick={() => handleSelectAccount(account.id)}
                  hover
                  selected={account.id === selectedAccountId}
                  style={{ cursor: onSelectAccount ? 'pointer' : 'default' }}
                >
                  <TableCell>
                    {account.logo_image ? (
                      <img
                        src={account.logo_image}
                        alt="Logo Banco"
                        className="companyLogoSmall"
                      />
                    ) : (
                      <div className="companyLogoSmall">{getInitials(account.nome_banco)}</div>
                    )}
                  </TableCell>
                  <TableCell>{account.nome_banco}</TableCell>
                  <TableCell>{`${account.agencia}/${account.numero_conta}-${account.digito_conta}`}</TableCell>
                  <TableCell>{account.titular_cpf || account.titular_cnpj}</TableCell>
                  <TableCell>{formatCurrency(account.ultimo_saldo ?? 0)}</TableCell>
                  {actionIcons.length > 0 && (
                    <TableCell>
                      {actionIcons.map((icon, index) => (
                        <Button
                          key={index}
                          onClick={e => {
                            e.stopPropagation();
                            icon.onClick(account);
                          }}
                        >
                          {icon.component}
                        </Button>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box my={2}></Box>
      {showRegisterButton && (
        <Button
          fullWidth
          type="button"
          variant="outlined"
          size="large"
          onClick={onRegisterNewAccount}
          disabled={selectedProfiles.length === 0}
        >
          Cadastrar nova conta
        </Button>
      )}
      {showManageLink && (
        <Button
          fullWidth
          type="button"
          variant="text"
          size="large"
          onClick={handleManageAccounts}
          disabled={selectedProfiles.length === 0}
        >
          Gerenciar Contas Bancárias
        </Button>
      )}
    </div>
  );
};

export default ListAccounts;
