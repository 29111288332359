import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './avatar.css'; 
import { getProfileDataByCpf } from '../services/profile/userProfileService'; 
import { getInitials } from '../utils/helpers'; 
import { handleLogout } from '../services/auth/authService';
import { List, ListItem, ListItemText, ListItemIcon, Button, Box } from '@mui/material'; // Importando Box do Material-UI
import { Settings, Business } from '@mui/icons-material';

const Avatar = () => {
  const [profileData, setProfileData] = useState(null);
  const userEmail = localStorage.getItem('userEmail'); // Mantendo apenas a leitura do email
  const userCpf = localStorage.getItem('userCpf');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      if (userCpf) {
        const data = await getProfileDataByCpf(userCpf);
        setProfileData(data);
      }
    };

    fetchProfileData();
  }, [userCpf]);

  const renderAvatarContent = () => {
    if (profileData && profileData.profile_image) {
      return (
        <img
          src={profileData.profile_image}
          alt={`${profileData.nome} ${profileData.sobrenome}`}
        />
      );
    } else if (profileData) {
      return getInitials(profileData.nome, profileData.sobrenome);
    } else {
      return 'U';
    }
  };

  return (
    <div className="avatar">
      <div className="profileAvatarSmall">
        {renderAvatarContent()}
      </div>
      <div className="avatar__dropdown">
        {profileData && (
          <>
            <p className="avatar__name">{`${profileData.nome} ${profileData.sobrenome}`}</p>
            <p className="avatar__email">{userEmail}</p>
            <hr />
            <List component="div" disablePadding className="avatar__list">
              <ListItem onClick={() => navigate('/user-profiles')}>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Perfis de Usuário" />
              </ListItem>
              <ListItem onClick={() => navigate('/company-profiles')}>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary="Perfis Empresariais" />
              </ListItem>
            </List>
            <hr />
            <Box mt={2} /> {/* Adiciona espaço entre a lista e o botão */}
          </>
        )}
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => handleLogout(navigate)}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Avatar;
