// categoryInfo.js

import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, FormControl, InputLabel, Typography, Stack
} from '@mui/material';

import { tiposOperacao, status as statusOptions } from '../../../../config/constants';
import { OperationSelector } from '../../../../utils/formUtils';

import { updateFinancialOperation } from '../../../../services/finance/financialOperationService';
import { checkOwnership } from '../../../../services/auth/authService';

const FinancialOperationInfo = ({ open, onClose, operation }) => {
  const [operationData, setOperationData] = useState(operation);
  const [originalData, setOriginalData] = useState(operation);
  const [isOwner, setIsOwner] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const verifyOwnership = async () => {
      const ownershipStatus = await checkOwnership(operation);
      setIsOwner(ownershipStatus);
    };

    setOperationData(operation);
    setOriginalData(operation);
    verifyOwnership();
    setIsChanged(false);
  }, [operation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOperationData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setIsChanged(true);
  };

  const handleSaveChanges = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const modifiedFields = {};
      Object.keys(operationData).forEach((key) => {
        if (operationData[key] !== originalData[key]) {
          modifiedFields[key] = operationData[key];
        }
      });
      if (Object.keys(modifiedFields).length > 0) {
        await updateFinancialOperation(operationData.id, modifiedFields);
        setSuccessMessage('Operação atualizada com sucesso.');
        setIsChanged(false);
        onClose();
      }
    } catch (error) {
      setErrorMessage('Erro ao atualizar operação.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{isOwner ? 'Editar Operação' : 'Visualizar Operação'}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="body2" color="primary" gutterBottom>
            {successMessage}
          </Typography>
        )}
        <Stack spacing={2}>
          <Stack>
            <TextField
              margin="dense"
              name="nome"
              label="Nome"
              type="text"
              fullWidth
              value={operationData.nome}
              onChange={handleChange}
              disabled={!isOwner}
            />
          </Stack>
          <Stack>
            <TextField
              margin="dense"
              name="descricao"
              label="Descrição"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={operationData.descricao}
              onChange={handleChange}
              disabled={!isOwner}
            />
          </Stack>
          <Stack>
            <OperationSelector
              value={operationData.operacao_pai_id || ''}
              onChange={(value) => handleChange({ target: { name: 'operacao_pai_id', value } })}
              titular={operation.titular_cpf || operation.titular_cnpj}
              disabled={!isOwner}
              label="Operação Pai"
            />
          </Stack>
          <Stack>
            <TextField
              margin="dense"
              name="ordem_exibicao"
              label="Ordem de Exibição"
              type="number"
              fullWidth
              value={operationData.ordem_exibicao || ''}
              onChange={handleChange}
              disabled={!isOwner}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Stack>
          <Stack>
            <FormControl fullWidth margin="dense">
              <InputLabel id="tipo_operacao_label">Tipo de Operação</InputLabel>
              <Select
                labelId="tipo_operacao_label"
                name="tipo_operacao"
                value={operationData.tipo_operacao}
                onChange={handleChange}
                label="Tipo de Operação"
                disabled={!isOwner}
              >
                {tiposOperacao.map(tipo => (
                  <MenuItem key={tipo.valor} value={tipo.valor}>{tipo.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack>
            <FormControl fullWidth margin="dense">
              <InputLabel id="status_label">Status</InputLabel>
              <Select
                labelId="status_label"
                name="status"
                value={operationData.status}
                onChange={handleChange}
                label="Status"
                disabled={!isOwner}
              >
                {statusOptions.map(status => (
                  <MenuItem key={status.valor} value={status.valor}>{status.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Fechar</Button>
        {isOwner && isChanged && (
          <Button onClick={handleSaveChanges} color="primary">Salvar Alterações</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FinancialOperationInfo;
