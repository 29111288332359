import React, { useState, useRef, useEffect } from 'react';
import { FaRobot } from 'react-icons/fa'; // Ícone de AI
import './aiAssistant.css';

const AiAssistant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const chatBoxRef = useRef(null);


  const handleClickOutside = (event) => {
    if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="aiAssistant">
      <button
        className="aiAssistant__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FaRobot /> {/* Ícone de AI */}
      </button>
      {isOpen && (
        <div ref={chatBoxRef} className="aiAssistant__chatbox">
          <div className="aiAssistant__header">
            <h4>EI MONA...</h4>
            <button onClick={() => setIsOpen(false)}>X</button>
          </div>
          <div className="aiAssistant__body">
            <p>Olá! Como posso ajudar você hoje?</p>
            {/* Implementação do chat pode ser feita aqui */}
          </div>
          <div className="aiAssistant__footer">
            <input type="text" placeholder="Como posso te ajudar?" />
            <button>Enviar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AiAssistant;
