import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  Stack,
  Modal,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { v4 as uuidv4 } from 'uuid';

import { ProfileContext } from '../../../../context/profileContext';
import {
  AccountSelector,
  CreditCardSelector,
} from '../../../../utils/formUtils';
import { dateRanges } from '../../../../config/constants';
import { formatCurrency } from '../../../../utils/helpers';

import { getTransactions } from '../../../../services/finance/transactionService';
import { getCreditCardByID } from '../../../../services/finance/creditCardService';
import { getAccountByID } from '../../../../services/finance/accountsService';
import { getBankByID } from '../../../../services/finance/bankService';

import SingleTransactionForm from './singleTransactionForm';
import MultipleTransactionsForm from './multipleTransactionsForm';

const LastTransactions = ({
  onSelectTransaction,
  transactions,
  setTransactions,
  selectedAccountId = null,
  selectedCreditCardId = null,
  showAccountFilter = true,
  showCreditCardFilter = true,
  initialDateRange = '7',
  showRegisterButton = true,
  tableHeight = 800, // Novo prop para parametrizar a altura da tabela
  onFiltersChange,
  initialFilters,
  onRegisterTransaction,
  selectedTitular = null,
  passAccountOrCardInfoToModals = false, // Nova prop para controlar o comportamento
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { selectedProfiles } = useContext(ProfileContext);
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [selectedCreditCardName, setSelectedCreditCardName] = useState('');

  const [filters, setFilters] = useState(initialFilters);
  const [isInitialized, setIsInitialized] = useState(false);

  const [showSingleTransactionModal, setShowSingleTransactionModal] = useState(false);
  const [showMultipleTransactionsModal, setShowMultipleTransactionsModal] = useState(false);

  // Efeito para inicializar as datas se não forem fornecidas
  useEffect(() => {
    if (!isInitialized) {
      const today = new Date();
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - parseInt(initialDateRange, 10));

      setFilters((prevFilters) => ({
        ...prevFilters,
        data_inicio: prevFilters.data_inicio || pastDate.toISOString().split('T')[0],
        data_fim: prevFilters.data_fim || today.toISOString().split('T')[0],
        dateRange: prevFilters.dateRange || initialDateRange,
      }));
      setIsInitialized(true);
    }
  }, [initialFilters, isInitialized, initialDateRange]);

  // Atualiza os filtros quando selectedAccountId muda
  useEffect(() => {
    if (selectedAccountId) {
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      id_conta_bancaria: selectedAccountId || '',
    }));
  }, [selectedAccountId, selectedTitular]);

  // Atualiza os filtros quando selectedCreditCardId muda
  useEffect(() => {
    if (selectedCreditCardId) {
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      id_cartao: selectedCreditCardId || '',
    }));
  }, [selectedCreditCardId, selectedTitular]);

  // Função para buscar as transações, memoizada com useCallback
  const fetchTransactions = useCallback(
    async () => {
      if (selectedProfiles.length === 0 || !isInitialized) {
        setTransactions([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const allTransactions = [];

        for (const profile of selectedProfiles) {
          const appliedFilters = { ...filters };
          appliedFilters.titular_cpf = profile.cpf;
          appliedFilters.titular_cnpj = profile.cnpj;

          const fetchedTransactions = await getTransactions(appliedFilters);
          const transactionsWithProfile = fetchedTransactions
            .filter(
              (tx) =>
                tx && typeof tx === 'object' && tx.id !== undefined && tx.id !== null
            )
            .map((tx) => ({
              ...tx,
              profileData: profile, // Alterado de 'profile' para 'profileData'
            }));
          allTransactions.push(...transactionsWithProfile);
        }

        setTransactions(allTransactions);
        setError(null); // Limpa erro anterior
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [filters, setTransactions, isInitialized, selectedProfiles]
  );

  // Chama fetchTransactions sempre que filters, selectedProfiles ou isInitialized mudarem
  useEffect(() => {
    if (isInitialized) {
      fetchTransactions();
      // Adicione esta linha para atualizar os filtros no componente pai
      onFiltersChange(filters);
    }
  }, [fetchTransactions, selectedProfiles, isInitialized, filters, onFiltersChange]);

  const handleDateRangeChange = (value) => {
    if (value) {
      const days = parseInt(value, 10);
      const today = new Date();
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - days);
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: value,
        data_inicio: pastDate.toISOString().split('T')[0],
        data_fim: today.toISOString().split('T')[0],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: '',
        data_inicio: '',
        data_fim: '',
      }));
    }
  };

  const handleClearFilters = () => {
    setFilters({
      data_inicio: '',
      data_fim: '',
      tipo_data: 'data_hora',
      id_conta_bancaria: selectedAccountId || '',
      id_cartao: '',
      tipo_transacao: '',
      status_pagamento: '',
      operacao_financeira: '',
      dateRange: initialDateRange,
    });
  };

  const handleFilterChange = useCallback((name, value) => {
    setFilters((prevFilters) => {
      let newFilters = { ...prevFilters, [name]: value };

      // Se um cartão for selecionado, limpe a conta e vice-versa
      if (name === 'id_cartao' && value) {
        newFilters.id_conta_bancaria = '';
      } else if (name === 'id_conta_bancaria' && value) {
        newFilters.id_cartao = '';
      }

      onFiltersChange(newFilters);
      return newFilters;
    });
  }, [onFiltersChange]);


  useEffect(() => {
    const fetchAccountOrCardDetails = async () => {
      if (selectedAccountId) {
        try {
          const account = await getAccountByID(selectedAccountId);
          const bank = await getBankByID(account.id_banco);
          const accountName = `${bank.nome_banco} | ${account.agencia} / ${account.numero_conta}-${account.digito_conta}`;
          setSelectedAccountName(accountName);
        } catch (error) {
          console.error('Erro ao buscar detalhes da conta:', error);
        }
      } else if (selectedCreditCardId) {
        try {
          const creditCard = await getCreditCardByID(selectedCreditCardId);
          setSelectedCreditCardName(creditCard.apelido);
        } catch (error) {
          console.error('Erro ao buscar detalhes do cartão de crédito:', error);
        }
      }
    };

    fetchAccountOrCardDetails();
  }, [selectedAccountId, selectedCreditCardId]);

  // Definição das colunas
  const columns = [
    {
      field: 'data_vencimento',
      headerName: 'Vencimento',
      width: 100,
      renderCell: (params) => {
        if (!params.row || !params.row.data_vencimento) {
          return '';
        }
        const date = new Date(params.row.data_vencimento);
        if (isNaN(date.getTime())) {
          return '';
        }
        return date.toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'tipo_transacao',
      headerName: 'Tipo',
      width: 50,
      renderCell: (params) => {
        if (!params.row || !params.row.tipo_transacao) {
          return '';
        }
        const tipo = params.row.tipo_transacao;
        if (tipo === 'receita') {
          return 'R';
        } else if (tipo === 'despesa') {
          return 'D';
        } else if (tipo === 'receita_parcelada') {
          return 'RP'; // Adicionado
        } else if (tipo === 'despesa_parcelada') {
          return 'DP'; // Adicionado
        } else if (tipo === 'transferencia') {
          return 'T'; // Adicionado
        } else if (tipo === 'pagamento_cartao') {
          return 'PC'; // Adicionado
        } else {
          return '';
        }
      },
    },
    {
      field: 'status_pagamento',
      headerName: 'Status',
      width: 60,
      renderCell: (params) => {
        if (!params.row || !params.row.status_pagamento) {
          return '';
        }
        const status = params.row.status_pagamento;
        switch (status) {
          case 'pago':
            return 'PG';
          case 'aberto':
            return 'AB';
          case 'agendado':
            return 'AG';
          case 'cancelado':
            return 'CN';
          case 'confirmado':
            return 'CF';
          default:
            return '-';
        }
      },
    },
    {
      field: 'nome_operacao_financeira',
      headerName: 'Op. Financeira',
      width: 100,
    },
    {
      field: 'nome_resumido',
      headerName: 'Descrição',
      width: 180,
      renderCell: (params) => {
        if (!params.row) return '';
        
        const nomeResumido = params.row.nome_resumido || '';
        const numeroParcela = params.row.numero_parcela;
        const totalParcelas = params.row.parcelas;
        
        let parcelasInfo = '';
        if (numeroParcela && totalParcelas) {
          parcelasInfo = `[${numeroParcela}/${totalParcelas}] `;
        }
        
        return `${parcelasInfo}${nomeResumido}`;
      },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 110,
      renderCell: (params) => {
        if (!params.row || params.row.valor_parcela === undefined || params.row.valor_parcela === null) {
          return '';
        }
        const numero = parseFloat(params.row.valor_parcela);
        if (isNaN(numero)) {
          return '';
        }
        return formatCurrency(numero);
      },
    },
    {
      field: 'titular',
      headerName: 'Titular',
      width: 180,
      renderCell: (params) => {
        if (params && params.row) {
          const titularCpf = params.row.titular_cpf;
          const titularCnpj = params.row.titular_cnpj;
          const profileCpf = params.row.profileData?.cpf;
          const profileCnpj = params.row.profileData?.cnpj;

          return titularCpf || titularCnpj || profileCpf || profileCnpj || '';
        }
        return '';
      },
    },
  ];

  const handleSingleTransactionRegistered = (newTransaction) => {
    setTransactions([newTransaction, ...transactions]);
    setShowSingleTransactionModal(false);
  };

  const handleMultipleTransactionsRegistered = (newTransactions) => {
    setTransactions([...newTransactions, ...transactions]);
    setShowMultipleTransactionsModal(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'auto',
  };

  // Atualizar a função handleRegisterTransaction
  const handleRegisterTransaction = (isMultiple = false) => {
    if (isMultiple) {
      setShowMultipleTransactionsModal(true);
    } else {
      setShowSingleTransactionModal(true);
    }
  };

  return (
    <div className="dataCard">
      <Typography variant="h5" align="left">
        Últimas Transações:
      </Typography>
      <Box my={2}></Box>
      {selectedAccountName && (
        <Typography variant="subtitle1" align="left">
          {selectedAccountName}
        </Typography>
      )}
      {selectedCreditCardName && (
        <Typography variant="subtitle1" align="left">
          {selectedCreditCardName}
        </Typography>
      )}

      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          {showAccountFilter && (
            <AccountSelector
              value={filters.id_conta_bancaria}
              onChange={(value) => handleFilterChange('id_conta_bancaria', value)}
              useContextProfiles={true} 
            />
          )}
          {showCreditCardFilter && (
            <CreditCardSelector
              value={filters.id_cartao}
              onChange={(value) => handleFilterChange('id_cartao', value)}
              useContextProfiles={true} 
            />
          )}
        </Stack>
        <Stack spacing={2} direction="row">
          <TextField
            fullWidth
            label="Data Inicial"
            type="date"
            name="data_inicio"
            value={filters.data_inicio}
            onChange={(e) => handleFilterChange('data_inicio', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="Data Final"
            type="date"
            name="data_fim"
            value={filters.data_fim}
            onChange={(e) => handleFilterChange('data_fim', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            select
            fullWidth
            label="Tipo de Data"
            name="tipo_data"
            value={filters.tipo_data}
            onChange={(e) => handleFilterChange('tipo_data', e.target.value)}
          >
            <MenuItem value="data_hora">Data da Transação</MenuItem>
            <MenuItem value="data_vencimento">Data de Vencimento</MenuItem>
            <MenuItem value="data_criacao">Data de Criação</MenuItem>
            <MenuItem value="data_atualizacao">Data de Atualização</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            label="Intervalo de Datas"
            value={filters.dateRange}
            onChange={(e) => handleDateRangeChange(e.target.value)}
          >
            <MenuItem value="">Nenhum</MenuItem>
            {dateRanges.map((range) => (
              <MenuItem key={range.value} value={range.value}>
                {range.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>


      </Stack>
      <Box my={2}></Box>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={handleClearFilters}
      >
        Limpar Filtros
      </Button>
      <Box my={2}></Box>
      {showRegisterButton && (
        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleRegisterTransaction(false)}
          >
            Cadastrar Nova Transação
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleRegisterTransaction(true)}
          >
            Cadastrar Múltiplas Transações
          </Button>
        </Stack>
      )}
          <Box my={2}></Box>
      <Modal
        open={showSingleTransactionModal}
        onClose={() => setShowSingleTransactionModal(false)}
        aria-labelledby="modal-single-transaction"
        aria-describedby="modal-single-transaction-form"
      >
        <Box sx={modalStyle}>
          <SingleTransactionForm
            initialAccountId={passAccountOrCardInfoToModals ? filters.id_conta_bancaria : undefined}
            initialCreditCardId={passAccountOrCardInfoToModals ? filters.id_cartao : undefined}
            initialTitular={passAccountOrCardInfoToModals ? selectedTitular : undefined}
            onTransactionRegistered={handleSingleTransactionRegistered}
            setErrorMessage={(message) => {
              console.error(message);
            }}
          />
        </Box>
      </Modal>

      <Modal
        open={showMultipleTransactionsModal}
        onClose={() => setShowMultipleTransactionsModal(false)}
        aria-labelledby="modal-multiple-transactions"
        aria-describedby="modal-multiple-transactions-form"
      >
        <Box sx={modalStyle}>
          <MultipleTransactionsForm
            initialAccountId={passAccountOrCardInfoToModals ? filters.id_conta_bancaria : undefined}
            initialCreditCardId={passAccountOrCardInfoToModals ? filters.id_cartao : undefined}
            initialTitular={passAccountOrCardInfoToModals ? selectedTitular : undefined}
            onTransactionRegistered={handleMultipleTransactionsRegistered}
            setErrorMessage={(message) => {
              console.error(message);
            }}
          />
        </Box>
      </Modal>
      {loading ? (
        <Typography variant="body2">Carregando transações...</Typography>
      ) : error ? (
        <Typography variant="body2">
          Erro ao carregar transações: {error.message}
        </Typography>
      ) : transactions.length === 0 ? (
        <Typography variant="body2">Nenhuma transação encontrada.</Typography>
      ) : (
        <div style={{ height: tableHeight, width: '100%' }}>
          <DataGrid
            rows={transactions}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection={false}
            disableSelectionOnClick
            onRowClick={(params) => {
              if (onSelectTransaction && params.row && params.row.id) {
                onSelectTransaction(params.row.id);
              }
            }}
            getRowId={(row) => row.id || uuidv4()}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      )}
      <Box my={2}></Box>
    </div>
  );
};

export default LastTransactions;
