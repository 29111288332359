import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { ProductCategorySelector } from '../../../../utils/formUtils';

import { createProductCategory } from '../../../../services/inventory/productCategoryService';

const RegisterCategory = ({ open, onClose, parentCategory, allCategories, profileIdentifier }) => {
  const [categoryData, setCategoryData] = useState({
    nome: '',
    descricao: '',
    categoria_pai_id: parentCategory ? parentCategory.id : null,
    ordem_exibicao: 0,
    status: 'ativa',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (parentCategory) {
      setCategoryData(prevData => ({
        ...prevData,
        categoria_pai_id: parentCategory.id,
      }));
    } else {
      setCategoryData(prevData => ({
        ...prevData,
        categoria_pai_id: null,
      }));
    }
  }, [parentCategory]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const newCategoryData = {
        ...categoryData,
        titular_cpf: profileIdentifier.replace(/\D/g, '').length === 11 ? profileIdentifier : null,
        titular_cnpj: profileIdentifier.replace(/\D/g, '').length === 14 ? profileIdentifier : null,
        nivel: parentCategory ? parentCategory.nivel + 1 : 1,
      };

      await createProductCategory(newCategoryData);
      setSuccessMessage('Categoria criada com sucesso.');
      setLoading(false);
      onClose();
    } catch (error) {
      setErrorMessage(error.message || 'Erro ao criar categoria.');
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{parentCategory ? 'Adicionar Subcategoria' : 'Adicionar Categoria Principal'}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="body2" color="primary" gutterBottom>
            {successMessage}
          </Typography>
        )}
        <Stack spacing={2}>
          <TextField
            autoFocus
            margin="dense"
            name="nome"
            label="Nome"
            type="text"
            fullWidth
            value={categoryData.nome}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="descricao"
            label="Descrição"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={categoryData.descricao}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="status_label">Status</InputLabel>
            <Select
              labelId="status_label"
              name="status"
              value={categoryData.status}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value="ativa">Ativa</MenuItem>
              <MenuItem value="inativa">Inativa</MenuItem>
            </Select>
          </FormControl>
          {!parentCategory && (
            <ProductCategorySelector
              value={categoryData.categoria_pai_id || ''}
              onChange={(value) => handleChange({ target: { name: 'categoria_pai_id', value } })}
              titular={profileIdentifier}
              disabled={false}
              label="Categoria Pai"
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterCategory; 