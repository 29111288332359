import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton, Button } from '@mui/material';
import { VerifiedUser, Group } from '@mui/icons-material';

import { getInitials } from '../../../../utils/helpers';

import { getCompanyData } from '../../../../services/profile/companyProfileService';
import { getUserTeamMember } from '../../../../services/profile/userProfileService';

const CompanyProfilesCard = ({ refreshProfiles, onSelectCnpj, onRegisterCompany, selectedCnpj }) => {
  const [companyProfiles, setCompanyProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userCpf = localStorage.getItem('userCpf');  // Removido setUserCpf

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cnpjs = await getUserTeamMember(); // Obtém os CNPJs das empresas em que o usuário é membro

        const profiles = await Promise.all(
          cnpjs.map(async (cnpj) => {
            const companyData = await getCompanyData(cnpj);
            return companyData;
          })
        );

        setCompanyProfiles(profiles);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [refreshProfiles, userCpf]);

  const handleSelectProfile = (cnpj) => {
    onSelectCnpj(cnpj);
  };

  const handleRegisterCompany = () => {
    onRegisterCompany();
  };

  if (loading) {
    return <Typography variant="body2">Carregando dados...</Typography>;
  }

  if (error) {
    return <Typography variant="body2">Erro ao carregar dados: {error.message}</Typography>;
  }

  return (
    <div className="dataCard">
      <Typography variant="h5">Seus Perfis Empresariais:</Typography>
      <Box my={2}></Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Razão Social</TableCell>
              <TableCell>CNPJ</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyProfiles.length > 0 ? (
              companyProfiles.map(company => {
                const isOwner = userCpf === company.cpf_responsavel;

                return (
                  <TableRow 
                    key={company.cnpj} 
                    selected={selectedCnpj === company.cnpj}
                    onClick={() => handleSelectProfile(company.cnpj)}
                    hover
                  >
                    <TableCell>
                      {company.logo_image ? (
                        <img
                          src={company.logo_image}
                          alt="Company Logo"
                          className="companyLogoSmall"
                        />
                      ) : (
                        <div className="companyLogoSmall">
                          {getInitials(company.razao_social)}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{company.razao_social}</TableCell>
                    <TableCell>{company.cnpj}</TableCell>
                    <TableCell>
                      {isOwner ? (
                        <IconButton title="Você é um administrador desta empresa">
                          <VerifiedUser className="companyActionIcon" />
                        </IconButton>
                      ) : (
                        <IconButton title="Você é um colaborador nesta empresa">
                          <Group className="companyActionIcon" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography variant="body2">Nenhum perfil empresarial encontrado.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}></Box> {/* Adiciona um espaçamento */} 
      <Button
        fullWidth
        type="button"
        variant="outlined"
        size="large"
        onClick={handleRegisterCompany}
      >
        Cadastrar nova empresa
      </Button>
    </div>
  );
};

export default CompanyProfilesCard;
