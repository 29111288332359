// src/modules/finance/transactions/cards/SingleTransactionForm.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
} from '@mui/material';

import { tiposTransacao, statusPagamento } from '../../../../config/constants';
import {
  TitularSelector,
  AccountSelector,
  CreditCardSelector,
  OperationSelector,
} from '../../../../utils/formUtils';

import { createTransaction } from '../../../../services/finance/transactionService';

const SingleTransactionForm = ({
  initialAccountId,
  initialCreditCardId,
  initialTitular,
  onTransactionRegistered,
}) => {
  const [transactionData, setTransactionData] = useState({
    titular: initialTitular || '',
    nome_resumido: '',
    tipo_transacao: '',
    valor: '',
    parcelas: 1,
    id_conta_bancaria: initialAccountId || '',
    id_cartao: initialCreditCardId || '',
    data_hora: new Date().toISOString().split('T')[0],
    descricao: '',
    categoria: '',
    notas_adicionais: '',
    parcela_detalhes: [],
    status_pagamento: '', // Inicialize com uma string vazia
  });

  const [showParcelasDetails, setShowParcelasDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Log das informações recebidas
  useEffect(() => {
    console.log('Informações recebidas no SingleTransactionForm:', {
      initialAccountId,
      initialCreditCardId,
      initialTitular,
    });
  }, [initialAccountId, initialCreditCardId, initialTitular]);

  // Atualize o estado inicial com os valores recebidos
  useEffect(() => {
    setTransactionData((prev) => {
      const newData = {
        ...prev,
        titular: initialTitular || '',
        id_conta_bancaria: initialAccountId || '',
        id_cartao: initialCreditCardId || '',
      };

      // Inicializa parcela_detalhes com status_pagamento 'pago' se houver cartão de crédito
      if (initialCreditCardId) {
        newData.parcela_detalhes = [{
          numero_parcela: 1,
          valor_parcela: newData.valor,
          data_vencimento: newData.data_hora,
          numero_documento: '',
          status_pagamento: 'pago'
        }];
      }

      return newData;
    });
  }, [initialTitular, initialAccountId, initialCreditCardId]);

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : { name: e.name, value: e.value };
    
    setTransactionData((prev) => {
      const newData = { ...prev, [name]: value };
      
      if (name === 'titular') {
        newData.categoria = '';
        newData.id_conta_bancaria = '';
        newData.id_cartao = '';
        newData.parcela_detalhes = [];
      }
      
      if (name === 'id_cartao' && value) {
        newData.id_conta_bancaria = '';
        newData.parcela_detalhes = newData.parcela_detalhes.map(parcela => ({ ...parcela, status_pagamento: 'pago' }));
      } else if (name === 'id_conta_bancaria' && value) {
        newData.id_cartao = '';
        newData.parcela_detalhes = newData.parcela_detalhes.map(parcela => ({ ...parcela, status_pagamento: '' }));
      }

      if (name === 'valor' || name === 'parcelas' || name === 'data_hora') {
        if (['receita_parcelada', 'despesa_parcelada'].includes(newData.tipo_transacao)) {
          newData.parcela_detalhes = calcularParcelas(newData.valor, newData.parcelas, newData.data_hora);
        } else if (['receita', 'despesa'].includes(newData.tipo_transacao)) {
          newData.parcela_detalhes = [{
            ...newData.parcela_detalhes[0],
            valor_parcela: newData.valor,
            data_vencimento: newData.parcela_detalhes[0]?.data_vencimento || newData.data_hora
          }];
        }
      }
      
      if (['transferencia', 'pagamento_cartao'].includes(newData.tipo_transacao)) {
        newData.parcela_detalhes = [{
          numero_parcela: 1,
          valor_parcela: newData.valor,
          data_vencimento: newData.data_hora,
          numero_documento: '',
          status_pagamento: newData.parcela_detalhes[0]?.status_pagamento || '' // Mantenha o status_pagamento existente
        }];
        delete newData.status_pagamento; // Remove o status_pagamento do nível principal
      }
      
      return newData;
    });
  };

  const calcularParcelas = (valor, numeroParcelas, dataTransacao) => {
    const valorTotal = parseFloat(valor);
    const numParcelas = parseInt(numeroParcelas, 10);
    const dataInicial = new Date(dataTransacao);
    
    if (numParcelas > 0 && !isNaN(valorTotal) && valorTotal > 0) {
      const valorParcelaExato = valorTotal / numParcelas;
      const valorParcelaArredondado = Math.floor(valorParcelaExato * 100) / 100; // Arredonda para baixo com 2 casas decimais
      const diferencaTotal = valorTotal - (valorParcelaArredondado * numParcelas);
      
      const novasParcelas = Array.from({ length: numParcelas }, (_, index) => {
        let valorParcela = valorParcelaArredondado;
        if (index === 0) {
          // Adiciona a diferença à primeira parcela
          valorParcela = (valorParcelaArredondado + diferencaTotal).toFixed(2);
        }
        const dataVencimento = new Date(dataInicial);
        dataVencimento.setMonth(dataVencimento.getMonth() + index);
        return {
          numero_parcela: index + 1,
          valor_parcela: parseFloat(valorParcela).toFixed(2), // Alterado de 'valor' para 'valor_parcela'
          data_vencimento: dataVencimento,
          numero_documento: transactionData.numero_documento,
          status_pagamento: transactionData.id_cartao ? 'pago' : '',
        };
      });

      return novasParcelas.map(parcela => ({
        ...parcela,
        status_pagamento: transactionData.id_cartao ? 'pago' : ''
      }));
    }
    return [];
  };

  const handleParcelaChange = (index, field, value) => {
    setTransactionData((prev) => {
      const novasParcelas = [...prev.parcela_detalhes];
      novasParcelas[index] = { ...novasParcelas[index], [field]: value };
      return { ...prev, parcela_detalhes: novasParcelas };
    });
  };

  // Função para registrar a transação
  const handleRegister = async () => {
    try {
      let dataToSend = { ...transactionData };
      
      // Remover status_pagamento do corpo principal
      delete dataToSend.status_pagamento;

      // Garantir que parcela_detalhes existe e tem pelo menos um item
      if (!dataToSend.parcela_detalhes || dataToSend.parcela_detalhes.length === 0) {
        dataToSend.parcela_detalhes = [{
          numero_parcela: 1,
          valor_parcela: dataToSend.valor,
          data_vencimento: dataToSend.data_hora,
          numero_documento: dataToSend.numero_documento,
          status_pagamento: dataToSend.id_cartao ? 'pago' : ''
        }];
      }

      console.log('Dados da transação antes do envio:', dataToSend); // Log para depuração
      
      if (!dataToSend.categoria && !['transferencia', 'pagamento_cartao'].includes(dataToSend.tipo_transacao)) {
        setErrorMessage('Por favor, selecione uma categoria.');
        setSuccessMessage('');
        return;
      }

      const newTransaction = await createTransaction(dataToSend);
      onTransactionRegistered(newTransaction);
      setErrorMessage('');
      setSuccessMessage('Transação registrada com sucesso!');
    } catch (error) {
      console.error('Erro ao registrar transação:', error);
      setErrorMessage(error.message || 'Erro ao registrar transação.');
      setSuccessMessage('');
    }
  };

  const renderTransactionFields = () => {
    switch (transactionData.tipo_transacao) {
      case 'receita':
      case 'despesa':
        return renderSingleParcelaDetails();
      case 'receita_parcelada':
      case 'despesa_parcelada':
        return (
          <>
            {renderParcelasInput()}
            {showParcelasDetails && renderParcelasDetails()}
          </>
        );
      case 'transferencia':
        return renderTransferenciaFields();
      case 'pagamento_cartao':
        return renderPagamentoCartaoFields();
      default:
        return null;
    }
  };

  const renderSingleParcelaDetails = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '10px' }}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Data Vencimento"
          variant="outlined"
          type="date"
          value={transactionData.parcela_detalhes[0]?.data_vencimento || transactionData.data_hora}
          name="data_vencimento"
          onChange={(e) => handleParcelaChange(0, 'data_vencimento', e.target.value)}
          size="small"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Número Documento"
          variant="outlined"
          value={transactionData.parcela_detalhes[0]?.numero_documento || ''}
          name="numero_documento"
          onChange={(e) => handleParcelaChange(0, 'numero_documento', e.target.value)}
          size="small"
        />
      </Grid>
      {transactionData.id_conta_bancaria && !transactionData.id_cartao && (
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            label="Status de Pagamento"
            variant="outlined"
            value={transactionData.parcela_detalhes[0]?.status_pagamento || ''}
            name="status_pagamento"
            onChange={(e) => handleParcelaChange(0, 'status_pagamento', e.target.value)}
            size="small"
          >
            {statusPagamento.map((status) => (
              <MenuItem key={status.valor} value={status.valor}>
                {status.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );

  const renderParcelasInput = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Número de Parcelas"
          variant="outlined"
          type="number"
          inputProps={{ min: 1, step: 1 }}
          value={transactionData.parcelas}
          name="parcelas"
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={8} style={{ marginTop: '8px' }}>
        <Button
          variant="outlined"
          onClick={() => setShowParcelasDetails(!showParcelasDetails)}
        >
          {showParcelasDetails ? "Ocultar" : "Exibir"} Detalhes das Parcelas
        </Button>
      </Grid>
    </Grid>
  );

  const renderParcelasDetails = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      {transactionData.parcela_detalhes.map((parcela, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className="parcelaTitle" style={{ fontWeight: 'bold', marginBottom: '-8px' }}>
              Parcela {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Data Vencimento"
                  variant="outlined"
                  type="date"
                  value={parcela.data_vencimento instanceof Date && !isNaN(parcela.data_vencimento) 
                    ? parcela.data_vencimento.toISOString().split('T')[0] 
                    : ''}
                  onChange={(e) => handleParcelaChange(index, 'data_vencimento', new Date(e.target.value))}
                  disabled={!transactionData.valor}
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Número Documento"
                  variant="outlined"
                  value={parcela.numero_documento}
                  onChange={(e) => handleParcelaChange(index, 'numero_documento', e.target.value)}
                  disabled={!transactionData.valor}
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Valor"
                  variant="outlined"
                  type="number"
                  value={parcela.valor_parcela}
                  onChange={(e) => handleParcelaChange(index, 'valor_parcela', parseFloat(e.target.value))}
                  disabled={!transactionData.valor}
                  size="small"
                />
              </Grid>
              {transactionData.id_conta_bancaria && (
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    label="Status de Pagamento"
                    variant="outlined"
                    value={parcela.status_pagamento || ''}
                    onChange={(e) => handleParcelaChange(index, 'status_pagamento', e.target.value)}
                    size="small"
                  >
                    {statusPagamento.map((status) => (
                      <MenuItem key={status.valor} value={status.valor}>
                        {status.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );

  const renderTransferenciaFields = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      <Grid item xs={12} sm={4}>
        <TitularSelector
          value={transactionData.titular_destino}
          onChange={(value) => handleChange({ name: 'titular_destino', value })}
          label="Titular de Destino"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AccountSelector
          value={transactionData.id_conta_bancaria_destino}
          onChange={(value) => handleChange({ name: 'id_conta_bancaria_destino', value })}
          titular={transactionData.titular_destino}
          label="Conta Destino"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Status de Pagamento"
          variant="outlined"
          value={transactionData.parcela_detalhes[0]?.status_pagamento || ''}
          name="status_pagamento"
          onChange={(e) => handleParcelaChange(0, 'status_pagamento', e.target.value)}
        >
          {statusPagamento.map((status) => (
            <MenuItem key={status.valor} value={status.valor}>
              {status.nome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );

  const renderPagamentoCartaoFields = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      <Grid item xs={12} sm={4}>
        <TitularSelector
          value={transactionData.titular_destino}
          onChange={(value) => handleChange({ name: 'titular_destino', value })}
          label="Titular do Cartão"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CreditCardSelector
          value={transactionData.id_cartao_destino}
          onChange={(value) => handleChange({ name: 'id_cartao_destino', value })}
          titular={transactionData.titular_destino}
          label="Cartão a Pagar"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Status de Pagamento"
          variant="outlined"
          value={transactionData.parcela_detalhes[0]?.status_pagamento || ''}
          name="status_pagamento"
          onChange={(e) => handleParcelaChange(0, 'status_pagamento', e.target.value)}
        >
          <MenuItem value="">Selecione um status</MenuItem>
          {statusPagamento.map((status) => (
            <MenuItem key={status.valor} value={status.valor}>
              {status.nome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );

  return (
    <div className="accountInfoCard fullWidthCard">
      <Box>
        <Typography variant="h6" className="transactionTitle" gutterBottom>
          Cadastrar Nova Transação
        </Typography>
        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="body2" color="primary" gutterBottom>
            {successMessage}
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TitularSelector
              value={transactionData.titular}
              onChange={(value) => handleChange({ name: 'titular', value })}
              disabled={!!initialTitular} // Desabilita se initialTitular for recebido
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AccountSelector
              value={transactionData.id_conta_bancaria}
              onChange={(value) => handleChange({ name: 'id_conta_bancaria', value })}
              titular={transactionData.titular}
              disabled={!!initialAccountId || !!initialCreditCardId} // Desabilita se initialAccountId ou initialCreditCardId forem recebidos
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CreditCardSelector
              value={transactionData.id_cartao}
              onChange={(value) => handleChange({ name: 'id_cartao', value })}
              titular={transactionData.titular}
              disabled={!!initialAccountId || !!initialCreditCardId} // Desabilita se initialAccountId ou initialCreditCardId forem recebidos
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nome da Transação"
              variant="outlined"
              value={transactionData.nome_resumido}
              name="nome_resumido"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data da Transação"
              variant="outlined"
              type="date"
              value={transactionData.data_hora}
              name="data_hora"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              label="Tipo de Transação"
              variant="outlined"
              name="tipo_transacao"
              value={transactionData.tipo_transacao}
              onChange={handleChange}
              required
            >
              {tiposTransacao.map((tipo) => (
                <MenuItem key={tipo.valor} value={tipo.valor}>
                  {tipo.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Valor Total"
              variant="outlined"
              type="number"
              value={transactionData.valor}
              name="valor"
              onChange={handleChange}
              required
            />
          </Grid>
          {renderTransactionFields()}
          {['receita', 'receita_parcelada', 'despesa', 'despesa_parcelada'].includes(transactionData.tipo_transacao) && (
            <Grid item xs={12} sm={6}>
              <OperationSelector
                value={transactionData.operacao_financeira}
                onChange={(value) => handleChange({ name: 'operacao_financeira', value })}
                titular={transactionData.titular}
                disabled={!transactionData.titular}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrição"
              variant="outlined"
              multiline
              rows={2}
              value={transactionData.descricao}
              name="descricao"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notas Adicionais"
              variant="outlined"
              multiline
              rows={3}
              value={transactionData.notas_adicionais}
              name="notas_adicionais"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" onClick={handleRegister}>
              Registrar Transação
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SingleTransactionForm;
