import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Stack,
} from '@mui/material';

import { ProductCategorySelector } from '../../../../utils/formUtils';

import { updateProductCategory } from '../../../../services/inventory/productCategoryService';
import { checkOwnership } from '../../../../services/auth/authService';

const CategoryInfo = ({ open, onClose, category }) => {
  const [categoryData, setCategoryData] = useState(category);
  const [originalData, setOriginalData] = useState(category);
  const [isOwner, setIsOwner] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const verifyOwnership = async () => {
      const ownershipStatus = await checkOwnership(category);
      setIsOwner(ownershipStatus);
    };

    setCategoryData(category);
    setOriginalData(category);
    verifyOwnership();
    setIsChanged(false);
  }, [category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsChanged(true);
  };

  const handleSaveChanges = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const modifiedFields = {};
      Object.keys(categoryData).forEach((key) => {
        if (categoryData[key] !== originalData[key]) {
          modifiedFields[key] = categoryData[key];
        }
      });
      if (Object.keys(modifiedFields).length > 0) {
        await updateProductCategory(categoryData.id, modifiedFields);
        setSuccessMessage('Categoria atualizada com sucesso.');
        setIsChanged(false);
        onClose();
      }
    } catch (error) {
      setErrorMessage('Erro ao atualizar categoria.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{isOwner ? 'Editar Categoria' : 'Visualizar Categoria'}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="body2" color="primary" gutterBottom>
            {successMessage}
          </Typography>
        )}
        <Stack spacing={2}>
          <TextField
            margin="dense"
            name="nome"
            label="Nome"
            type="text"
            fullWidth
            value={categoryData.nome}
            onChange={handleChange}
            disabled={!isOwner}
          />
          <TextField
            margin="dense"
            name="descricao"
            label="Descrição"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={categoryData.descricao}
            onChange={handleChange}
            disabled={!isOwner}
          />
          <ProductCategorySelector
            value={categoryData.categoria_pai_id || ''}
            onChange={(value) => handleChange({ target: { name: 'categoria_pai_id', value } })}
            titular={category.titular_cpf || category.titular_cnpj}
            disabled={!isOwner}
            label="Categoria Pai"
          />
          <TextField
            margin="dense"
            name="ordem_exibicao"
            label="Ordem de Exibição"
            type="number"
            fullWidth
            value={categoryData.ordem_exibicao || ''}
            onChange={handleChange}
            disabled={!isOwner}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="status_label">Status</InputLabel>
            <Select
              labelId="status_label"
              name="status"
              value={categoryData.status}
              onChange={handleChange}
              label="Status"
              disabled={!isOwner}
            >
              <MenuItem value="ativa">Ativa</MenuItem>
              <MenuItem value="inativa">Inativa</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Fechar
        </Button>
        {isOwner && isChanged && (
          <Button onClick={handleSaveChanges} color="primary">
            Salvar Alterações
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CategoryInfo; 