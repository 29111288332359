import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, MenuItem, Stack, Box } from '@mui/material';
import { tiposDeConta, moedas, status } from '../../../../config/constants';
import { getInitials } from '../../../../utils/helpers';

import { getAccountByID, updateAccount } from '../../../../services/finance/accountsService';
import { getCompanyData } from '../../../../services/profile/companyProfileService';
import { getProfileDataByCpf } from '../../../../services/profile/userProfileService';
import { checkOwnership } from '../../../../services/auth/authService'; // Importar a nova função




const AccountInfo = ({ accountId, onUpdateAccountList }) => {
  const [accountData, setAccountData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [titularName, setTitularName] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        setErrorMessage('');
        setSuccessMessage('');
        const account = await getAccountByID(accountId);
        const formattedData = {
          ...account,
          codigo_banco: account.codigo_banco || '',
          nome_banco: account.nome_banco || '',
          agencia: account.agencia || '',
          numero_conta: account.numero_conta || '',
          digito_conta: account.digito_conta || '',
          descricao: account.descricao || '',
          tipo_conta: account.tipo_conta || '',
          moeda: account.moeda || '',
          status: account.status || '',
          logo_image: account.logo_image || ''
        };
        setAccountData(formattedData);
        setOriginalData(formattedData);
        const isOwnerResult = await checkOwnership(formattedData); // Usar a nova função
        setIsOwner(isOwnerResult);
        if (formattedData.titular_cpf) {
          await fetchTitularName(formattedData.titular_cpf);
        } else if (formattedData.titular_cnpj) {
          await fetchTitularCompanyName(formattedData.titular_cnpj);
        }
        setLoading(false);
      } catch (err) {
        setError('Erro ao buscar dados da conta');
        setLoading(false);
      }
    };

    fetchAccountData();
  }, [accountId]);

  const fetchTitularName = async (cpf) => {
    try {
      const profileData = await getProfileDataByCpf(cpf);
      setTitularName(`${profileData.nome} ${profileData.sobrenome}`);
    } catch (err) {
      setError('Erro ao buscar dados do titular');
    }
  };

  const fetchTitularCompanyName = async (cnpj) => {
    try {
      const companyData = await getCompanyData(cnpj);
      setTitularName(companyData.razao_social);
    } catch (err) {
      setError('Erro ao buscar dados da empresa titular');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAccountData({ ...accountData, [name]: value });
    setIsChanged(JSON.stringify({ ...accountData, [name]: value }) !== JSON.stringify(originalData));
  };

  const getModifiedFields = () => {
    const modifiedFields = {};
    Object.keys(accountData).forEach((key) => {
      if (accountData[key] !== originalData[key]) {
        modifiedFields[key] = accountData[key];
      }
    });
    return modifiedFields;
  };

  const handleSaveChanges = async () => {
    const modifiedFields = getModifiedFields();
    if (Object.keys(modifiedFields).length > 0) {
      try {
        const updatedAccount = await updateAccount(accountId, modifiedFields);
        setOriginalData(accountData); // Atualizar os dados originais
        setIsChanged(false);
        setSuccessMessage('Dados atualizados com sucesso!');  // Define a mensagem de sucesso
        setErrorMessage('');  // Limpa a mensagem de erro

        // Chama a função para atualizar a lista de contas no ListAccounts
        if (onUpdateAccountList) {
          onUpdateAccountList(updatedAccount);
        }
      } catch (err) {
        setErrorMessage('Erro ao salvar alterações');  // Define a mensagem de erro
        setSuccessMessage('');  // Limpa a mensagem de sucesso
      }
    }
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!accountData) {
    return <Typography color="error">Conta não encontrada.</Typography>;
  }

  return (
    <div className="accountInfoCard">
      <div className="accountHeader">
        {accountData.logo_image ? (
          <img src={accountData.logo_image} alt="Logo do Banco" className="bankLogo" />
        ) : (
          <div className="bankLogo">
            {getInitials(accountData.nome_banco)}
          </div>
        )}
        <div className="bankName">
          <Typography variant="h5">{accountData.nome_banco}</Typography>
        </div>
      </div>
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Cód. Banco"
            variant="outlined"
            name="codigo_banco"
            value={accountData.codigo_banco}
            onChange={handleChange}
            fullWidth
            disabled
          />
          <TextField
            label="Agência"
            variant="outlined"
            name="agencia"
            value={accountData.agencia}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          />
          <TextField
            label="Número da Conta"
            variant="outlined"
            name="numero_conta"
            value={accountData.numero_conta}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          />
          <TextField
            label="Dígito"
            variant="outlined"
            name="digito_conta"
            value={accountData.digito_conta}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            select
            label="Tipo de Conta"
            variant="outlined"
            name="tipo_conta"
            value={accountData.tipo_conta}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          >
            {tiposDeConta.map((tipo) => (
              <MenuItem key={tipo.valor} value={tipo.valor}>{tipo.nome}</MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Moeda"
            variant="outlined"
            name="moeda"
            value={accountData.moeda}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          >
            {moedas.map((moeda) => (
              <MenuItem key={moeda.sigla} value={moeda.sigla}>{moeda.nome}</MenuItem>
            ))}
          </TextField>
        </Stack>
        <TextField
          label="Descrição"
          variant="outlined"
          name="descricao"
          value={accountData.descricao}
          onChange={handleChange}
          fullWidth
          disabled={!isOwner}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            select
            label="Status"
            variant="outlined"
            name="status"
            value={accountData.status}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          >
            {status.map((option) => (
              <MenuItem key={option.valor} value={option.valor}>
                {option.nome}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {accountData.titular_cpf && (
          <Stack direction="row" spacing={2}>
            <TextField
              label="Nome do Titular"
              variant="outlined"
              value={titularName}
              fullWidth
              disabled
            />
            <TextField
              label="CPF do Titular"
              variant="outlined"
              value={accountData.titular_cpf}
              fullWidth
              disabled
            />
          </Stack>
        )}
        {accountData.titular_cnpj && (
          <Stack direction="row" spacing={2}>
            <TextField
              label="Razão Social da Empresa"
              variant="outlined"
              value={titularName}
              fullWidth
              disabled
            />
            <TextField
              label="CNPJ do Titular"
              variant="outlined"
              value={accountData.titular_cnpj}
              fullWidth
              disabled
            />
          </Stack>
        )}
      </Stack>
      <Box mt={2}>
        {isChanged && isOwner && (
          <Button fullWidth variant="contained" color="primary" onClick={handleSaveChanges}>
            Salvar Alterações
          </Button>
        )}
      </Box>
    </div>
  );
};

export default AccountInfo;
