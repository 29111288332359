// src/modules/erp/productCategory/cards/productCategoriesCard.js

import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  Add,
  Edit,
  Visibility,
  ChevronRight,
} from '@mui/icons-material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ProfileContext } from '../../../../context/profileContext';
import './productCategoriesCard.css';

import {
  getProductCategoriesByIdentifier,
  updateProductCategory,
} from '../../../../services/inventory/productCategoryService';
import { getCompanyData } from '../../../../services/profile/companyProfileService';

import RegisterCategory from './registerCategory';
import CategoryInfo from './categoryInfo';

const ItemTypes = {
  CATEGORY: 'category',
};

const ProductCategoriesCard = () => {
  const { selectedProfiles } = useContext(ProfileContext);
  const [categoriesByProfile, setCategoriesByProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [showInactive, setShowInactive] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedNodeIds, setExpandedNodeIds] = useState(() => {
    const savedExpandedNodes = localStorage.getItem('expandedNodeIds');
    return savedExpandedNodes ? JSON.parse(savedExpandedNodes) : {};
  });
  const [selectedProfileIdentifier, setSelectedProfileIdentifier] = useState(null);
  const [expandAll, setExpandAll] = useState(false);
  const userCpf = localStorage.getItem('userCpf');

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const categoriesData = {};
        for (const profile of selectedProfiles) {
          const identifier = profile.cpf || profile.cnpj;
          const fetchedCategories = await getProductCategoriesByIdentifier(identifier);
          categoriesData[identifier] = {
            profile,
            categories: fetchedCategories,
            treeData: buildTreeData(fetchedCategories, identifier),
          };
        }
        setCategoriesByProfile(categoriesData);
        setLoading(false);
      } catch (error) {
        setErrorMessage('Erro ao carregar categorias.');
        setLoading(false);
      }
    };

    if (selectedProfiles.length > 0) {
      fetchCategories();
    } else {
      setCategoriesByProfile({});
      setLoading(false);
    }
  }, [selectedProfiles]);

  const buildTreeData = (categoriesList, identifier) => {
    const idToNodeMap = {};
    categoriesList.forEach((category) => {
      if (category.id) {
        const nodeId = `${identifier}-${category.id}`;
        idToNodeMap[category.id] = {
          ...category,
          nodeId,
          children: [],
        };
      }
    });

    const tree = [];
    categoriesList.forEach((category) => {
      if (category.id) {
        const parentId = category.categoria_pai_id;
        if (parentId && idToNodeMap[parentId]) {
          idToNodeMap[parentId].children.push(idToNodeMap[category.id]);
        } else {
          tree.push(idToNodeMap[category.id]);
        }
      }
    });

    return tree;
  };

  const handleAddSubCategory = (e, node, profileIdentifier) => {
    e.stopPropagation();
    setSelectedCategory(node);
    setSelectedProfileIdentifier(profileIdentifier);
    setOpenRegisterModal(true);
  };

  const handleEditCategory = (e, node) => {
    e.stopPropagation();
    setSelectedCategory(node);
    setOpenInfoModal(true);
  };

  const handleViewCategory = (e, node) => {
    e.stopPropagation();
    setSelectedCategory(node);
    setOpenInfoModal(true);
  };

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
    setSelectedCategory(null);
    refreshCategories();
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
    setSelectedCategory(null);
    refreshCategories();
  };

  const refreshCategories = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const categoriesData = {};
      for (const profile of selectedProfiles) {
        const identifier = profile.cpf || profile.cnpj;
        const fetchedCategories = await getProductCategoriesByIdentifier(identifier);
        categoriesData[identifier] = {
          profile,
          categories: fetchedCategories,
          treeData: buildTreeData(fetchedCategories, identifier),
        };
      }
      setCategoriesByProfile(categoriesData);
      setLoading(false);
    } catch (error) {
      setErrorMessage('Erro ao atualizar categorias.');
      setLoading(false);
    }
  };

  const filterCategories = (nodes) => {
    return nodes
      .filter((node) => showInactive || node.status === 'ativa')
      .map((node) => ({
        ...node,
        children: filterCategories(node.children || []),
      }));
  };

  const handleToggleNode = (nodeId) => {
    setExpandedNodeIds((prev) => {
      const newExpanded = { ...prev, [nodeId]: !prev[nodeId] };
      localStorage.setItem('expandedNodeIds', JSON.stringify(newExpanded));
      return newExpanded;
    });
  };

  const handleToggleAllNodes = () => {
    setExpandAll(!expandAll);
    const allNodeIds = Object.values(categoriesByProfile).flatMap(({ treeData }) =>
      getAllNodeIds(treeData)
    );
    const newExpandedNodeIds = {};
    allNodeIds.forEach((nodeId) => {
      newExpandedNodeIds[nodeId] = !expandAll;
    });
    setExpandedNodeIds(newExpandedNodeIds);
    localStorage.setItem('expandedNodeIds', JSON.stringify(newExpandedNodeIds));
  };

  const getAllNodeIds = (nodes) => {
    return nodes.flatMap((node) => [
      node.nodeId,
      ...(node.children ? getAllNodeIds(node.children) : []),
    ]);
  };

  const handleToggleInactive = () => {
    setShowInactive((prev) => !prev);
  };

  // Função para verificar a propriedade da categoria
  const checkOwnership = useCallback(async (category) => {
    if (category.titular_cpf === userCpf) {
      return true;
    } else if (category.titular_cnpj) {
      try {
        const company = await getCompanyData(category.titular_cnpj);
        return company && company.cpf_responsavel === userCpf;
      } catch (error) {
        console.error('Erro ao verificar propriedade da empresa:', error);
        return false;
      }
    }
    return false;
  }, [userCpf]);

  // Função para mover a categoria (se desejar implementar arrastar e soltar)
  const moveCategory = async (draggedNodeId, targetNodeId, position, profileIdentifier) => {
    if (moveCategory.isMoving) return;
    moveCategory.isMoving = true;

    try {
      const draggedId = parseInt(draggedNodeId.split('-').pop());
      const targetId = parseInt(targetNodeId.split('-').pop());

      const profileData = categoriesByProfile[profileIdentifier];
      if (!profileData) {
        throw new Error('Perfil não encontrado');
      }

      const draggedCategory = findCategory(draggedId, profileIdentifier);
      if (!draggedCategory) {
        throw new Error('Categoria arrastada não encontrada');
      }

      const isOwner = await checkOwnership(draggedCategory);
      if (!isOwner) {
        throw new Error('Você não tem permissão para mover esta categoria.');
      }

      const targetCategory = findCategory(targetId, profileIdentifier);
      if (!targetCategory) {
        throw new Error('Categoria alvo não encontrada');
      }

      let newParentId = draggedCategory.categoria_pai_id;
      let siblings = [];

      if (position === 'inside') {
        newParentId = targetCategory.id;
        siblings = targetCategory.children ? [...targetCategory.children] : [];
      } else {
        newParentId = targetCategory.categoria_pai_id;
        const parent = newParentId ? findCategory(newParentId, profileIdentifier) : { children: profileData.treeData };
        siblings = parent.children ? [...parent.children] : [];
      }

      siblings = siblings.filter(n => n.id !== draggedId);

      const targetIndex = siblings.findIndex(n => n.id === targetId);
      if (position === 'above') {
        siblings.splice(targetIndex, 0, draggedCategory);
      } else if (position === 'below') {
        siblings.splice(targetIndex + 1, 0, draggedCategory);
      } else if (position === 'inside') {
        siblings.push(draggedCategory);
      }

      let order = 1;
      const updates = [];
      for (let sibling of siblings) {
        const updatedFields = {};
        if (sibling.id === draggedCategory.id) {
          updatedFields.categoria_pai_id = newParentId;
        }
        updatedFields.ordem_exibicao = order++;

        if (await checkOwnership(sibling)) {
          updates.push(updateProductCategory(sibling.id, updatedFields));
        }
      }

      await Promise.all(updates);
      setErrorMessage('');

    } catch (error) {
      console.error('Erro ao mover categoria:', error);
      setErrorMessage('Erro ao mover categoria: ' + error.message);
    } finally {
      await refreshCategories();
      moveCategory.isMoving = false;
    }
  };

  const findCategory = (id, profileIdentifier) => {
    const categories = categoriesByProfile[profileIdentifier]?.categories || [];
    return categories.find((cat) => cat.id === id);
  };

  const findCategoryByNodeId = (nodeId, profileIdentifier) => {
    const idParts = nodeId.split('-');
    const id = parseInt(idParts[1], 10);
    return findCategory(id, profileIdentifier);
  };

  const getInitials = (name) => {
    const names = name.split(' ');
    const initials = names.map((n) => n.charAt(0).toUpperCase());
    return initials.slice(0, 2).join('');
  };

  // Componente DraggableTreeItem adaptado para categorias de produtos
  const DraggableTreeItem = ({
    node,
    getNodeColor,
    handleAddSubCategory,
    handleEditCategory,
    handleViewCategory,
    moveCategory,
    findCategory,
    findCategoryByNodeId,
    expandedNodeIds,
    handleToggleNode,
    checkOwnership,
    treeData,
    level = 0,
    profileIdentifier,
  }) => {
    const [isOwner, setIsOwner] = useState(false);
    const [canDrag, setCanDrag] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
      const verifyOwnership = async () => {
        const ownershipStatus = await checkOwnership(node);
        setIsOwner(ownershipStatus);
        setCanDrag(ownershipStatus);
      };
      verifyOwnership();
    }, [node, checkOwnership]);

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CATEGORY,
      item: () => ({ id: node.nodeId }),
      canDrag: () => canDrag,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [dropPosition, setDropPosition] = useState(null);
    const [hoverTimeout, setHoverTimeout] = useState(null);

    const [{ isOverCurrent, canDrop }, drop] = useDrop({
      accept: ItemTypes.CATEGORY,
      hover(item, monitor) {
        if (!ref.current) return;

        const draggedNode = findCategoryByNodeId(item.id, node.titular_cpf || node.titular_cnpj);
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();
        const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        let position = 'inside';
        if (hoverClientY < hoverHeight * 0.25) {
          position = 'above';
        } else if (hoverClientY > hoverHeight * 0.75) {
          position = 'below';
        }

        setDropPosition(position);
      },
      drop(item, monitor) {
        if (monitor.didDrop()) {
          return;
        }

        const draggedId = item.id;
        const hoverId = node.nodeId;
        const position = dropPosition;

        if (draggedId === hoverId) {
          return;
        }

        moveCategory(draggedId, hoverId, position, node.titular_cpf || node.titular_cnpj);
        return { moved: true };
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    });

    const canDropAtPosition = (draggedNode, targetNode, position) => {
      if (!draggedNode || !targetNode) return false;
      if (isDescendant(draggedNode, targetNode)) return false;

      return true;
    };

    const isDescendant = (draggedNode, targetNode) => {
      if (!draggedNode || !targetNode) return false;
      let parent = targetNode;
      while (parent) {
        if (parent.id === draggedNode.id) return true;
        parent = findCategory(parent.categoria_pai_id);
      }
      return false;
    };

    drag(drop(ref));

    const opacity = isDragging ? 0.5 : 1;

    const handleToggle = () => {
      handleToggleNode(node.nodeId);
    };

    const isExpanded = expandedNodeIds[node.nodeId] || false;

    let dropIndicatorStyle = {};

    if (isOverCurrent && canDrop && dropPosition) {
      if (dropPosition === 'above') {
        dropIndicatorStyle = { borderTop: '2px solid #fca22d' };
      } else if (dropPosition === 'below') {
        dropIndicatorStyle = { borderBottom: '2px solid #fca22d' };
      } else if (dropPosition === 'inside') {
        dropIndicatorStyle = { backgroundColor: '#5a5a5a' };
      }
    }

    return (
      <div ref={ref} style={{ opacity, ...dropIndicatorStyle, marginBottom: '2px' }}>
        <div className="treeItem" style={{ marginLeft: level * 15 }}>
          <div className="treeItemContent">
            <span onClick={handleToggle} className={`expandIcon ${isExpanded ? 'expanded' : ''}`}>
              {node.children && node.children.length > 0 ? (
                isExpanded ? (
                  <ExpandMore />
                ) : (
                  <ChevronRight />
                )
              ) : null}
            </span>
            <span className="categoryName">{node.nome}</span>
          </div>
          <div className="categoryIcons">
            {isOwner ? (
              <Edit
                onClick={(e) => handleEditCategory(e, node)}
                className="icon"
                titleAccess="Editar Categoria"
              />
            ) : (
              <Visibility
                onClick={(e) => handleViewCategory(e, node)}
                className="icon"
                titleAccess="Visualizar Categoria"
              />
            )}
            <Add
              onClick={(e) => handleAddSubCategory(e, node, node.titular_cpf || node.titular_cnpj)}
              className="icon"
              titleAccess="Adicionar Subcategoria"
            />
          </div>
        </div>
        {isExpanded && node.children && node.children.length > 0 && (
          <div className="treeItemChildren">
            {node.children.map((childNode) => (
              <DraggableTreeItem
                key={childNode.nodeId}
                node={childNode}
                getNodeColor={getNodeColor}
                handleAddSubCategory={handleAddSubCategory}
                handleEditCategory={handleEditCategory}
                handleViewCategory={handleViewCategory}
                moveCategory={moveCategory}
                findCategory={(id) => findCategory(id, profileIdentifier)}
                findCategoryByNodeId={(nodeId) =>
                  findCategoryByNodeId(nodeId, profileIdentifier)
                }
                expandedNodeIds={expandedNodeIds}
                handleToggleNode={handleToggleNode}
                checkOwnership={checkOwnership}
                treeData={treeData}
                level={level + 1}
                profileIdentifier={node.titular_cpf || node.titular_cnpj}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="productCategoriesCard">
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="outlined"
          onClick={handleToggleAllNodes}
          startIcon={expandAll ? <ExpandLess /> : <ExpandMore />}
        >
          {expandAll ? 'Fechar Tudo' : 'Abrir Tudo'}
        </Button>
        <FormControlLabel
          control={<Switch checked={showInactive} onChange={handleToggleInactive} />}
          label={<Typography variant="body2">Exibir Categorias Inativas</Typography>}
          labelPlacement="start"
          style={{ marginRight: 0 }}
        />
      </Box>
      {loading ? (
        <Typography variant="body2">Carregando categorias...</Typography>
      ) : errorMessage ? (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      ) : (
        Object.values(categoriesByProfile).map(({ profile, treeData }) => (
          <div
            key={profile.cpf || profile.cnpj}
            className="profileCategoryTree"
          >
            <div className="profileHeader">
              <div className="profileAvatarCategories">
                {profile.image ? (
                  <img
                    src={`${profile.image}`}
                    alt={profile.name}
                    onError={(e) => {
                      e.target.style.display = 'none';
                      e.target.parentNode.textContent = getInitials(profile.name);
                    }}
                  />
                ) : (
                  <span>{getInitials(profile.name)}</span>
                )}
              </div>
              <Typography variant="h6">{profile.name}</Typography>
            </div>
            <DndProvider backend={HTML5Backend}>
              <div className="customTreeView">
                {filterCategories(treeData).map((node) => (
                  <DraggableTreeItem
                    key={node.nodeId}
                    node={node}
                    getNodeColor={() => ''}
                    handleAddSubCategory={(e, node) =>
                      handleAddSubCategory(e, node, profile.cpf || profile.cnpj)
                    }
                    handleEditCategory={handleEditCategory}
                    handleViewCategory={handleViewCategory}
                    moveCategory={(draggedId, targetId, position) =>
                      moveCategory(
                        draggedId,
                        targetId,
                        position,
                        profile.cpf || profile.cnpj
                      )
                    }
                    findCategory={(id) => findCategory(id, node.titular_cpf || node.titular_cnpj)}
                    findCategoryByNodeId={(nodeId) =>
                      findCategoryByNodeId(nodeId, node.titular_cpf || node.titular_cnpj)
                    }
                    expandedNodeIds={expandedNodeIds}
                    handleToggleNode={handleToggleNode}
                    checkOwnership={checkOwnership}
                    treeData={treeData}
                    level={0}
                    profileIdentifier={node.titular_cpf || node.titular_cnpj}
                  />
                ))}
              </div>
            </DndProvider>
            <Box mt={2}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  setSelectedCategory(null);
                  setOpenRegisterModal(true);
                  setSelectedProfileIdentifier(profile.cpf || profile.cnpj);
                }}
              >
                Adicionar Categoria Principal
              </Button>
            </Box>
          </div>
        ))
      )}
      {openRegisterModal && (
        <RegisterCategory
          open={openRegisterModal}
          onClose={handleCloseRegisterModal}
          parentCategory={selectedCategory}
          allCategories={
            selectedProfiles.length > 0
              ? categoriesByProfile[selectedProfileIdentifier].categories
              : []
          }
          profileIdentifier={selectedProfileIdentifier}
        />
      )}
      {openInfoModal && (
        <CategoryInfo
          open={openInfoModal}
          onClose={handleCloseInfoModal}
          category={selectedCategory}
        />
      )}
    </div>
  );
};

export default ProductCategoriesCard;