import React, { useContext } from 'react';
import { Container, Typography } from '@mui/material';

import './Dashboard.css';
import withAuth from '../hooks/withAuth';
import { ProfileContext } from '../context/profileContext'; // Importando o ProfileContext

import Sidebar from '../components/sidebar';
import Header from '../components/header';

const Dashboard = () => {
  const { selectedProfiles } = useContext(ProfileContext); // Consumindo o contexto

  return (
    <div className="dashboardContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="dashboardContent">
        <Header />
        <Container>
          <Typography variant="h4" gutterBottom>
            Em Construção
          </Typography>
          <Typography variant="body1">
            Esta seção está em desenvolvimento. Por favor, volte mais tarde.
          </Typography>
          {/* Exibindo perfis selecionados para teste */}
          <div>
            <Typography variant="h6">Perfis Selecionados:</Typography>
            <ul>
              {selectedProfiles.map((profile, index) => (
                <li key={index}>
                  <img src={profile.image ? profile.image : ''} alt={profile.name ? profile.name : profile.cpf || profile.cnpj} />
                  {profile.name ? profile.name : profile.cpf || profile.cnpj}
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default withAuth(Dashboard);
