// eventSystem.js
export const eventEmitter = new EventTarget();

export const triggerEvent = (eventName, detail = {}) => {
  const event = new CustomEvent(eventName, { detail });
  eventEmitter.dispatchEvent(event);
};

export const onEvent = (eventName, callback) => {
  eventEmitter.addEventListener(eventName, callback);
};

export const offEvent = (eventName, callback) => {
  eventEmitter.removeEventListener(eventName, callback);
};

// Função para disparar o evento de troca de perfil
export const triggerProfileChange = (newProfile) => {
  triggerEvent('profileChange', { newProfile });
};
