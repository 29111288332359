import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';

import './companyProfiles.css';
import withAuth from '../../../hooks/withAuth';

import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header'; // Importando o Header
import CompanyProfilesCard from './cards/companyProfilesCard';
import CompanyInfoCard from './cards/companyInfo';
import TeamMemberCard from './cards/teamMember';
import RegisterCompanyCard from './cards/registerCompany';

import { getTeamMembers } from '../../../services/profile/companyProfileService';

const CompanyProfiles = () => {
  const [refreshProfiles, setRefreshProfiles] = useState(false);
  const [selectedCnpj, setSelectedCnpj] = useState(null);
  const [showRegisterCard, setShowRegisterCard] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('authToken'));
  const [userCpf, setUserCpf] = useState(localStorage.getItem('userCpf'));
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshAllComponents = () => {
    setSelectedCnpj(null);
    setShowRegisterCard(false);
    setRefreshProfiles(prev => !prev);
    setRefreshKey(oldKey => oldKey + 1);
  };

  useEffect(() => {
    const handleTokenOrCpfChange = () => {
      const newToken = localStorage.getItem('authToken');
      const newCpf = localStorage.getItem('userCpf');
      if (newToken !== token || newCpf !== userCpf) {
        setToken(newToken);
        setUserCpf(newCpf);
        refreshAllComponents();
      }
    };

    window.addEventListener('storage', handleTokenOrCpfChange);
    return () => {
      window.removeEventListener('storage', handleTokenOrCpfChange);
    };
  }, [token, userCpf]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teamMembers = await getTeamMembers();
        if (teamMembers && teamMembers.length > 0) {
          setSelectedCnpj(teamMembers[0]);
          setShowRegisterCard(false);
        } else {
          setSelectedCnpj(null);
          setShowRegisterCard(true);
        }
      } catch (error) {
        console.error('Erro ao buscar informações do membro da equipe:', error);
        setShowRegisterCard(true);
      }
    };
    fetchData();
  }, [refreshProfiles, userCpf]);

  const handleSelectCnpj = (cnpj) => {
    setSelectedCnpj(cnpj);
    setShowRegisterCard(false);
  };

  const handleRegisterCompany = () => {
    setSelectedCnpj(null);
    setShowRegisterCard(true);
  };

  const handleCompanyRegistered = (cnpj) => {
    setRefreshProfiles(prev => !prev);
    setSelectedCnpj(cnpj);
    setShowRegisterCard(false);
  };

  return (
    <div className="companyProfileContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="profileContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Perfis Empresariais
            </Typography>
          </Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              {showRegisterCard ? (
                <RegisterCompanyCard onCompanyRegistered={handleCompanyRegistered} />
              ) : (
                <>
                  {selectedCnpj && <CompanyInfoCard cnpj={selectedCnpj} key={`companyInfo-${refreshKey}`} />}
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <CompanyProfilesCard 
                refreshProfiles={refreshProfiles} 
                onSelectCnpj={handleSelectCnpj} 
                onRegisterCompany={handleRegisterCompany}
                selectedCnpj={selectedCnpj}
                key={`companyProfiles-${refreshKey}`}
              />
              {selectedCnpj && <TeamMemberCard cnpj={selectedCnpj} refreshProfiles={refreshProfiles} key={`teamMember-${refreshKey}`} />}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withAuth(CompanyProfiles);
