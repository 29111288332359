// creditCardInfo.js

import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, MenuItem, Stack, Box, Switch, FormControlLabel } from '@mui/material';

import { CreditCardSelector, CardBrandSelector } from '../../../../utils/formUtils';
import { funcoesCartao, statusCartao } from '../../../../config/constants';
import config from '../../../../config/apiConfig';

import { getCreditCardByID, updateCreditCard } from '../../../../services/finance/creditCardService';
import { getCompanyData } from '../../../../services/profile/companyProfileService';
import { getProfileDataByCpf } from '../../../../services/profile/userProfileService';
import { checkOwnership } from '../../../../services/auth/authService'; // Importar a nova função
import { getCardBrandById } from '../../../../services/finance/cardBrandService';

const CreditCardInfo = ({ creditCardId, onUpdateCreditCardList }) => {
  const [creditCardData, setCreditCardData] = useState({
    id_bandeira: '',  // Substituindo 'bandeira' por 'id_bandeira'
  });
  const [originalData, setOriginalData] = useState({});
  const [titularName, setTitularName] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [cardBrandLogo, setCardBrandLogo] = useState('');

  useEffect(() => {
    const fetchCreditCardData = async () => {
      try {
        setErrorMessage('');
        setSuccessMessage('');
        const creditCard = await getCreditCardByID(creditCardId);
        const formattedData = {
          ...creditCard,
          apelido: creditCard.apelido || '',
          ultimos_digitos: creditCard.ultimos_digitos || '',
          id_bandeira: creditCard.id_bandeira || '',
          funcoes_cartao: creditCard.funcoes_cartao || '', // Corrigido aqui
          cartao_virtual: creditCard.cartao_virtual || false,
          dia_fechamento: creditCard.dia_fechamento || '',
          dia_vencimento: creditCard.dia_vencimento || '',
          data_expiracao: creditCard.data_expiracao || '',
          limite_credito: creditCard.limite_credito || '',
          usuario_cartao: creditCard.usuario_cartao || '',
          status: creditCard.status || '',
          descricao: creditCard.descricao || '',
          cartao_adicional: !!creditCard.cartao_principal_id,
          cartao_principal_id: creditCard.cartao_principal_id || '',
        };
        setCreditCardData(formattedData);
        setOriginalData(formattedData);
        
        // Buscar o logo da bandeira do cartão
        if (formattedData.id_bandeira) {
          const cardBrand = await getCardBrandById(formattedData.id_bandeira);
          setCardBrandLogo(`${cardBrand.logo_url}`);
        }

        const isOwnerResult = await checkOwnership(formattedData); // Usar a nova função
        setIsOwner(isOwnerResult);
        if (formattedData.titular_cpf) {
          await fetchTitularName(formattedData.titular_cpf);
        } else if (formattedData.titular_cnpj) {
          await fetchTitularCompanyName(formattedData.titular_cnpj);
        }
        setLoading(false);
      } catch (err) {
        setError('Erro ao buscar dados do cartão de crédito');
        setLoading(false);
      }
    };

    fetchCreditCardData();
  }, [creditCardId]);

  const fetchTitularName = async (cpf) => {
    try {
      const profileData = await getProfileDataByCpf(cpf);
      setTitularName(`${profileData.nome} ${profileData.sobrenome}`);
    } catch (err) {
      setError('Erro ao buscar dados do titular');
    }
  };

  const fetchTitularCompanyName = async (cnpj) => {
    try {
      const companyData = await getCompanyData(cnpj);
      setTitularName(companyData.razao_social);
    } catch (err) {
      setError('Erro ao buscar dados da empresa titular');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCreditCardData({ ...creditCardData, [name]: value });
    setIsChanged(JSON.stringify({ ...creditCardData, [name]: value }) !== JSON.stringify(originalData));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setCreditCardData({ ...creditCardData, [name]: checked });
    setIsChanged(true);
  };

  const getModifiedFields = () => {
    const modifiedFields = {};
    Object.keys(creditCardData).forEach((key) => {
      if (creditCardData[key] !== originalData[key]) {
        modifiedFields[key] = creditCardData[key];
      }
    });
    return modifiedFields;
  };

  const handleSaveChanges = async () => {
    const modifiedFields = getModifiedFields();
    if (Object.keys(modifiedFields).length > 0) {
      try {
        const updatedCreditCard = await updateCreditCard(creditCardId, modifiedFields);
        setOriginalData(creditCardData); // Atualizar os dados originais
        setIsChanged(false);
        setSuccessMessage('Dados atualizados com sucesso!');
        setErrorMessage('');

        if (onUpdateCreditCardList) {
          onUpdateCreditCardList(updatedCreditCard);
        }
      } catch (err) {
        setErrorMessage('Erro ao salvar alterações');
        setSuccessMessage('');
      }
    }
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!creditCardData) {
    return <Typography color="error">Cartão não encontrado.</Typography>;
  }

  return (
    <div className="creditCardInfoCard">
      <div className="cardHeader">
        <img src={cardBrandLogo} alt="Logo do Banco" className="bankLogo" />
        <div className="bankName">
          <Typography variant="h5">{creditCardData.apelido}</Typography>
        </div>
      </div>
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={creditCardData.cartao_virtual}
                onChange={handleSwitchChange}
                name="cartao_virtual"
                color="primary"
                disabled={!isOwner}
              />
            }
            label="Cartão Virtual"
          />
          <FormControlLabel
            control={
              <Switch
                checked={creditCardData.cartao_adicional}
                onChange={handleSwitchChange}
                name="cartao_adicional"
                color="primary"
                disabled={!isOwner}
              />
            }
            label="Cartão Adicional"
          />
        </Stack>
        {creditCardData.cartao_adicional && (
          <CreditCardSelector
            value={creditCardData.cartao_principal_id || ''}
            onChange={(value) => {
              setCreditCardData({ ...creditCardData, cartao_principal_id: value });
              setIsChanged(true);
            }}
            titular={creditCardData.titular_cpf || creditCardData.titular_cnpj}
            label="Cartão Principal"
            disabled={!isOwner}
          />
        )}
        {!creditCardData.cartao_adicional && (
          <>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Dia de Fechamento"
                variant="outlined"
                name="dia_fechamento"
                value={creditCardData.dia_fechamento}
                onChange={handleChange}
                fullWidth
                disabled={!isOwner}
              />
              <TextField
                label="Dia de Vencimento"
                variant="outlined"
                name="dia_vencimento"
                value={creditCardData.dia_vencimento}
                onChange={handleChange}
                fullWidth
                disabled={!isOwner}
              />
            </Stack>
            <TextField
              label="Limite de Crédito"
              variant="outlined"
              name="limite_credito"
              value={creditCardData.limite_credito}
              onChange={handleChange}
              fullWidth
              disabled={!isOwner}
            />
          </>
        )}
        <Stack direction="row" spacing={2}>
          <TextField
            label="Apelido"
            variant="outlined"
            name="apelido"
            value={creditCardData.apelido}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          />
          <TextField
            label="Últimos Dígitos"
            variant="outlined"
            name="ultimos_digitos"
            value={creditCardData.ultimos_digitos}
            onChange={handleChange}
            fullWidth
            inputProps={{ maxLength: 4 }}
            disabled={!isOwner}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <CardBrandSelector
            value={creditCardData.id_bandeira}
            onChange={(e) => handleChange({ target: { name: 'id_bandeira', value: e.target.value } })}
            disabled={!isOwner}
          />
          <TextField
            select
            label="Funções do Cartão"
            variant="outlined"
            name="funcoes_cartao"
            value={creditCardData.funcoes_cartao}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          >
            {funcoesCartao.map((funcao) => (
              <MenuItem key={funcao.valor} value={funcao.valor}>{funcao.nome}</MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Data de Expiração"
            variant="outlined"
            name="data_expiracao"
            value={creditCardData.data_expiracao}
            onChange={handleChange}
            fullWidth
            placeholder="MM/AA"
            disabled={!isOwner}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Usuário do Cartão"
            variant="outlined"
            name="usuario_cartao"
            value={creditCardData.usuario_cartao}
            onChange={handleChange}
            fullWidth
            disabled={!isOwner}
          />
        <TextField
          select
          label="Status"
          variant="outlined"
          name="status"
          value={creditCardData.status}
          onChange={handleChange}
          fullWidth
          disabled={!isOwner}
        >
          {statusCartao.map((status) => (
            <MenuItem key={status.valor} value={status.valor}>{status.nome}</MenuItem>
          ))}
        </TextField>
        </Stack>

        <TextField
          label="Descrição"
          variant="outlined"
          name="descricao"
          value={creditCardData.descricao}
          onChange={handleChange}
          fullWidth
          disabled={!isOwner}
        />
        <div></div>
        {/* Campos separados para CPF/CNPJ do titular */}
        {creditCardData.titular_cpf && (
          <Stack direction="row" spacing={2}>
            <TextField
              label="Nome do Titular"
              variant="outlined"
              value={titularName}
              fullWidth
              disabled
            />
            <TextField
              label="CPF do Titular"
              variant="outlined"
              value={creditCardData.titular_cpf}
              fullWidth
              disabled
            />
          </Stack>
        )}
        {creditCardData.titular_cnpj && (
          <Stack direction="row" spacing={2}>
            <TextField
              label="Razão Social da Empresa"
              variant="outlined"
              value={titularName}
              fullWidth
              disabled
            />
            <TextField
              label="CNPJ do Titular"
              variant="outlined"
              value={creditCardData.titular_cnpj}
              fullWidth
              disabled
            />
          </Stack>
        )}
      </Stack>

      <Box mt={2}>
        {isChanged && isOwner && (
          <Button fullWidth variant="contained" color="primary" onClick={handleSaveChanges}>
            Salvar Alterações
          </Button>
        )}
      </Box>
    </div>
  );
};

export default CreditCardInfo;