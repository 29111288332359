import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, MenuItem, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './personalData.css';
import logo from '../../img/robot.png';
import withAuth from '../../hooks/withAuth';
import { estados, generos, nacionalidades } from '../../config/constants';
import { getProfileDataByCpf, createPerson } from '../../services/profile/userProfileService';
import { handleLogout } from '../../services/auth/authService';
import { validateDate, fetchAddressByCep, formatDateForServer, formatCepForServer, formatCpfForServer } from '../../utils/helpers';

const PersonalData = () => {
  const [personalData, setPersonalData] = useState({
    cpf: localStorage.getItem('userCpf') || '',
    nome: '',
    sobrenome: '',
    data_nascimento: '',
    telefone: '',
    cidade: '',
    estado: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    genero: '',
    nacionalidade: 'brasileiro', // Pre-selecionado
  });
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(''); // Novo estado para armazenar mensagens de erro do servidor
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken');

      const cpf = localStorage.getItem('userCpf');

      try {
        const data = await getProfileDataByCpf(cpf, token);
        if (data) {
          localStorage.setItem('hasPersonalData', 'true');
          navigate('/');
        }
      } catch (error) {
        console.error('Erro ao buscar dados pessoais:', error);
      }
    };

    fetchData();
  }, [navigate]);

  const handlePersonalDataChange = (event) => {
    const { name, value } = event.target;
    setPersonalData((prevData) => ({ ...prevData, [name]: value }));

    if (name === 'cep' && value.length === 8) {
      handleFetchAddressByCep(value);
    }
  };

  const handleFetchAddressByCep = async (cep) => {
    try {
      const data = await fetchAddressByCep(cep);
      if (data.erro) {
        setErrors((prevErrors) => ({ ...prevErrors, cep: 'CEP inválido' }));
      } else {
        setPersonalData((prevData) => ({
          ...prevData,
          logradouro: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
          numero: '', // Limpa o campo número
          complemento: '', // Limpa o campo complemento
        }));
        setErrors((prevErrors) => ({ ...prevErrors, cep: '' }));
      }
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, cep: 'Erro ao buscar endereço' }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedDate = formatDateForServer(personalData.data_nascimento);
    if (!formattedDate || !validateDate(formattedDate)) {
      setErrors((prevErrors) => ({ ...prevErrors, data_nascimento: 'Data de nascimento inválida' }));
      return;
    }

    const formattedCep = formatCepForServer(personalData.cep);
    const formattedCpf = formatCpfForServer(personalData.cpf);

    const personalDataToSend = {
      ...personalData,
      data_nascimento: formattedDate,
      cep: formattedCep,
      cpf: formattedCpf,
    };

    const token = localStorage.getItem('authToken');

    try {
      await createPerson(personalDataToSend, token);
      navigate('/dashboard');
    } catch (error) {
      console.error('Erro ao enviar dados pessoais:', error);
      setServerError(error.message); // Armazena o erro do servidor
    }
  };

  return (
    <Container maxWidth="lg" className="personalDataContainer">
      <Box className="header" display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <img src={logo} alt="Logo" className="logo" />
          <Typography variant="h4" className="title">EI MONA</Typography>
        </Box>
        <Typography variant="body2" className="logoutLink">
          <a href="/" onClick={() => handleLogout(navigate)} className="logoutLink">
            Logout
          </a>
        </Typography>
      </Box>
      <Box className="personalDataBox">
        <Typography variant="h4" component="h1" gutterBottom>
          Dados Pessoais
        </Typography>
        {serverError && (
              <Typography variant="body2" color="error" gutterBottom>
                {serverError}
              </Typography>
        )}
        <form onSubmit={handleSubmit} className="personalDataForm">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Nome"
                variant="outlined"
                required
                name="nome"
                value={personalData.nome}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Sobrenome"
                variant="outlined"
                required
                name="sobrenome"
                value={personalData.sobrenome}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="CPF"
                variant="outlined"
                required
                name="cpf"
                value={personalData.cpf}
                className="textFieldReadOnly"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInputReadOnly" }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Data de Nascimento"
                variant="outlined"
                type="date"
                required
                name="data_nascimento"
                value={personalData.data_nascimento}
                onChange={handlePersonalDataChange}
                error={!!errors.data_nascimento}
                helperText={errors.data_nascimento}
                className="textField"
                InputLabelProps={{ className: "formLabel", shrink: true }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Telefone"
                variant="outlined"
                required
                name="telefone"
                value={personalData.telefone}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="CEP"
                variant="outlined"
                required
                name="cep"
                value={personalData.cep}
                onChange={handlePersonalDataChange}
                error={!!errors.cep}
                helperText={errors.cep || "Ao inserir o CEP, os campos correspondentes serão preenchidos automaticamente."}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Cidade"
                variant="outlined"
                required
                name="cidade"
                value={personalData.cidade}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                margin="normal"
                label="Estado"
                variant="outlined"
                required
                name="estado"
                value={personalData.estado}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              >
                {estados.map((estado) => (
                  <MenuItem key={estado.sigla} value={estado.sigla}>
                    {estado.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Logradouro"
                variant="outlined"
                required
                name="logradouro"
                value={personalData.logradouro}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Número"
                variant="outlined"
                required
                name="numero"
                value={personalData.numero}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Complemento"
                variant="outlined"
                name="complemento"
                value={personalData.complemento}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Bairro"
                variant="outlined"
                name="bairro"
                value={personalData.bairro}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                margin="normal"
                label="Gênero"
                variant="outlined"
                name="genero"
                value={personalData.genero}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              >
                {generos.map((genero) => (
                  <MenuItem key={genero.value} value={genero.value}>
                    {genero.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                margin="normal"
                label="Nacionalidade"
                variant="outlined"
                name="nacionalidade"
                value={personalData.nacionalidade}
                onChange={handlePersonalDataChange}
                className="textField"
                InputLabelProps={{ className: "formLabel" }}
                InputProps={{ className: "formInput" }}
              >
                {nacionalidades.map((nacionalidade) => (
                  <MenuItem key={nacionalidade.valor} value={nacionalidade.valor}>
                    {nacionalidade.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          <Box my={2}></Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className="buttonOrange"
          >
            Salvar
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default withAuth(PersonalData);