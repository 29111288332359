import React, { useState, useEffect, useContext } from 'react';
import { MenuItem, CircularProgress, ListItemIcon, Avatar, Select, FormControl, InputLabel } from '@mui/material';
import { getAccountByIdentifier } from '../services/finance/accountsService';
import { getCreditCardByIdentifier } from '../services/finance/creditCardService';
import { getFinancialOperationsByIdentifier } from '../services/finance/financialOperationService';
import { ProfileContext } from '../context/profileContext';
import { getInitials } from './helpers';
import { getBankList } from '../services/finance/bankService';
import { getCardBrandList } from '../services/finance/cardBrandService';
import { getProductCategoriesByIdentifier } from '../services/inventory/productCategoryService';


// Componente BankSelector
export const BankSelector = ({ value, onChange }) => {
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchBanks = async () => {
      try {
        const bankList = await getBankList();
        if (isMounted) { 
          setBanks(bankList);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Erro ao buscar lista de bancos:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchBanks();

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Banco</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label="Banco"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, 
              maxWidth: 300,
            },
          },
        }}
      >
        {banks.map((bank) => (
          <MenuItem 
            key={bank.id} 
            value={bank.id}
            style={{ padding: '8px', fontSize: '14px' }}
          >
            <ListItemIcon>
              {bank.logo_image ? (
                <img 
                  src={bank.logo_image}
                  alt={bank.nome_banco} 
                  width="24" 
                  style={{ borderRadius: '50%' }} 
                />
              ) : (
                <Avatar style={{ backgroundColor: 'orange', color: 'white', borderRadius: '50%', width: 24, height: 24, fontSize: '12px' }}>
                  {getInitials(bank.nome_banco)}
                </Avatar>
              )}
            </ListItemIcon>
            {`${bank.codigo_banco} - ${bank.nome_banco}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Componente TitularSelector atualizado
export const TitularSelector = ({ value, onChange, disabled, label = "Titular" }) => {
  const { selectedProfiles } = useContext(ProfileContext);
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    // Verifica se o valor é válido e atualiza o estado interno
    if (value && selectedProfiles.some(profile => (profile.cpf || profile.cnpj) === value)) {
      setInternalValue(value);
    } else {
      setInternalValue('');
    }
  }, [value, selectedProfiles]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    onChange(newValue);
  };

  return (
    <FormControl fullWidth variant="outlined" disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={internalValue}
        onChange={handleChange}
        label={label}
        disabled={disabled}
      >
        <MenuItem value="">
          <em>Nenhum titular selecionado</em>
        </MenuItem>
        {selectedProfiles.map((profile) => {
          const [nome, sobrenome] = profile.name.split(' ');
          return (
            <MenuItem key={profile.cpf || profile.cnpj} value={profile.cpf || profile.cnpj}>
              <ListItemIcon>
                {profile.image ? (
                  <img 
                    src={profile.image} 
                    alt={profile.name} 
                    width="24" 
                    style={{ borderRadius: '50%' }} 
                  />
                ) : (
                  <Avatar style={{ backgroundColor: 'orange', color: 'white', borderRadius: '50%', width: 24, height: 24 }}>
                    {getInitials(nome, sobrenome)}
                  </Avatar>
                )}
              </ListItemIcon>
              {profile.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

// Componente AccountSelector corrigido
export const AccountSelector = ({ value, onChange, disabled, label = "Conta", titular, useContextProfiles = false }) => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedProfiles } = useContext(ProfileContext);

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        let allAccounts = [];
        if (titular) {
          // Buscar contas apenas para o titular específico
          const accountList = await getAccountByIdentifier(titular);
          allAccounts = accountList.filter(account => account.status === 'ativo');
        } else if (useContextProfiles && selectedProfiles.length > 0) {
          // Buscar contas para todos os perfis selecionados no contexto
          for (const profile of selectedProfiles) {
            const identifier = profile.cpf || profile.cnpj;
            const accountList = await getAccountByIdentifier(identifier);
            const activeAccounts = accountList.filter(account => account.status === 'ativo');
            allAccounts.push(...activeAccounts);
          }
        }
        setAccounts(allAccounts);
      } catch (error) {
        console.error('Erro ao buscar contas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [titular, useContextProfiles, selectedProfiles]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  // Verifica se o valor atual é válido
  const validOptions = accounts.map(account => account.id);
  const currentValue = validOptions.includes(value) ? value : '';

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        value={currentValue}
        onChange={handleChange}
        label={label}
        disabled={disabled || loading}
      >
        <MenuItem value="">
          <em>Nenhuma conta selecionada</em>
        </MenuItem>
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
            Carregando contas...
          </MenuItem>
        ) : (
          accounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              <ListItemIcon>
                {account.logo_image ? (
                  <img 
                    src={account.logo_image}
                    alt={account.nome_banco} 
                    width="24" 
                    style={{ borderRadius: '50%' }} 
                  />
                ) : (
                  <Avatar style={{ backgroundColor: 'orange', color: 'white', borderRadius: '50%', width: 24, height: 24, fontSize: '12px' }}>
                    {getInitials(account.nome_banco)}
                  </Avatar>
                )}
              </ListItemIcon>
              {`${account.nome_banco} - Ag: ${account.agencia} Conta: ${account.numero_conta}-${account.digito_conta}`}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

// Componente CreditCardSelector modificado
export const CreditCardSelector = ({ value, onChange, disabled, label = "Cartão de Crédito", titular, useContextProfiles = false }) => {
  const [creditCards, setCreditCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedProfiles } = useContext(ProfileContext);

  useEffect(() => {
    const fetchCreditCards = async () => {
      setLoading(true);
      try {
        let allCards = [];
        if (titular) {
          // Buscar cartões apenas para o titular específico
          const cardList = await getCreditCardByIdentifier(titular);
          allCards = cardList.filter(card => card.status === 'ativo');
        } else if (useContextProfiles && selectedProfiles.length > 0) {
          // Buscar cartões para todos os perfis selecionados no contexto
          for (const profile of selectedProfiles) {
            const identifier = profile.cpf || profile.cnpj;
            const cardList = await getCreditCardByIdentifier(identifier);
            const activeCards = cardList.filter(card => card.status === 'ativo');
            allCards.push(...activeCards);
          }
        }
        setCreditCards(allCards);
      } catch (error) {
        console.error('Erro ao buscar cartões de crédito:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditCards();
  }, [titular, useContextProfiles, selectedProfiles]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  // Verifica se o valor atual é válido
  const validOptions = creditCards.map(card => card.id);
  const currentValue = validOptions.includes(value) ? value : '';

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        value={currentValue}
        onChange={handleChange}
        label={label}
        disabled={disabled || loading}
      >
        <MenuItem value="">
          <em>Nenhum cartão selecionado</em>
        </MenuItem>
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
            Carregando cartões...
          </MenuItem>
        ) : (
          creditCards.map((card) => (
            <MenuItem key={card.id} value={card.id}>
              <ListItemIcon>
                {card.logo_image ? (
                  <img 
                    src={card.logo_image}
                    alt={card.bandeira} 
                    width="24" 
                    style={{ borderRadius: '50%' }} 
                  />
                ) : (
                  <Avatar style={{ backgroundColor: 'orange', color: 'white', borderRadius: '50%', width: 24, height: 24, fontSize: '12px' }}>
                    {getInitials(card.bandeira)}
                  </Avatar>
                )}
              </ListItemIcon>
              {`${card.apelido} - **** ${card.ultimos_digitos}`}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

// Função auxiliar para construir a árvore de operações
const buildOperationTree = (operations) => {
  const operationMap = new Map();
  const rootOperations = [];

  operations.forEach(operation => {
    operationMap.set(operation.id, { ...operation, children: [] });
  });

  operations.forEach(operation => {
    if (operation.operacao_pai_id) {
      const parentOperation = operationMap.get(operation.operacao_pai_id);
      if (parentOperation) {
        parentOperation.children.push(operationMap.get(operation.id));
      }
    } else {
      rootOperations.push(operationMap.get(operation.id));
    }
  });

  rootOperations.sort((a, b) => a.ordem_exibicao - b.ordem_exibicao);
  return rootOperations;
};

// Novo componente OperationSelector
export const OperationSelector = ({ value, onChange, titular, disabled, label = "Operação" }) => {
  const [operations, setOperations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    let isMounted = true;
    const fetchOperations = async () => {
      if (titular) {
        setLoading(true);
        try {
          const operationList = await getFinancialOperationsByIdentifier(titular);
          if (isMounted) {
            const operationTree = buildOperationTree(operationList);
            setOperations(operationTree);
            
            // Verifica se o valor atual é válido
            if (!operationList.some(op => op.id === value)) {
              setInternalValue('');
            } else {
              setInternalValue(value);
            }
          }
        } catch (error) {
          console.error('Erro ao buscar operações:', error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      } else {
        setOperations([]);
        setInternalValue('');
      }
    };

    fetchOperations();

    return () => {
      isMounted = false;
    };
  }, [titular, value]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const flattenOperations = (operations) => {
    return operations.reduce((acc, operation) => {
      acc.push(operation);
      if (operation.children && operation.children.length > 0) {
        acc.push(...flattenOperations(operation.children));
      }
      return acc;
    }, []);
  };

  const renderOperationOptions = (operations, level = 0) => {
    return operations.flatMap((operation) => [
      <MenuItem key={operation.id} value={operation.id} style={{ paddingLeft: `${level * 20}px` }}>
        {operation.nome}
      </MenuItem>,
      ...renderOperationOptions(operation.children || [], level + 1)
    ]);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    onChange(newValue);
  };

  const flatOperations = flattenOperations(operations);
  const validOptions = ['', ...flatOperations.map(op => op.id)];

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        value={validOptions.includes(internalValue) ? internalValue : ''}
        onChange={handleChange}
        label={label}
        disabled={disabled || loading}
      >
        <MenuItem value="">
          <em>Nenhuma operação selecionada</em>
        </MenuItem>
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
            Carregando operações...
          </MenuItem>
        ) : (
          renderOperationOptions(operations)
        )}
      </Select>
    </FormControl>
  );
};

// Componente CardBrandSelector
export const CardBrandSelector = ({ value, onChange }) => {
  const [cardBrands, setCardBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchCardBrands = async () => {
      try {
        const brandList = await getCardBrandList();
        if (isMounted) { 
          setCardBrands(brandList);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Erro ao buscar lista de bandeiras de cartão:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchCardBrands();

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Bandeira do Cartão</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label="Bandeira do Cartão"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, 
              maxWidth: 300,
            },
          },
        }}
      >
        {cardBrands.map((brand) => (
          <MenuItem 
            key={brand.id} 
            value={brand.id}
            style={{ padding: '8px', fontSize: '14px' }}
          >
            <ListItemIcon>
              {brand.logo_url ? (
                <img 
                  src={brand.logo_url}
                  alt={brand.nome} 
                  width="24" 
                  style={{ borderRadius: '50%' }} 
                />
              ) : (
                <Avatar style={{ backgroundColor: 'orange', color: 'white', borderRadius: '50%', width: 24, height: 24, fontSize: '12px' }}>
                  {getInitials(brand.nome)}
                </Avatar>
              )}
            </ListItemIcon>
            {brand.nome_bandeira}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Função auxiliar para construir a árvore de categorias
const buildCategoryTree = (categories) => {
  const categoryMap = new Map();
  const rootCategories = [];

  categories.forEach(category => {
    categoryMap.set(category.id, { ...category, children: [] });
  });

  categories.forEach(category => {
    if (category.categoria_pai_id) {
      const parentCategory = categoryMap.get(category.categoria_pai_id);
      if (parentCategory) {
        parentCategory.children.push(categoryMap.get(category.id));
      }
    } else {
      rootCategories.push(categoryMap.get(category.id));
    }
  });

  rootCategories.sort((a, b) => (a.ordem_exibicao || 0) - (b.ordem_exibicao || 0));
  return rootCategories;
};

// Novo componente ProductCategorySelector
export const ProductCategorySelector = ({ value, onChange, titular, disabled, label = "Categoria de Produto" }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    let isMounted = true;

    const fetchCategories = async () => {
      if (titular) {
        setLoading(true);
        try {
          const categoryList = await getProductCategoriesByIdentifier(titular);
          if (isMounted) {
            const categoryTree = buildCategoryTree(categoryList);
            setCategories(categoryTree);

            // Verifica se o valor atual é válido
            if (!categoryList.some(cat => cat.id === value)) {
              setInternalValue('');
            } else {
              setInternalValue(value);
            }
          }
        } catch (error) {
          console.error('Erro ao buscar categorias de produtos:', error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      } else {
        setCategories([]);
        setInternalValue('');
      }
    };

    fetchCategories();

    return () => {
      isMounted = false;
    };
  }, [titular, value]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const flattenCategories = (categories) => {
    return categories.reduce((acc, category) => {
      acc.push(category);
      if (category.children && category.children.length > 0) {
        acc.push(...flattenCategories(category.children));
      }
      return acc;
    }, []);
  };

  const renderCategoryOptions = (categories, level = 0) => {
    return categories.flatMap((category) => [
      <MenuItem key={category.id} value={category.id} style={{ paddingLeft: `${level * 20}px` }}>
        {category.nome}
      </MenuItem>,
      ...renderCategoryOptions(category.children || [], level + 1)
    ]);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    onChange(newValue);
  };

  const flatCategories = flattenCategories(categories);
  const validOptions = ['', ...flatCategories.map(cat => cat.id)];

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        value={validOptions.includes(internalValue) ? internalValue : ''}
        onChange={handleChange}
        label={label}
        disabled={disabled || loading}
      >
        <MenuItem value="">
          <em>Nenhuma categoria selecionada</em>
        </MenuItem>
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
            Carregando categorias...
          </MenuItem>
        ) : (
          renderCategoryOptions(categories)
        )}
      </Select>
    </FormControl>
  );
};
