// productCategoryService.js

import axios from 'axios';
import config from '../../config/apiConfig';
import {
  formatCpfForServer,
  formatCpfForDisplay,
  formatCnpjForServer,
  formatCnpjForDisplay,
  validateCpf,
  validateCnpj
} from '../../utils/helpers';

// Função auxiliar para tratamento de erros
const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  if (error.response && error.response.data && error.response.data.error) {
    throw new Error(error.response.data.error);
  } else if (error.response && error.response.data) {
    throw new Error(error.response.data.message || defaultMessage);
  } else {
    throw new Error(defaultMessage);
  }
};

export const createProductCategory = async (categoryData) => {
  const token = localStorage.getItem('authToken');

  try {
    let cleanIdentifier;
    if (categoryData.titular_cpf) {
      cleanIdentifier = categoryData.titular_cpf.replace(/\D/g, '');
    } else if (categoryData.titular_cnpj) {
      cleanIdentifier = categoryData.titular_cnpj.replace(/\D/g, '');
    } else {
      throw new Error('É necessário fornecer um CPF ou CNPJ válido.');
    }

    let formattedCpf = null;
    let formattedCnpj = null;

    if (cleanIdentifier.length === 11) {
      if (!validateCpf(cleanIdentifier)) {
        throw new Error('CPF inválido');
      }
      formattedCpf = formatCpfForServer(cleanIdentifier);
    } else if (cleanIdentifier.length === 14) {
      if (!validateCnpj(cleanIdentifier)) {
        throw new Error('CNPJ inválido');
      }
      formattedCnpj = formatCnpjForServer(cleanIdentifier);
    } else {
      throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
    }

    // Verificação dos campos obrigatórios
    const requiredFields = ['nome'];
    for (const field of requiredFields) {
      if (!categoryData[field]) {
        throw new Error(`Campo obrigatório não preenchido: ${field}`);
      }
    }

    const jsonData = {
      ...categoryData,
      titular_cpf: formattedCpf,
      titular_cnpj: formattedCnpj,
    };

    const response = await axios.post(`${config.apiUrl+config.inventoryApiUrl}/product-categories`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const category = response.data;
    if (category.titular_cpf) {
      category.titular_cpf = formatCpfForDisplay(category.titular_cpf);
    }
    if (category.titular_cnpj) {
      category.titular_cnpj = formatCnpjForDisplay(category.titular_cnpj);
    }
    return category;
  } catch (error) {
    handleServiceError(error, 'Erro ao criar categoria de produto.');
  }
};

export const updateProductCategory = async (categoryId, updatedCategoryData) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.put(`${config.apiUrl+config.inventoryApiUrl}/product-categories/${categoryId}`, updatedCategoryData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const category = response.data;
    if (category.titular_cpf) {
      category.titular_cpf = formatCpfForDisplay(category.titular_cpf);
    }
    if (category.titular_cnpj) {
      category.titular_cnpj = formatCnpjForDisplay(category.titular_cnpj);
    }
    return category;
  } catch (error) {
    handleServiceError(error, 'Erro ao atualizar categoria de produto.');
  }
};

export const deleteProductCategory = async (categoryId) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.delete(`${config.apiUrl+config.inventoryApiUrl}/product-categories/${categoryId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const category = response.data;
    if (category.titular_cpf) {
      category.titular_cpf = formatCpfForDisplay(category.titular_cpf);
    }
    if (category.titular_cnpj) {
      category.titular_cnpj = formatCnpjForDisplay(category.titular_cnpj);
    }
    return category;
  } catch (error) {
    handleServiceError(error, 'Erro ao excluir categoria de produto.');
  }
};

export const getProductCategoryByID = async (categoryId) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.inventoryApiUrl}/product-categories/${categoryId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const category = response.data;
    if (category.titular_cpf) {
      category.titular_cpf = formatCpfForDisplay(category.titular_cpf);
    }
    if (category.titular_cnpj) {
      category.titular_cnpj = formatCnpjForDisplay(category.titular_cnpj);
    }
    return category;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar categoria de produto.');
  }
};

export const getProductCategoriesByIdentifier = async (identifier) => {
  const token = localStorage.getItem('authToken');

  let formattedIdentifier;
  if (validateCpf(identifier)) {
    formattedIdentifier = formatCpfForServer(identifier);
  } else if (validateCnpj(identifier)) {
    formattedIdentifier = formatCnpjForServer(identifier);
  } else {
    throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
  }

  try {
    const response = await axios.get(`${config.apiUrl+config.inventoryApiUrl}/product-categories/${formattedIdentifier}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const categories = response.data.map(category => {
      if (category.titular_cpf) {
        category.titular_cpf = formatCpfForDisplay(category.titular_cpf);
      }
      if (category.titular_cnpj) {
        category.titular_cnpj = formatCnpjForDisplay(category.titular_cnpj);
      }
      return category;
    });

    return categories;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar categorias de produto por CPF ou CNPJ.');
  }
};