// src/utils/profileUtils.js


export const isProfileSelected = (selectedProfiles, identifier) => {


  const result = selectedProfiles.some(profile => profile.cpf === identifier || profile.cnpj === identifier);


  return result;
};