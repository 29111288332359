import axios from 'axios';

import config from '../../config/apiConfig';
import { getProfileDataById, getProfileDataByCpf } from '../profile/userProfileService';
import { getCompanyData } from '../profile/companyProfileService';
import { triggerEvent } from '../../utils/eventSystem';
import { validateEmail, validateCpf, formatCpfForServer, formatCpfForDisplay } from '../../utils/helpers';
// Adicione no topo do arquivo, após o import do axios

export const checkTokenValidity = async (token) => {
  try {
    const response = await axios.get(`${config.apiUrl+config.profileApiUrl}/users/token/validity`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.data.valid) {
      return true;
    } else {
      handleLogout();
      return false;
    }
  } catch (error) {
    console.error('Erro ao verificar a validade do token:', error);
    handleLogout();
    return false;
  }
};

export const handleLogout = (navigate) => {
  localStorage.clear();
  sessionStorage.clear();
  
  window.userData = null;
  
  triggerEvent('logout');

  if (navigate) {
    navigate('/');
  } else {
    window.location.href = '/';
  }
};

export const login = async (email, password, navigate) => {
  try {
    const response = await axios.post(`${config.apiUrl+config.profileApiUrl}/users/login`, { email, password });

    if (!response.data) {
      throw new Error('Erro ao fazer login');
    }

    const { token, user } = response.data;
    const { id, cpf_usuario, email: userEmail, ultima_data_login: lastLoggedIn } = user;

    // Armazenar dados do usuário
    localStorage.setItem('authToken', token);
    localStorage.setItem('userId', id);
    localStorage.setItem('userCpf', formatCpfForDisplay(cpf_usuario));
    localStorage.setItem('userEmail', userEmail);
    localStorage.setItem('lastLoggedIn', lastLoggedIn);

    // Verificar se o usuário tem perfil cadastrado
    try {
      const profileData = await getProfileDataByCpf(cpf_usuario);
      const hasPersonalData = !!profileData;
      localStorage.setItem('hasPersonalData', hasPersonalData.toString());

      if (navigate) {
        navigate(hasPersonalData ? config.loginRedirectUrl : '/personal-data');
      }
    } catch (error) {
      console.error('Erro ao verificar perfil do usuário:', error);
      localStorage.setItem('hasPersonalData', 'false');
      if (navigate) {
        navigate('/personal-data');
      }
    }

    return token;
  } catch (error) {
    console.error('Erro ao fazer login:', error.response ? error.response.data : error.message);
    throw new Error(error.response?.data?.error || 'Erro ao fazer login');
  }
};

export const register = async (email, password, cpf) => {
  try {
    // Valida o email
    if (!validateEmail(email)) {
      throw new Error('Email inválido');
    }

    // Valida o CPF
    if (!validateCpf(cpf)) {
      throw new Error('CPF inválido');
    }

    // Formata o CPF para o formato do servidor
    const formattedCpf = formatCpfForServer(cpf);

    const payload = { email, password, cpf_usuario: formattedCpf };
    const response = await axios.post(`${config.apiUrl+config.profileApiUrl}/users/register`, payload);

    if (!response.data) {
      throw new Error('Erro ao registrar usuário');
    }

    // Chama a função login após o registro bem-sucedido
    await login(email, password);

    return response.data;
  } catch (error) {
    console.error('Erro ao registrar usuário:', error.response ? error.response.data : error.message);
    throw new Error(error.response?.data?.error || 'Erro ao registrar usuário');
  }
};

export const handleChangePassword = async (userData, currentPassword, newPassword, confirmNewPassword, setIsEditingPassword) => {
  if (newPassword !== confirmNewPassword) {
    throw new Error('As senhas não coincidem');
  }

  const email = localStorage.getItem('userEmail');
  const token = localStorage.getItem('authToken');
  const url = `${config.apiUrl+config.authApiUrl}/users/profile`;
  const body = {
    password: newPassword,
  };

  try {
    // Verificar a senha atual
    await login(email, currentPassword);

    // Se a senha atual estiver correta, continue com a atualização da nova senha
    await axios.put(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    setIsEditingPassword(false);
    return 'Senha alterada com sucesso'; // Retorna a mensagem de sucesso
  } catch (error) {
    console.error('Erro ao alterar a senha:', error.response ? error.response.data.error : error.message);
    throw new Error(error.response?.data?.error || error.response?.data?.message || error.message || 'Erro ao alterar a senha');
  }
};

export const checkPersonalDataStatus = async (userId) => {
  try {
    const profileData = await getProfileDataById(userId);
    return !!profileData;
  } catch (error) {
    console.error('Erro ao verificar dados pessoais:', error);
    return false;
  }
};

export const checkOwnership = async (object) => {
  const userCpf = localStorage.getItem('userCpf');
  if (object.titular_cpf === userCpf) {
    return true;
  } else if (object.titular_cnpj) {
    try {
      const company = await getCompanyData(object.titular_cnpj);
      return company && company.cpf_responsavel === userCpf;
    } catch (error) {
      console.error('Erro ao verificar propriedade da empresa:', error);
      return false;
    }
  }
  return false;
};