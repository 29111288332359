import axios from 'axios';

//
// Funções de Formatação e Validação de Data
//

/**
 * Valida se uma data está em um formato aceitável (DD/MM/AAAA, DD/MM/AA, DDMMAAAA, DDMMAA).
 * @param {string} date - A data a ser validada.
 * @returns {boolean} - Retorna true se a data for válida, false caso contrário.
 */
export const validateDate = (date) => {
  if (!date) return false;

  const cleanDate = date.replace(/\D/g, '');

  if (/^\d{8}$/.test(cleanDate)) return true; // DDMMYYYY
  if (/^\d{6}$/.test(cleanDate)) return true; // DDMMYY

  return false;
};

/**
 * Formata uma data para o formato AAAA-MM-DD.
 * Aceita datas nos formatos DD/MM/AAAA, DD/MM/AA, DDMMAAAA, DDMMAA, AAAA-MM-DD.
 * @param {string} date - A data a ser formatada.
 * @returns {string|null} - A data formatada ou null se a data for inválida.
 */
export const formatDateForServer = (date) => {
  if (!date) return null;

  // Verifica se já está no formato AAAA-MM-DD
  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date;
  }

  const cleanDate = date.replace(/\D/g, '');

  if (cleanDate.length === 8) {
    const day = cleanDate.slice(0, 2);
    const month = cleanDate.slice(2, 4);
    const year = cleanDate.slice(4, 8);
    return `${year}-${month}-${day}`;
  }

  if (cleanDate.length === 6) {
    const day = cleanDate.slice(0, 2);
    const month = cleanDate.slice(2, 4);
    const year = cleanDate.slice(4, 6);
    const fullYear = year >= '00' && year <= '21' ? `20${year}` : `19${year}`;
    return `${fullYear}-${month}-${day}`;
  }

  return null;
};

/**
 * Formata uma data do formato AAAA-MM-DD para o formato DD/MM/AAAA.
 * @param {string} date - A data a ser formatada.
 * @returns {string|null} - A data formatada ou null se a data for inválida.
 */
export const formatDateForDisplay = (date) => {
  if (!date) return null;
  const cleanDate = date.split('T')[0].split('-');
  if (cleanDate.length !== 3) return null;
  return `${cleanDate[2]}/${cleanDate[1]}/${cleanDate[0]}`;
};

//
// Funções de Formatação e Validação de CPF
//

/**
 * Formata um CPF para visualização no formato xxx.xxx.xxx-xx.
 * @param {string} cpf - O CPF a ser formatado.
 * @returns {string|null} - O CPF formatado ou null se o CPF for inválido.
 */
export const formatCpfForDisplay = (cpf) => {
  const cleanCpf = cpf.replace(/\D/g, '');
  if (cleanCpf.length !== 11) return null;
  
  return `${cleanCpf.slice(0, 3)}.${cleanCpf.slice(3, 6)}.${cleanCpf.slice(6, 9)}-${cleanCpf.slice(9, 11)}`;
};

/**
 * Formata um CPF do formato visualizado para o formato do servidor xxxxxxxxxxx.
 * @param {string} cpf - O CPF a ser formatado.
 * @returns {string|null} - O CPF formatado ou null se o CPF for inválido.
 */
export const formatCpfForServer = (cpf) => {
  if (!cpf) throw new Error('CPF is undefined or null');
  
  const cleanCpf = cpf.replace(/\D/g, '');
  
  if (cleanCpf.length !== 11) return null;
  
  return cleanCpf;
};

/**
 * Valida se um CPF está no formato correto.
 * @param {string} cpf - O CPF a ser validado.
 * @returns {boolean} - Retorna true se o CPF for válido, false caso contrário.
 */
export const validateCpf = (cpf) => {
  const cleanCpf = cpf.replace(/\D/g, '');
  const re = /^\d{11}$/;
  return re.test(cleanCpf);
};

//
// Funções de Formatação e Validação de CNPJ
//

/**
 * Valida se um CNPJ está no formato correto.
 * @param {string} cnpj - O CNPJ a ser validado.
 * @returns {boolean} - Retorna true se o CNPJ for válido, false caso contrário.
 */
export const validateCnpj = (cnpj) => {
  const cleanCnpj = cnpj.replace(/\D/g, '');

  if (cleanCnpj.length !== 14) return false;

  let tamanho = cleanCnpj.length - 2;
  let numeros = cleanCnpj.substring(0, tamanho);
  const digitos = cleanCnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== parseInt(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cleanCnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  return resultado === parseInt(digitos.charAt(1));
};

/**
 * Formata um CNPJ para visualização no formato xx.xxx.xxx/xxxx-xx.
 * @param {string} cnpj - O CNPJ a ser formatado.
 * @returns {string|null} - O CNPJ formatado ou null se o CNPJ for inválido.
 */
export const formatCnpjForDisplay = (cnpj) => {
  const cleanCnpj = cnpj.replace(/\D/g, '');
  if (cleanCnpj.length !== 14) return null;

  return `${cleanCnpj.slice(0, 2)}.${cleanCnpj.slice(2, 5)}.${cleanCnpj.slice(5, 8)}/${cleanCnpj.slice(8, 12)}-${cleanCnpj.slice(12, 14)}`;
};

/**
 * Formata um CNPJ do formato visualizado para o formato do servidor xxxxxxxxxxxxxx.
 * @param {string} cnpj - O CNPJ a ser formatado.
 * @returns {string|null} - O CNPJ formatado ou null se o CNPJ for inválido.
 * @throws {Error} - Lança um erro se o CNPJ for indefinido ou inválido.
 */
export const formatCnpjForServer = (cnpj) => {
  if (!cnpj || typeof cnpj !== 'string') {
    throw new Error('CNPJ is undefined, null, or not a string');
  }

  const cleanCnpj = cnpj.replace(/\D/g, '');
  if (cleanCnpj.length !== 14) {
    return null;
  }

  return cleanCnpj;
};

/**
 * Obtém os dados de uma empresa com base no CNPJ usando a API pública.
 * @param {string} cnpj - O CNPJ da empresa.
 * @returns {Promise<object>} - Um objeto com os dados da empresa ou um erro se o CNPJ for inválido.
 * @throws {Error} - Lança um erro se ocorrer um problema ao buscar os dados.
 */
export const getCompanyDataByCnpj = async (cnpj) => {
  try {
    const cleanCnpj = formatCnpjForServer(cnpj);
    const response = await fetch(`https://publica.cnpj.ws/cnpj/${cleanCnpj}`);
    const data = await response.json();

    if (data.status === 'ERROR') {
      throw new Error('CNPJ inválido');
    }

    return {
      razao_social: data.razao_social,
      cnpj: data.estabelecimento.cnpj,
      inscricao_estadual: data.estabelecimento.inscricoes_estaduais[0]?.inscricao_estadual || '',
      inscricao_municipal: '', // Não disponível na resposta
      cep: data.estabelecimento.cep,
      cidade: data.estabelecimento.cidade.nome,
      estado: data.estabelecimento.estado.sigla,
      logradouro: data.estabelecimento.logradouro,
      bairro: data.estabelecimento.bairro,
      numero: data.estabelecimento.numero,
      complemento: data.estabelecimento.complemento,
      telefone: data.estabelecimento.telefone1,
      cargo_responsavel: '', // Não disponível na resposta
      setor_atividade: data.estabelecimento.atividade_principal.descricao,
      tipo_empresa: data.natureza_juridica.descricao,
      numero_funcionarios: '', // Não disponível na resposta
      cpf_responsavel: '' // Não disponível na resposta
    };
  } catch (error) {
    console.error("Erro ao buscar dados da empresa:", error.message);
    throw new Error('Erro ao buscar dados da empresa');
  }
};


//
// Funções de Formatação e Validação de CEP
//

/**
 * Formata um CEP para visualização no formato nnnnn-nnn.
 * @param {string} cep - O CEP a ser formatado.
 * @returns {string|null} - O CEP formatado ou null se o CEP for inválido.
 */
export const formatCepForDisplay = (cep) => {
  const cleanCep = cep.replace(/\D/g, '');
  if (cleanCep.length !== 8) return null;
  return `${cleanCep.slice(0, 5)}-${cleanCep.slice(5, 8)}`;
};

/**
 * Formata um CEP do formato visualizado para o formato do servidor nnnnnnnn.
 * @param {string} cep - O CEP a ser formatado.
 * @returns {string|null} - O CEP formatado ou null se o CEP for inválido.
 */
export const formatCepForServer = (cep) => {
  const cleanCep = cep.replace(/\D/g, '');
  if (cleanCep.length !== 8) return null;
  return cleanCep;
};

/**
 * Busca o endereço correspondente a um CEP utilizando a API ViaCEP.
 * @param {string} cep - O CEP a ser buscado.
 * @returns {Promise<object>} - Os dados do endereço ou um erro se o CEP for inválido.
 */
export const fetchAddressByCep = async (cep) => {
  try {
    const cleanCep = formatCepForServer(cep);
    if (!cleanCep) throw new Error('CEP inválido');

    const response = await axios.get(`https://viacep.com.br/ws/${cleanCep}/json/`);
    return response.data;
  } catch (error) {
    throw new Error('Erro ao buscar endereço');
  }
};

/**
 * Valida se um CEP está no formato correto.
 * @param {string} cep - O CEP a ser validado.
 * @returns {boolean} - Retorna true se o CEP for válido, false caso contrário.
 */
export const validateCep = (cep) => {
  const cleanCep = cep.replace(/\D/g, '');
  const re = /^\d{8}$/;
  return re.test(cleanCep);
};

//
// Funções Gerais
//

/**
 * Obtém as iniciais de um nome e sobrenome, ou as primeiras letras das palavras do nome.
 * Se o segundo nome tiver apenas duas letras, usará a primeira letra do terceiro nome, se disponível.
 * Apenas letras serão consideradas como iniciais.
 * @param {string} nome - O nome da pessoa.
 * @param {string} [sobrenome] - O sobrenome da pessoa (opcional).
 * @returns {string} - As iniciais do nome e sobrenome, ou as primeiras letras das palavras do nome.
 */
export const getInitials = (nome, sobrenome) => {
  if (!nome) return '';

  // Remove todos os caracteres que não são letras
  const cleanName = nome.replace(/[^a-zA-Z\s]/g, '');
  const cleanSurname = sobrenome ? sobrenome.replace(/[^a-zA-Z\s]/g, '') : '';

  const words = cleanName.split(' ');

  if (cleanSurname) {
    return `${cleanName.charAt(0)}${cleanSurname.charAt(0)}`.toUpperCase();
  } else if (words.length > 1) {
    const firstInitial = words[0].charAt(0);
    let secondInitial = words[1].charAt(0);

    // Se o segundo nome tiver apenas duas letras e houver um terceiro nome, use a inicial do terceiro nome
    if (words[1].length === 2 && words.length > 2) {
      secondInitial = words[2].charAt(0);
    }

    return `${firstInitial}${secondInitial}`.toUpperCase();
  } else {
    return cleanName.substring(0, 2).toUpperCase();
  }
};


/**
 * Formata os dados do perfil para exibição.
 * @param {object} data - Os dados do perfil.
 * @returns {object} - Os dados formatados para exibição.
 */
export const formatProfileData = (data) => {
  if (!data) return {};

  const formattedData = {
    ...data,
    data_nascimento: data.data_nascimento ? formatDateForDisplay(data.data_nascimento) : null,
    cpf: data.cpf ? formatCpfForDisplay(data.cpf) : null,
    cep: data.cep ? formatCepForDisplay(data.cep) : null,
    perfil_compartilhado: Array.isArray(data.perfil_compartilhado) ? data.perfil_compartilhado.map(cpf => formatCpfForDisplay(cpf)) : [],
    membro_empresa: Array.isArray(data.membro_empresa) ? data.membro_empresa.map(cnpj => formatCnpjForDisplay(cnpj)) : []
  };

  return formattedData;
};

/**
 * Formata os dados do perfil da empresa para exibição.
 * @param {object} data - Os dados do perfil da empresa.
 * @returns {object} - Os dados formatados para exibição.
 */
export const formatCompanyProfile = (data) => {
  if (!data) return {};
  return {
    ...data,
    cnpj: formatCnpjForDisplay(data.cnpj),
    cpf_responsavel: formatCpfForDisplay(data.cpf_responsavel),
    cep: formatCepForDisplay(data.cep),
  };
};

/**
 * Valida se um email está no formato correto.
 * @param {string} email - O email a ser validado.
 * @returns {boolean} - Retorna true se o email for válido, false caso contrário.
 */
export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Formata um valor numérico para o padrão de moeda brasileira (R$ xxx,xx).
 * @param {number|string} value - O valor a ser formatado.
 * @returns {string} - O valor formatado como moeda.
 */
export const formatCurrency = (value) => {
  // Verifica se o valor é nulo, indefinido ou NaN
  if (value === null || value === undefined || isNaN(value)) {
    return 'R$ 0,00';
  }

  // Converte para número se for uma string
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  // Verifica se o valor é um número válido
  if (isNaN(numericValue)) {
    return 'R$ 0,00';
  }

  // Trata especificamente o caso de zero
  if (numericValue === 0) {
    return 'R$ 0,00';
  }

  return numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};