import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography } from '@mui/material';
import { updateCompany } from '../../../../services/profile/companyProfileService';

const EditCompanyNameModal = ({ open, handleClose, companyData, setCompanyData }) => {
  const [companyName, setCompanyName] = useState(companyData.razao_social);
  const [error, setError] = useState('');

  const handleSave = async () => {
    try {
      const updatedData = await updateCompany(companyData.cnpj, { razao_social: companyName });
      setCompanyData(prevData => ({
        ...prevData,
        razao_social: updatedData.razao_social,
      }));
      handleClose();
    } catch (error) {
      setError(error.response?.data?.error || 'Erro ao atualizar a razão social.');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Razão Social</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="companyName"
          label="Razão Social"
          type="text"
          fullWidth
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          variant="outlined" // Aumenta o campo de texto
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompanyNameModal;
