import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { onEvent, offEvent } from './utils/eventSystem';
import { ProfileProvider } from './context/profileContext';

import Dashboard from './modules/Dashboard';

// Auth
import Login from './modules/auth/login';
import Signup from './modules/auth/signup';
import PersonalData from './modules/auth/personalData';

// Profile
import Profile from './modules/profile/user/userProfiles';
import CompanyProfiles from './modules/profile/company/companyProfiles';

// Finance
import BankAccounts from './modules/finance/bankAccounts/bankAccounts';
import CreditCards from './modules/finance/creditCards/creditCard';
import Transactions from './modules/finance/transactions/transactions';
import FinancialOperations from './modules/finance/financialOperations/financialOperations';

// Inventory
import ProductAttributes from './modules/inventory/productAttributes/productAttributes';

// Sales
import Quotations from './modules/sales/quotations/quotations';

import './app.css';

function App() {
  useEffect(() => {
    const handleLogout = () => {
      window.location.href = '/';
    };

    const handleProfileChange = (event) => {
      const { newProfile } = event.detail;
      console.log('Perfil trocado para:', newProfile);
    };

    onEvent('logout', handleLogout);
    onEvent('profileChange', handleProfileChange);

    return () => {
      offEvent('logout', handleLogout);
      offEvent('profileChange', handleProfileChange);
    };
  }, []);

  return (
    <ProfileProvider>
      <div className="app">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/personal-data" element={<PersonalData />} />
          <Route path="/user-profiles" element={<Profile />} />
          <Route path="/company-profiles" element={<CompanyProfiles />} />
          <Route path="/bank-accounts" element={<BankAccounts />} />
          <Route path="/credit-card" element={<CreditCards />} />
          <Route path="/financial-operations" element={<FinancialOperations />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/product-attributes" element={<ProductAttributes />} />
          <Route path="/quotations" element={<Quotations />} />
        </Routes>
      </div>
    </ProfileProvider>
  );
}

export default App;
