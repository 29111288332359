// hooks/withAuth.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkTokenValidity, handleLogout } from '../services/auth/authService';
import config from '../config/apiConfig';

const withAuth = (WrappedComponent) => {
  const AuthHOC = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const token = localStorage.getItem('authToken');

      if (!token) {
        navigate(config.defaultRedirectUrl); // URL de não logado
        return;
      }

      const validateToken = async () => {
        const isValid = await checkTokenValidity(token);
        if (!isValid) {
          handleLogout(navigate);
        }
        return isValid;
      };

      const initAuth = async () => {
        await validateToken();
      };

      initAuth();

      const interval = setInterval(initAuth, 15 * 60 * 1000); // Verifica a cada 15 minutos

      return () => clearInterval(interval);
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return AuthHOC;
};

export default withAuth;
