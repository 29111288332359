import React, { useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { getInitials } from '../../../../utils/helpers';

import { changeFile, uploadCompanyLogo } from '../../../../services/profile/companyProfileService';


const EditCompanyLogoModal = ({ open, handleClose, setCompanyData, logoImage, razaoSocial, cnpj }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(logoImage || null);
  const [errorMessage, setErrorMessage] = useState('');


  const handleFileSelect = (event) => {
    changeFile(event, setSelectedFile);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      // Enviar o arquivo e o CNPJ para a função de upload
      const updatedLogoImage = await uploadCompanyLogo(cnpj, selectedFile);
      setCompanyData((prevData) => ({
        ...prevData,
        logo_image: updatedLogoImage,
      }));

      handleClose();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modalBox" sx={{ textAlign: 'center', p: 3, position: 'relative' }}>
        <IconButton aria-label="close" onClick={handleClose} className="modalCloseButton" sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Atualizar Logo da Empresa
        </Typography>
        <Box mt={2} mb={2}>
          {preview ? (
            <Avatar
              src={preview}
              alt="Company Logo"
              sx={{ width: 200, height: 200, margin: 'auto', borderRadius: '50%' }}
            />
          ) : (
            <Avatar sx={{ width: 200, height: 200, margin: 'auto', borderRadius: '50%', bgcolor: 'primary.main', fontSize: 80 }}>
              {getInitials(razaoSocial)}
            </Avatar>
          )}
        </Box>
        <Box mt={2}>
          <input type="file" onChange={handleFileSelect} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mt: 2 }}
          >
            Salvar
          </Button>
        </Box>
        {errorMessage && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default EditCompanyLogoModal;
