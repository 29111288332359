import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider } from '@mui/material';
import { ExpandLess, ExpandMore, ExitToApp, DirectionsCar, CreditCard, House, AccountBalance, Category, Inventory, Description } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from '../services/auth/authService';
import robotLogo from '../img/robot.png';
import './sidebar.css';

const Sidebar = () => {
  const [open, setOpen] = React.useState({});
  const navigate = useNavigate();

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  return (
    <div className="sidebar">
      <div className="sidebarHeader">
        <img src={robotLogo} alt="Logo" className="sidebarLogo" />
        <h3 className="sidebarTitle">EI MONA</h3>
      </div>
      <List component="nav" className="sidebarNav">
        <Divider />
        <ListItem onClick={() => handleClick('patrimonial')}>
          <ListItemText primary="Gestão Patrimonial" />
          {open.patrimonial ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.patrimonial} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem onClick={() => navigate('/imoveis')}>
              <ListItemIcon>
                <House />
              </ListItemIcon>
              <ListItemText primary="Imóveis" />
            </ListItem>
            <ListItem onClick={() => navigate('/veiculos')}>
              <ListItemIcon>
                <DirectionsCar />
              </ListItemIcon>
              <ListItemText primary="Veículos" />
            </ListItem>
            <ListItem onClick={() => navigate('/ativos')}>
              <ListItemIcon>
                <Inventory />
              </ListItemIcon>
              <ListItemText primary="Ativos" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem onClick={() => handleClick('financeiro')}>
          <ListItemText primary="Financeiro" />
          {open.financeiro ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.financeiro} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem onClick={() => navigate('/transactions')}>
              <ListItemIcon>
                <DirectionsCar />
              </ListItemIcon>
              <ListItemText primary="Transações" />
            </ListItem>
            <ListItem onClick={() => navigate('/financial-operations')}>
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary="Operações Financeiras" />
            </ListItem>
            <ListItem onClick={() => navigate('/bank-accounts')}>
              <ListItemIcon>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText primary="Contas Bancárias" />
            </ListItem>
            <ListItem onClick={() => navigate('/credit-card')}>
              <ListItemIcon>
                <CreditCard />
              </ListItemIcon>
              <ListItemText primary="Cartões de Crédito" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem onClick={() => handleClick('estoque')}>
          <ListItemText primary="Estoque" />
          {open.estoque ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.estoque} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem onClick={() => navigate('/product-attributes')}>
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary="Parâmetros de Produtos" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem onClick={() => handleClick('vendas')}>
          <ListItemText primary="Vendas" />
          {open.vendas ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.vendas} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem onClick={() => navigate('/quotations')}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary="Orçamentos" />
            </ListItem>
          </List>
        </Collapse>
        <Divider />
      </List>
      <div className="sidebarFooter">
        <Divider />
        <ListItem onClick={() => handleLogout(navigate)}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </div>
    </div>
  );
};

export default Sidebar;
