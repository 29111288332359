import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Stack, Box, MenuItem, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import ProfileImageModal from './profileImage';
import EditNameModal from './editName';
import { estados, generos, nacionalidades } from '../../../../config/constants';
import { getInitials, fetchAddressByCep, formatDateForDisplay, formatCepForServer } from '../../../../utils/helpers';
import { getProfileDataByCpf, updateProfileData, getUserData } from '../../../../services/profile/userProfileService';


const PersonalInfo = ({ cpf, token, refreshAllComponents }) => {
  const [profileData, setProfileData] = useState(null);
  const [originalProfileData, setOriginalProfileData] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openEditNameModal, setOpenEditNameModal] = useState(false);
  const [cepError, setCepError] = useState('');
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); 
  const [successMessage, setSuccessMessage] = useState(''); 

useEffect(() => {
  const fetchUserData = async () => {
    const data = await getUserData();
    if (data) {
      setIsEditable(cpf === data.cpf_usuario);
    }
  };

  fetchUserData();
}, [cpf, token]);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (cpf && token) {
        try {
          const data = await getProfileDataByCpf(cpf, token);
          setProfileData(data);
          setOriginalProfileData(data);
        } catch (error) {
          console.error('Erro ao buscar dados do perfil:', error);
          setErrorMessage('Erro ao carregar os dados do perfil.');
        }
      }
    };

    fetchProfileData();
  }, [cpf, token]);

  const handleOpenImageModal = () => setOpenImageModal(true);
  const handleCloseImageModal = () => setOpenImageModal(false);

  const handleOpenEditNameModal = () => setOpenEditNameModal(true);
  const handleCloseEditNameModal = () => setOpenEditNameModal(false);

  const handleSaveProfileData = async (updatedFields) => {
    try {
      await updateProfileData(cpf, updatedFields, token);

      if (updatedFields.data_nascimento) {
        updatedFields.data_nascimento = formatDateForDisplay(updatedFields.data_nascimento);
      }

      setProfileData((prevData) => ({
        ...prevData,
        ...updatedFields,
      }));
      setOriginalProfileData((prevData) => ({
        ...prevData,
        ...updatedFields,
      }));
      setIsDataChanged(false);
      setSuccessMessage('Dados pessoais atualizados com sucesso!');
      setErrorMessage('');
    } catch (error) {
      console.error("Erro completo:", error);
      const serverMessage = error.response?.data?.error || error.message || 'Erro ao atualizar os dados pessoais.';
      setErrorMessage(serverMessage);
      setSuccessMessage('');
    }
  };

  const handlePersonalDataChange = (event) => {
    const { name, value } = event.target;

    const newProfileData = { ...profileData, [name]: value };
    setProfileData(newProfileData);

    if (name === 'cep') {
      handleFetchAddressByCep(value);
    }

    const isChanged = Object.keys(newProfileData).some(key => newProfileData[key] !== originalProfileData[key]);
    setIsDataChanged(isChanged);
  };

  const handleFetchAddressByCep = async (cep) => {
    const cleanCep = formatCepForServer(cep);

    if (cleanCep) {
      try {
        const data = await fetchAddressByCep(cleanCep);
        if (data.erro) {
          setCepError('CEP inválido');
        } else {
          setProfileData((prevData) => ({
            ...prevData,
            logradouro: data.logradouro,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
            numero: '',
            complemento: ''
          }));
          setCepError('');
        }
      } catch (error) {
        setCepError('Erro ao buscar endereço');
      }
    } else {
      setCepError('');
    }
  };

  const handleSaveChanges = async () => {
    const updatedFields = {};

    Object.keys(profileData).forEach((key) => {
      if (profileData[key] !== originalProfileData[key]) {
        updatedFields[key] = profileData[key];
      }
    });

    if (Object.keys(updatedFields).length > 0) {
      await handleSaveProfileData(updatedFields);
      if (refreshAllComponents) {
        refreshAllComponents();
      }
    }
  };

  if (!profileData) {
    return null;
  }

  return (
    <div className="profileCard personalInfo">
      <div className="profileHeader">
        <div className="profileAvatarContainer" onClick={isEditable ? handleOpenImageModal : null}>
          {profileData.profile_image ? (
            <img src={profileData.profile_image} alt="User" className="profileAvatar" />
          ) : (
            <div className="profileAvatar">
              {getInitials(profileData.nome, profileData.sobrenome)}
            </div>
          )}
          {isEditable && <div className="editAvatar">Clique para alterar</div>}
        </div>
        <div className="profileName">
          <Typography variant="h5" display="inline">{profileData.nome} {profileData.sobrenome}</Typography>
          {isEditable && (
            <IconButton onClick={handleOpenEditNameModal}>
              <Edit />
            </IconButton>
          )}
        </div>
      </div>

      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}
      <div className="profileInfo">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="CPF"
              variant="outlined"
              value={profileData.cpf}
              disabled
              className="textFieldReadOnly"
              InputLabelProps={{
                className: "formLabel",
              }}
              InputProps={{
                className: "formInputReadOnly",
              }}
              sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
            />
            <Box flexGrow={1} /> {/* Espaço flexível */}
          </Stack>
          {profileData.data_nascimento && (
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Data de Nascimento"
                variant="outlined"
                name="data_nascimento"
                value={profileData.data_nascimento}
                onChange={isEditable ? handlePersonalDataChange : null}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
                disabled={!isEditable}
              />
            </Stack>
          )}
          {profileData.cep && (
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="CEP"
                variant="outlined"
                name="cep"
                value={profileData.cep || ''}
                onChange={isEditable ? handlePersonalDataChange : null}
                error={!!cepError}
                helperText={isEditable && !cepError ? "Ao digitar o CEP, preencheremos automaticamente os campos equivalentes." : cepError}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
                disabled={!isEditable}
                sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
                />
                <Box flexGrow={1} /> {/* Espaço flexível */}
            </Stack>
          )}
          {profileData.cidade && profileData.estado && (
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Cidade"
                variant="outlined"
                name="cidade"
                value={profileData.cidade}
                onChange={isEditable ? handlePersonalDataChange : null}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
                disabled={!isEditable}
                sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
              />
              <TextField
                select
                fullWidth
                label="Estado"
                variant="outlined"
                name="estado"
                value={profileData.estado}
                onChange={isEditable ? handlePersonalDataChange : null}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
                disabled={!isEditable}
                sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
              >
                {estados.map((estado) => (
                  <MenuItem key={estado.sigla} value={estado.sigla}>
                    {estado.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          )}
          {profileData.logradouro && (
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Logradouro"
                variant="outlined"
                name="logradouro"
                value={profileData.logradouro}
                onChange={isEditable ? handlePersonalDataChange : null}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
                disabled={!isEditable}
              />
            </Stack>
          )}
          {(profileData.numero || profileData.complemento) && (
            <Stack direction="row" spacing={2}>
              {profileData.numero && (
                <TextField
                  fullWidth
                  label="Número"
                  variant="outlined"
                  name="numero"
                  value={profileData.numero}
                  onChange={isEditable ? handlePersonalDataChange : null}
                  className="textField"
                  InputLabelProps={{
                    className: "formLabel",
                  }}
                  InputProps={{
                    className: "formInput",
                  }}
                  disabled={!isEditable}
                />
              )}
              {profileData.complemento && (
                <TextField
                  fullWidth
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  value={profileData.complemento}
                  onChange={isEditable ? handlePersonalDataChange : null}
                  className="textField"
                  InputLabelProps={{
                    className: "formLabel",
                  }}
                  InputProps={{
                    className: "formInput",
                  }}
                  disabled={!isEditable}
                />
              )}
            </Stack>
          )}
          {profileData.telefone && (
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Telefone"
                variant="outlined"
                name="telefone"
                value={profileData.telefone}
                onChange={isEditable ? handlePersonalDataChange : null}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
                disabled={!isEditable}
                sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
                />
                <Box flexGrow={1} /> {/* Espaço flexível */}
            </Stack>
          )}
          {(profileData.genero || profileData.nacionalidade) && (
            <Stack direction="row" spacing={2}>
              {profileData.genero && (
                <TextField
                  select
                  fullWidth
                  label="Gênero"
                  variant="outlined"
                  name="genero"
                  value={profileData.genero}
                  onChange={isEditable ? handlePersonalDataChange : null}
                  className="textField"
                  InputLabelProps={{
                    className: "formLabel",
                  }}
                  InputProps={{
                    className: "formInput",
                  }}
                  disabled={!isEditable}
                >
                  {generos.map((genero) => (
                    <MenuItem key={genero.value} value={genero.value}>
                      {genero.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {profileData.nacionalidade && (
                <TextField
                  select
                  fullWidth
                  label="Nacionalidade"
                  variant="outlined"
                  name="nacionalidade"
                  value={profileData.nacionalidade}
                  onChange={isEditable ? handlePersonalDataChange : null}
                  className="textField"
                  InputLabelProps={{
                    className: "formLabel",
                  }}
                  InputProps={{
                    className: "formInput",
                  }}
                  disabled={!isEditable}
                >
                  {nacionalidades.map((nacionalidade) => (
                    <MenuItem key={nacionalidade.valor} value={nacionalidade.valor}>
                      {nacionalidade.nome}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Stack>
          )}
        </Stack>
        <Box my={2}></Box>
        {isDataChanged && isEditable && (
          <Button
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            onClick={handleSaveChanges}
          >
            Salvar Alterações
          </Button>
        )}
      </div>
      {isEditable && (
        <>
          <ProfileImageModal 
            open={openImageModal} 
            handleClose={handleCloseImageModal} 
            setProfileData={setProfileData} 
            profileImage={profileData.profile_image} 
            nome={profileData.nome} 
            sobrenome={profileData.sobrenome} 
            refreshPersonalInfo={refreshAllComponents} 
            refreshPersonalProfiles={refreshAllComponents} 
          />
          <EditNameModal 
            open={openEditNameModal} 
            handleClose={handleCloseEditNameModal} 
            profileData={profileData} 
            setProfileData={setProfileData} 
            token={token} 
          />
        </>
      )}
    </div>
  );
};

export default PersonalInfo;
