import React, { useState, useEffect } from 'react';
import { TextField, Typography, IconButton, InputAdornment, Button, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { formatCpfForDisplay } from '../../../../utils/helpers';
import { getUserData } from '../../../../services/profile/userProfileService';
import { handleChangePassword } from '../../../../services/auth/authService';


const UserInfo = () => {
  const [userData, setUserData] = useState(null);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isPasswordFieldsFilled, setIsPasswordFieldsFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Estado para mensagem de erro
  const [successMessage, setSuccessMessage] = useState(''); // Estado para mensagem de sucesso

const updateUserData = async () => {
  const data = await getUserData();
  if (data) {
    setUserData(data);
  }
};

  const authToken = localStorage.getItem('authToken');

  useEffect(() => {
    if (authToken) {
      updateUserData();
    }
  }, [authToken]);

  useEffect(() => {
    setIsPasswordFieldsFilled(
      currentPassword.trim() !== '' || newPassword.trim() !== '' || confirmNewPassword.trim() !== ''
    );
  }, [currentPassword, newPassword, confirmNewPassword]);

  const handleEditPassword = () => {
    setIsEditingPassword(true);
  };

  const handleCancelEditPassword = () => {
    setIsEditingPassword(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setErrorMessage(''); // Limpa a mensagem de erro ao cancelar a edição da senha
    setSuccessMessage(''); // Limpa a mensagem de sucesso ao cancelar a edição da senha
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSaveNewPassword = async () => {
    try {
      const successMsg = await handleChangePassword(userData, currentPassword, newPassword, confirmNewPassword, setIsEditingPassword);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setErrorMessage(''); // Limpa a mensagem de erro após salvar com sucesso
      setSuccessMessage(successMsg); // Define a mensagem de sucesso
    } catch (error) {
      console.error('Erro ao alterar a senha:', error);
      const serverMessage = error.response?.data?.error || error.response?.data?.message || error.response?.data || error.message;
      setErrorMessage(serverMessage);
      setSuccessMessage(''); // Limpa a mensagem de sucesso em caso de erro
    }
  };

  if (!userData) {
    return <Typography variant="body2">Carregando dados do usuário...</Typography>;
  }

  // Formatar o CPF para exibição
  const cpfFormatado = formatCpfForDisplay(userData.cpf_usuario);

  return (
    <div className="profileCard">
      <Typography variant="h5">Dados do usuário</Typography>

      {/* Exibe mensagens de erro ou sucesso */}
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}

      <div className="profileInfo">
        <TextField
          fullWidth
          margin="normal"
          label="E-mail"
          variant="outlined"
          value={userData.email}
          disabled
          className="textFieldReadOnly"
          InputLabelProps={{
            className: "formLabel",
          }}
          InputProps={{
            className: "formInputReadOnly",
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="CPF"
          variant="outlined"
          value={cpfFormatado} // Exibe o CPF formatado
          disabled
          className="textFieldReadOnly"
          InputLabelProps={{
            className: "formLabel",
          }}
          InputProps={{
            className: "formInputReadOnly",
          }}
        />
        {isEditingPassword ? (
          <div>
            <TextField
              fullWidth
              margin="normal"
              label="Senha Atual"
              variant="outlined"
              type={showCurrentPassword ? 'text' : 'password'}
              className="textField"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputLabelProps={{
                className: "formLabel",
              }}
              InputProps={{
                className: "formInput",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Nova Senha"
              variant="outlined"
              type={showNewPassword ? 'text' : 'password'}
              className="textField"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputLabelProps={{
                className: "formLabel",
              }}
              InputProps={{
                className: "formInput",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirme a Nova Senha"
              variant="outlined"
              type={showNewPassword ? 'text' : 'password'}
              className="textField"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              InputLabelProps={{
                className: "formLabel",
              }}
              InputProps={{
                className: "formInput",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box my={2}></Box> {/* Adiciona um espaçamento */}
            {isPasswordFieldsFilled ? (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                onClick={handleSaveNewPassword}
              >
                Salvar Nova Senha
              </Button>
            ) : (
              <Button
                fullWidth
                type="button"
                variant="outlined"
                size="large"
                onClick={handleCancelEditPassword}
              >
                Cancelar
              </Button>
            )}
          </div>
        ) : (
          <>
            <Box my={2}></Box> {/* Adiciona um espaçamento */}
            <Button
              fullWidth
              type="button"
              variant="outlined"
              size="large"
              onClick={handleEditPassword}
            >
              Alterar Senha
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
