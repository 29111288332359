// src/modules/finance/financialOperation/financialOperations.js
import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import './financialOperations.css';
import { ProfileContext } from '../../../context/profileContext';
import withAuth from '../../../hooks/withAuth';

import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import FinancialOperationsCard from './cards/financialOperationsCard';

const FinancialOperations = () => {
  const { selectedProfiles } = useContext(ProfileContext);

  return (
    <div className="financialOperationsContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="financialOperationsContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Gestão de Operações Financeiras
            </Typography>
          </Box>
          {selectedProfiles.length > 0 ? (
            <FinancialOperationsCard />
          ) : (
            <Typography variant="body1">
              Selecione um perfil para visualizar as operações associadas.
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuth(FinancialOperations);
