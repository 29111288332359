// src/modules/finance/transactions/transactions.js

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Modal, Stack } from '@mui/material';

import './transactions.css';
import { ProfileContext } from '../../../context/profileContext';
import withAuth from '../../../hooks/withAuth';

import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import LastTransactions from './cards/lastTransactions';
import SingleTransactionForm from './cards/singleTransactionForm';
import MultipleTransactionsForm from './cards/multipleTransactionsForm';
import TransactionInfo from './cards/transactionInfo';

const Transactions = () => {
  const { selectedProfiles } = useContext(ProfileContext);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [lastTransactionsKey, setLastTransactionsKey] = useState(0);
  const [lastTransactionsFilters, setLastTransactionsFilters] = useState({
    data_inicio: '',
    data_fim: '',
    tipo_data: 'data_hora',
    id_conta_bancaria: '',
    id_cartao: '',
    dateRange: '30', // Definindo um valor padrão
  });
  const [showSingleTransactionModal, setShowSingleTransactionModal] = useState(false);
  const [showMultipleTransactionsModal, setShowMultipleTransactionsModal] = useState(false);

  useEffect(() => {
    setSelectedTransactionId(null);
    setTransactions([]);
    setLastTransactionsKey((prevKey) => prevKey + 1);
  }, [selectedProfiles]);

  const handleSelectTransaction = (transactionId) => {
    setSelectedTransactionId(transactionId);
  };

  const handleRegisterTransaction = (isMultiple = false) => {
    if (isMultiple) {
      setShowMultipleTransactionsModal(true);
    } else {
      setShowSingleTransactionModal(true);
    }
  };

  const handleCloseSingleTransactionModal = () => {
    setShowSingleTransactionModal(false);
  };

  const handleCloseMultipleTransactionsModal = () => {
    setShowMultipleTransactionsModal(false);
  };

  const handleTransactionRegistered = (newTransaction) => {
    setTransactions((prevTransactions) => [newTransaction, ...prevTransactions]);
    setSelectedTransactionId(newTransaction.id);
    setLastTransactionsKey((prevKey) => prevKey + 1);
  };

  const handleTransactionUpdated = useCallback(() => {
    setLastTransactionsKey((prevKey) => prevKey + 1);
  }, []);

  const handleLastTransactionsFiltersChange = useCallback((newFilters) => {
    setLastTransactionsFilters(newFilters);
  }, []);

  // Estilo do modal
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Você pode ajustar a largura aqui
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh', // Altura máxima do modal
    overflowY: 'auto', // Adiciona barra de rolagem vertical se necessário
  };

  return (
    <div className="transactionsContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="transactionsContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Transações Financeiras
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {/* Coluna Esquerda */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                {selectedProfiles.length > 0 ? (
                  <LastTransactions
                    key={lastTransactionsKey}
                    onSelectTransaction={handleSelectTransaction}
                    transactions={transactions}
                    setTransactions={setTransactions}
                    showAccountFilter={true}
                    showCreditCardFilter={true}
                    showRegisterButton={true}
                    onRegisterTransaction={handleRegisterTransaction}
                    initialFilters={lastTransactionsFilters}
                    onFiltersChange={handleLastTransactionsFiltersChange}
                    passAccountOrCardInfoToModals={false} // Não passar informações para os modais
                  />
                ) : (
                  <Typography>
                    Selecione um perfil para visualizar as transações associadas.
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* Coluna Direita */}
            <Grid item xs={12} md={6}>
              {selectedTransactionId && (
                <TransactionInfo
                  transactionId={selectedTransactionId}
                  onUpdateTransactionList={handleTransactionUpdated}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Modal para RegisterTransaction */}
      <Modal
        open={showSingleTransactionModal}
        onClose={handleCloseSingleTransactionModal}
        aria-labelledby="modal-single-transaction"
        aria-describedby="modal-single-transaction-form"
      >
        <Box sx={modalStyle}>
          <SingleTransactionForm
            onTransactionRegistered={handleTransactionRegistered}
            onClose={handleCloseSingleTransactionModal}
            lastTransactionsFilters={lastTransactionsFilters}
          />
        </Box>
      </Modal>

      <Modal
        open={showMultipleTransactionsModal}
        onClose={handleCloseMultipleTransactionsModal}
        aria-labelledby="modal-multiple-transactions"
        aria-describedby="modal-multiple-transactions-form"
      >
        <Box sx={modalStyle}>
          <MultipleTransactionsForm
            onTransactionRegistered={handleTransactionRegistered}
            onClose={handleCloseMultipleTransactionsModal}
            lastTransactionsFilters={lastTransactionsFilters}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default withAuth(Transactions);
