import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Box } from '@mui/material';
import { shareUserAccess } from '../../../../services/profile/userProfileService';

const ShareProfileModal = ({ open, handleClose, onShareSuccess }) => {
  const [cpf, setCpf] = useState('');
  const [error, setError] = useState('');

  const handleShareProfile = async () => {
    setError(''); // Limpa o erro anterior

    try {
      await shareUserAccess(cpf, onShareSuccess, handleClose, setError);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleChangeCpf = (event) => {
    setCpf(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Compartilhar Perfil</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para compartilhar seu perfil, insira o CPF do usuário com quem você deseja compartilhar.
        </DialogContentText>
        <Box my={2}></Box>
        <TextField
          autoFocus
          margin="dense"
          id="cpf"
          label="CPF do usuário"
          type="text"
          fullWidth
          value={cpf}
          onChange={handleChangeCpf}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleShareProfile} color="primary">
          Compartilhar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareProfileModal;
