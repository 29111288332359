// src/modules/finance/creditCards/creditCards.js

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Modal, Stack } from '@mui/material';

import './creditCard.css';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import { ProfileContext } from '../../../context/profileContext';
import withAuth from '../../../hooks/withAuth';

import { getCreditCardByID } from '../../../services/finance/creditCardService';

import ListCreditCards from './cards/listCreditCards';
import CreditCardInfo from './cards/creditCardInfo';
import RegisterCreditCard from './cards/registerCreditCard';
import LastTransactions from '../transactions/cards/lastTransactions';
import SingleTransactionForm from '../transactions/cards/singleTransactionForm';
import MultipleTransactionsForm from '../transactions/cards/multipleTransactionsForm';
import TransactionInfo from '../transactions/cards/transactionInfo';

const CreditCards = () => {
  const { selectedProfiles } = useContext(ProfileContext);
  const [selectedCreditCardId, setSelectedCreditCardId] = useState(null);
  const [showRegisterCreditCard, setShowRegisterCreditCard] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [lastTransactionsKey, setLastTransactionsKey] = useState(0);
  const [lastTransactionsFilters, setLastTransactionsFilters] = useState({
    data_inicio: '',
    data_fim: '',
    tipo_data: 'data_hora',
    id_cartao: '',
    dateRange: '30',
  });
  const [showSingleTransactionModal, setShowSingleTransactionModal] = useState(false);
  const [showMultipleTransactionsModal, setShowMultipleTransactionsModal] = useState(false);
  const [transactionInitialData, setTransactionInitialData] = useState({
    titular: '',
    creditCardId: '',
  });

  const handleSelectCreditCard = useCallback(async (creditCardId) => {
    setSelectedCreditCardId(creditCardId);
    setShowRegisterCreditCard(false);
    setIsInitialLoad(false);
    
    try {
      const creditCard = await getCreditCardByID(creditCardId);
      setSelectedCreditCard(creditCard);
    } catch (error) {
      console.error('Erro ao buscar detalhes do cartão de crédito:', error);
    }
  }, []);

  const handleRegisterNewCreditCard = useCallback(() => {
    setSelectedCreditCardId(null);
    setShowRegisterCreditCard(true);
    setIsInitialLoad(false);
  }, []);

  const handleCreditCardRegistered = useCallback((newCreditCard) => {
    setCreditCards((prevCreditCards) => [...prevCreditCards, newCreditCard]);
    setSelectedCreditCardId(newCreditCard.id);
    setShowRegisterCreditCard(false);

    setTimeout(() => {
      setIsInitialLoad(true);
    }, 100);
  }, []);

  const handleUpdateCreditCardList = useCallback((updatedCreditCard) => {
    setCreditCards((prevCreditCards) =>
      prevCreditCards.map((creditCard) =>
        creditCard.id === updatedCreditCard.id ? updatedCreditCard : creditCard
      )
    );
  }, []);

  const handleSelectTransaction = (transactionId) => {
    setSelectedTransactionId(transactionId);
    setOpenTransactionModal(true);
  };

  const handleLastTransactionsFiltersChange = useCallback((newFilters) => {
    setLastTransactionsFilters(newFilters);
  }, []);

  const handleTransactionUpdated = useCallback(() => {
    setLastTransactionsKey((prevKey) => prevKey + 1);
  }, []);

  const handleRegisterTransaction = ({ isMultiple, titular, creditCardId }) => {
    if (isMultiple) {
      setShowMultipleTransactionsModal(true);
    } else {
      setShowSingleTransactionModal(true);
    }
    setTransactionInitialData({
      titular: titular || selectedCreditCard?.titular_cpf || selectedCreditCard?.titular_cnpj,
      creditCardId: creditCardId || selectedCreditCardId,
    });
  };

  // Limpa o estado ao trocar de perfil
  useEffect(() => {
    if (selectedProfiles.length > 0) {
      setSelectedCreditCardId(null);
      setShowRegisterCreditCard(false);
      setIsInitialLoad(true);
    } else {
      setCreditCards([]);
      setSelectedCreditCardId(null);
    }
  }, [selectedProfiles]);

  useEffect(() => {
    if (isInitialLoad && creditCards.length > 0) {
      handleSelectCreditCard(creditCards[0]?.id);
    } else if (isInitialLoad && creditCards.length === 0) {
      setShowRegisterCreditCard(true);
    }
  }, [creditCards, isInitialLoad, handleSelectCreditCard]);

  // Verifica se o cartão selecionado ainda pertence ao perfil ativo
  useEffect(() => {
    if (
      selectedCreditCardId &&
      !creditCards.some((creditCard) => creditCard.id === selectedCreditCardId)
    ) {
      setSelectedCreditCardId(creditCards[0]?.id || null);
      if (!creditCards[0]) {
        setShowRegisterCreditCard(true);
      }
    }
  }, [selectedCreditCardId, creditCards]);

  const fetchUpdatedCreditCard = useCallback(
    async (creditCardId) => {
      try {
        const updatedCreditCard = await getCreditCardByID(creditCardId);
        setCreditCards((prevCreditCards) =>
          prevCreditCards.map((creditCard) =>
            creditCard.id === updatedCreditCard.id ? updatedCreditCard : creditCard
          )
        );
      } catch (error) {
        console.error('Erro ao atualizar dados do cartão de crédito:', error);
      }
    },
    [setCreditCards]
  );

  return (
    <div className="creditCardContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="creditCardContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Gestão de Cartões de Crédito
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {/* Lado Esquerdo */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                {/* ListCreditCards */}
                <ListCreditCards
                  onSelectCreditCard={handleSelectCreditCard}
                  onRegisterNewCreditCard={handleRegisterNewCreditCard}
                  selectedCreditCardId={selectedCreditCardId}
                  setCreditCards={setCreditCards}
                  creditCards={creditCards}
                  showRegisterButton={true}
                  showInactiveToggle={true}
                  showManageLink={false}
                />
                {/* CreditCardInfo ou RegisterCreditCard */}
                {selectedProfiles.length > 0 ? (
                  showRegisterCreditCard ? (
                    <RegisterCreditCard onCreditCardRegistered={handleCreditCardRegistered} />
                  ) : selectedCreditCardId ? (
                    <CreditCardInfo
                      creditCardId={selectedCreditCardId}
                      onUpdateCreditCardList={handleUpdateCreditCardList}
                    />
                  ) : (
                    <Typography>
                      Não há cartões para este perfil. Clique em 'Cadastrar novo cartão' para
                      começar.
                    </Typography>
                  )
                ) : (
                  <Typography>
                    Selecione um perfil para visualizar os cartões associados.
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* Lado Direito */}
            <Grid item xs={12} md={6}>
              {/* LastTransactions */}
              {selectedCreditCardId && (
                <LastTransactions
                  key={lastTransactionsKey}
                  transactions={transactions}
                  setTransactions={setTransactions}
                  showRegisterButton={true}
                  onRegisterTransaction={handleRegisterTransaction}
                  onSelectTransaction={handleSelectTransaction}
                  showAccountFilter={false} // Desabilitar filtro de conta
                  showCreditCardFilter={false} // Desabilitar filtro de cartão
                  selectedCreditCardId={selectedCreditCardId} // Passar o ID do cartão selecionado
                  selectedTitular={selectedCreditCard?.titular_cpf || selectedCreditCard?.titular_cnpj}
                  initialFilters={{
                    ...lastTransactionsFilters,
                    id_cartao: selectedCreditCardId,
                  }}
                  onFiltersChange={handleLastTransactionsFiltersChange}
                  passAccountOrCardInfoToModals={true} // Passar informações para os modais
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Modal para SingleTransactionForm */}
      <Modal open={showSingleTransactionModal} onClose={() => setShowSingleTransactionModal(false)}>
        <Box className="modalBox" sx={{ width: '80%', maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
          <SingleTransactionForm
            initialCreditCardId={transactionInitialData.creditCardId}
            initialTitular={transactionInitialData.titular}
            onTransactionRegistered={(newTransaction) => {
              setTransactions((prevTransactions) => [newTransaction, ...prevTransactions]);
              fetchUpdatedCreditCard(selectedCreditCardId);
              setShowSingleTransactionModal(false);
            }}
          />
        </Box>
      </Modal>

      {/* Modal para MultipleTransactionsForm */}
      <Modal open={showMultipleTransactionsModal} onClose={() => setShowMultipleTransactionsModal(false)}>
        <Box className="modalBox" sx={{ width: '80%', maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
          <MultipleTransactionsForm
            initialCreditCardId={transactionInitialData.creditCardId}
            initialTitular={transactionInitialData.titular}
            onTransactionRegistered={(newTransactions) => {
              setTransactions((prevTransactions) => [...newTransactions, ...prevTransactions]);
              fetchUpdatedCreditCard(selectedCreditCardId);
              setShowMultipleTransactionsModal(false);
            }}
          />
        </Box>
      </Modal>

      {/* Modal para TransactionInfo */}
      <Modal open={openTransactionModal} onClose={() => setOpenTransactionModal(false)}>
        <Box className="modalBox" sx={{ width: '80%', maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
          <TransactionInfo
            transactionId={selectedTransactionId}
            onClose={() => setOpenTransactionModal(false)}
            onUpdateTransactionList={(updatedTransaction) => {
              setTransactions((prevTransactions) =>
                prevTransactions.map((transaction) =>
                  transaction.id === updatedTransaction.id ? updatedTransaction : transaction
                )
              );
              fetchUpdatedCreditCard(selectedCreditCardId);
              handleTransactionUpdated();
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default withAuth(CreditCards);
