// src/modules/finance/categories/cards/financeCategoriesCard.js
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Button, Switch, FormControlLabel } from '@mui/material';
import { Add, Edit, Visibility, Public, ExpandMore, ChevronRight, ExpandLess } from '@mui/icons-material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './financialOperationsCard.css';
import { ProfileContext } from '../../../../context/profileContext';
import { getInitials } from '../../../../utils/helpers';

import { getFinancialOperationsByIdentifier, updateFinancialOperation } from '../../../../services/finance/financialOperationService';
import { getCompanyData } from '../../../../services/profile/companyProfileService';

import CategoryInfo from './financialOperationInfo';
import RegisterCategory from './registerFinancialOperation';

const ItemTypes = {
  OPERATION: 'operation',
};

const FinancialOperationsCard = () => {
  const { selectedProfiles } = useContext(ProfileContext);
  const [operationsByProfile, setOperationsByProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [showInactive, setShowInactive] = useState(false);
  const userCpf = localStorage.getItem('userCpf');
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedNodeIds, setExpandedNodeIds] = useState(() => {
    const savedExpandedNodes = localStorage.getItem('expandedNodeIds');
    return savedExpandedNodes ? JSON.parse(savedExpandedNodes) : {};
  });
  const [selectedProfileIdentifier, setSelectedProfileIdentifier] = useState(null);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    const fetchOperations = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const operationsData = {};
        for (const profile of selectedProfiles) {
          const identifier = profile.cpf || profile.cnpj;
          const fetchedOperations = await getFinancialOperationsByIdentifier(identifier);
          operationsData[identifier] = {
            profile,
            operations: fetchedOperations,
            treeData: buildTreeData(fetchedOperations, identifier),
          };
        }
        setOperationsByProfile(operationsData);
        setLoading(false);
      } catch (error) {
        setErrorMessage('Erro ao carregar operações.');
        setLoading(false);
      }
    };

    if (selectedProfiles.length > 0) {
      fetchOperations();
    } else {
      setOperationsByProfile({});
      setLoading(false);
    }
  }, [selectedProfiles]);

  const buildTreeData = (operationsList, identifier) => {
    const idToNodeMap = {};
    operationsList.forEach(operation => {
      if (operation.id) {
        const nodeId = `${identifier}-${operation.id}`;
        idToNodeMap[operation.id] = { 
          ...operation, 
          nodeId,
          children: [] 
        };
      }
    });

    const tree = [];
    operationsList.forEach(operation => {
      if (operation.id) {
        const parentId = operation.operacao_pai_id;
        if (parentId && idToNodeMap[parentId]) {
          idToNodeMap[parentId].children.push(idToNodeMap[operation.id]);
        } else {
          tree.push(idToNodeMap[operation.id]);
        }
      }
    });

    // Ordenar os filhos com base em ordem_exibicao
    const sortTree = (nodes) => {
      nodes.sort((a, b) => (a.ordem_exibicao || 0) - (b.ordem_exibicao || 0));
      nodes.forEach(node => {
        if (node.children && node.children.length > 0) {
          sortTree(node.children);
        }
      });
    };

    sortTree(tree);
    return tree;
  };

  const handleToggleInactive = () => {
    setShowInactive(!showInactive);
  };

  const getNodeColor = (tipo_operacao) => {
    switch (tipo_operacao) {
      case 'receitas':
        return 'receitaColor';
      case 'despesas':
        return 'despesaColor';
      case 'investimentos':
        return 'investimentoColor';
      default:
        return '';
    }
  };

  const handleAddSubCategory = (e, node, profileIdentifier) => {
    e.stopPropagation();
    setSelectedOperation(node);
    setOpenRegisterModal(true);
    setSelectedProfileIdentifier(profileIdentifier);
  };

  const handleEditCategory = (e, node) => {
    e.stopPropagation();
    setSelectedOperation(node);
    setOpenInfoModal(true);
  };

  const handleViewCategory = (e, node) => {
    e.stopPropagation();
    setSelectedOperation(node);
    setOpenInfoModal(true);
  };

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
    setSelectedOperation(null);
    refreshOperations();
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
    setSelectedOperation(null);
    refreshOperations();
  };

  const refreshOperations = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const updatedOperationsByProfile = {};
      for (const profile of selectedProfiles) {
        const identifier = profile.cpf || profile.cnpj;
        const fetchedOperations = await getFinancialOperationsByIdentifier(identifier);
        updatedOperationsByProfile[identifier] = {
          profile,
          operations: fetchedOperations,
          treeData: buildTreeData(fetchedOperations, identifier),
        };
      }
      setOperationsByProfile(updatedOperationsByProfile);
      setLoading(false);
    } catch (error) {
      setErrorMessage('Erro ao carregar operações.');
      setLoading(false);
    }
  };

  const filterOperations = (nodes) => {
    return nodes
      .filter(node => showInactive || node.status === 'ativo')
      .map(node => ({
        ...node,
        children: node.children ? filterOperations(node.children) : [],
      }));
  };

  const findOperation = (id, profileIdentifier) => {
    const profileData = operationsByProfile[profileIdentifier];
    if (!profileData) return null;

    const findInTree = (nodes) => {
      for (let node of nodes) {
        if (node.id === id) {
          return node;
        } else if (node.children && node.children.length > 0) {
          const result = findInTree(node.children);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    return findInTree(profileData.treeData);
  };

  const findOperationByNodeId = (nodeId, profileIdentifier) => {
    const profileData = operationsByProfile[profileIdentifier];
    if (!profileData) return null;

    const findInTree = (nodes) => {
      for (let node of nodes) {
        if (node.nodeId === nodeId) {
          return node;
        } else if (node.children && node.children.length > 0) {
          const result = findInTree(node.children);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    return findInTree(profileData.treeData);
  };

  const checkOwnership = useCallback(async (operation) => {
    if (operation.titular_cpf === userCpf) {
      return true;
    } else if (operation.titular_cnpj) {
      try {
        const company = await getCompanyData(operation.titular_cnpj);
        return company && company.cpf_responsavel === userCpf;
      } catch (error) {
        console.error('Erro ao verificar propriedade da empresa:', error);
        return false;
      }
    }
    return false;
  }, [userCpf]);

  const moveOperation = async (draggedNodeId, targetNodeId, position, profileIdentifier) => {
    if (moveOperation.isMoving) return;
    moveOperation.isMoving = true;

    try {
      // Obter o id numérico das operações
      const draggedId = parseInt(draggedNodeId.split('-').pop());
      const targetId = parseInt(targetNodeId.split('-').pop());

      const profileData = operationsByProfile[profileIdentifier];
      if (!profileData) {
        throw new Error('Perfil não encontrado');
      }

      // Find the operation being moved
      const draggedOperation = findOperation(draggedId, profileIdentifier);
      if (!draggedOperation) {
        throw new Error('Operação arrastada não encontrada');
      }

      // Verificar propriedade usando a função checkOwnership
      const isOwner = await checkOwnership(draggedOperation);
      if (!isOwner || draggedOperation.operacao_global) {
        throw new Error('Você não tem permissão para mover esta operação.');
      }

      // Find the target operation
      const targetOperation = findOperation(targetId, profileIdentifier);
      if (!targetOperation) {
        throw new Error('Operação alvo não encontrada');
      }

      let newParentId = draggedOperation.operacao_pai_id;
      let siblings = [];

      if (position === 'inside') {
        newParentId = targetOperation.id;
        siblings = targetOperation.children ? [...targetOperation.children] : [];
      } else {
        newParentId = targetOperation.operacao_pai_id;
        const parent = newParentId ? findOperation(newParentId, profileIdentifier) : { children: profileData.treeData };
        siblings = parent.children ? [...parent.children] : [];
      }

      // Ajuste para impedir que operações editáveis sejam colocadas entre ou acima de operações globais
      if (position === 'above' || position === 'below') {
        const targetIndex = siblings.findIndex(n => n.id === targetId);

        if (position === 'above' && siblings.slice(0, targetIndex).some(n => n.operacao_global)) {
          throw new Error('Não é permitido mover operações acima de operações globais.');
        } else if (position === 'below' && siblings.slice(targetIndex + 1).some(n => n.operacao_global)) {
          throw new Error('Não é permitido mover operações entre operações globais.');
        }
      }

      // Remove dragged operation from current siblings
      siblings = siblings.filter(n => n.id !== draggedId);

      // Insert dragged operation at the correct position
      const targetIndex = siblings.findIndex(n => n.id === targetId);
      if (position === 'above') {
        siblings.splice(targetIndex, 0, draggedOperation);
      } else if (position === 'below') {
        siblings.splice(targetIndex + 1, 0, draggedOperation);
      } else if (position === 'inside') {
        siblings.push(draggedOperation);
      }

      // Recalculate ordem_exibicao for siblings
      let order = 1;
      const updates = [];
      for (let sibling of siblings) {
        const updatedFields = {};
        if (sibling.id === draggedOperation.id) {
          updatedFields.operacao_pai_id = newParentId;
        }
        updatedFields.ordem_exibicao = order++;

        if (!sibling.operacao_global && (await checkOwnership(sibling))) {
          updates.push(updateFinancialOperation(sibling.id, updatedFields));
        }
      }

      await Promise.all(updates);
      setErrorMessage('');

      console.log(`Operação movida: ${draggedOperation.nome} (ID: ${draggedId})`);
      console.log(`Nova operação pai: ${newParentId}`);
      console.log(`Nova ordem de exibição: ${draggedOperation.ordem_exibicao}`);
      console.log(`Posição relativa: ${position}`);
      console.log(`Operação alvo: ${targetOperation.nome} (ID: ${targetId})`);
      console.log('Nova ordem dos irmãos:', siblings.map(s => `${s.nome} (ID: ${s.id}, Ordem: ${s.ordem_exibicao})`));

    } catch (error) {
      console.error('Erro ao mover operação:', error);
      setErrorMessage('Erro ao mover operação: ' + error.message);
    } finally {
      await refreshOperations();
      moveOperation.isMoving = false;
    }
  };

  const handleToggleNode = (nodeId) => {
    setExpandedNodeIds((prev) => {
      const newExpanded = { ...prev, [nodeId]: !prev[nodeId] };
      localStorage.setItem('expandedNodeIds', JSON.stringify(newExpanded));
      return newExpanded;
    });
  };

  const handleToggleAllNodes = () => {
    setExpandAll(!expandAll);
    const allNodeIds = Object.values(operationsByProfile).flatMap(({ treeData }) => 
      getAllNodeIds(treeData)
    );
    const newExpandedNodeIds = {};
    allNodeIds.forEach(nodeId => {
      newExpandedNodeIds[nodeId] = !expandAll;
    });
    setExpandedNodeIds(newExpandedNodeIds);
    localStorage.setItem('expandedNodeIds', JSON.stringify(newExpandedNodeIds));
  };

  const getAllNodeIds = (nodes) => {
    return nodes.flatMap(node => [
      node.nodeId,
      ...(node.children ? getAllNodeIds(node.children) : [])
    ]);
  };

  return (
    <div className="financialOperationsCard">
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="outlined"
          onClick={handleToggleAllNodes}
          startIcon={expandAll ? <ExpandLess /> : <ExpandMore />}
        >
          {expandAll ? "Fechar Tudo" : "Abrir Tudo"}
        </Button>
        <FormControlLabel
          control={<Switch checked={showInactive} onChange={handleToggleInactive} />}
          label={<Typography variant="body2">Exibir Operações Inativas</Typography>}
          labelPlacement="start"
          style={{ marginRight: 0 }}
        />
      </Box>
      {loading ? (
        <Typography variant="body2">Carregando operações...</Typography>
      ) : errorMessage ? (
        <Typography variant="body2" color="error">{errorMessage}</Typography>
      ) : (
        Object.values(operationsByProfile).map(({ profile, treeData }) => (
          <div key={profile.cpf || profile.cnpj} className="profileCategoryTree">
            <div className="profileHeader">
            <div className="profileAvatarOperations">
  {profile.image ? (
    <img 
      src={`${profile.image}`} 
      alt={profile.name} 
      onError={(e) => {
        e.target.style.display = 'none';
        e.target.parentNode.textContent = getInitials(profile.name);
      }}
    />
  ) : (
    <span>{getInitials(profile.name)}</span>
  )}
</div>
              <Typography variant="h6">{profile.name}</Typography>
            </div>
            <DndProvider backend={HTML5Backend}>
              <div className="customTreeView">
                {filterOperations(treeData).map((node) => (
                  <DraggableTreeItem
                    key={node.nodeId}
                    node={node}
                    userCpf={userCpf}
                    getNodeColor={getNodeColor}
                    handleAddSubCategory={(e, node) => handleAddSubCategory(e, node, profile.cpf || profile.cnpj)}
                    handleEditCategory={handleEditCategory}
                    handleViewCategory={handleViewCategory}
                    moveOperation={(draggedId, targetId, position) => 
                      moveOperation(draggedId, targetId, position, profile.cpf || profile.cnpj)
                    }
                    findOperation={(id) => findOperation(id, profile.cpf || profile.cnpj)}
                    findOperationByNodeId={(nodeId) => findOperationByNodeId(nodeId, profile.cpf || profile.cnpj)}
                    expandedNodeIds={expandedNodeIds}
                    handleToggleNode={handleToggleNode}
                    checkOwnership={checkOwnership}
                    treeData={treeData}
                    level={0} // Inicia o nível em 0
                  />
                ))}
              </div>
            </DndProvider>
            <Box mt={2}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  setSelectedOperation(null);
                  setOpenRegisterModal(true);
                  setSelectedProfileIdentifier(profile.cpf || profile.cnpj);
                }}
              >
                Adicionar Operação Principal
              </Button>
            </Box>
          </div>
        ))
      )}
      {openRegisterModal && (
        <RegisterCategory
          open={openRegisterModal}
          onClose={handleCloseRegisterModal}
          parentCategory={selectedOperation}
          allCategories={selectedProfiles.length > 0 ? operationsByProfile[selectedProfileIdentifier].operations : []}
          profileIdentifier={selectedProfileIdentifier}
        />
      )}
      {openInfoModal && (
        <CategoryInfo
          open={openInfoModal}
          onClose={handleCloseInfoModal}
          category={selectedOperation}
        />
      )}
    </div>
  );
};

const DraggableTreeItem = ({
  node,
  userCpf,
  getNodeColor,
  handleAddSubCategory,
  handleEditCategory,
  handleViewCategory,
  moveOperation,
  findOperation,
  findOperationByNodeId,
  expandedNodeIds,
  handleToggleNode,
  checkOwnership,
  treeData,
  level = 0, // Valor padrão para level
}) => {
  const [isOwner, setIsOwner] = useState(false);
  const [canDrag, setCanDrag] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const verifyOwnership = async () => {
      const ownershipStatus = await checkOwnership(node);
      setIsOwner(ownershipStatus);
      setCanDrag(ownershipStatus && !node.operacao_global);
    };
    verifyOwnership();
  }, [node, checkOwnership]);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.OPERATION,
    item: () => ({ id: node.nodeId }),
    canDrag: () => canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [dropPosition, setDropPosition] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: ItemTypes.OPERATION,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const draggedNode = findOperationByNodeId(item.id);
      if (!draggedNode) return;

      // Determine mouse position
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Decide whether to show insert above, inside, or below
      let position = 'inside';
      if (hoverClientY < hoverHeight * 0.25) {
        position = 'above';
      } else if (hoverClientY > hoverHeight * 0.75) {
        position = 'below';
      }

      // Verificar se é possível soltar nesta posição
      const canDropHere = canDropAtPosition(draggedNode, node, position);

      // Atualizar dropPosition apenas se for possível soltar
      setDropPosition(canDropHere ? position : null);

      // Auto-expand node after hovering for 500ms
      if (position === 'inside' && !expandedNodeIds[node.nodeId] && canDropHere) {
        if (!hoverTimeout) {
          const timeout = setTimeout(() => {
            handleToggleNode(node.nodeId);
          }, 500);
          setHoverTimeout(timeout);
        }
      } else {
        if (hoverTimeout) {
          clearTimeout(hoverTimeout);
          setHoverTimeout(null);
        }
      }
    },
    drop(item, monitor) {
      if (monitor.didDrop()) {
        return;
      }

      const draggedId = item.id;
      const hoverId = node.nodeId;
      const position = dropPosition;

      if (draggedId === hoverId || !position) {
        return;
      }

      console.log(`Iniciando drop da operação ${draggedId} ${position} ${hoverId}`);
      moveOperation(draggedId, hoverId, position);
      setDropPosition(null);

      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
        setHoverTimeout(null);
      }

      return { moved: true };
    },
    canDrop: (item) => {
      const draggedNode = findOperationByNodeId(item.id);
      return canDropAtPosition(draggedNode, node, dropPosition);
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  // Função auxiliar para verificar se é possível soltar em uma posição específica
  const canDropAtPosition = (draggedNode, targetNode, position) => {
    if (!draggedNode || !targetNode) return false;
    if (isDescendant(draggedNode, targetNode)) return false;

    // Permitir soltar dentro de qualquer operação, incluindo globais
    if (position === 'inside') {
      return true;
    }

    // Para posições 'above' e 'below', verificar se não está tentando inserir entre operações globais
    if (position === 'above' || position === 'below') {
      const parent = findOperation(targetNode.operacao_pai_id);
      const siblings = parent ? parent.children : treeData;
      const targetIndex = siblings.findIndex(n => n.id === targetNode.id);

      if (position === 'above') {
        // Verificar se há uma operação global imediatamente acima no mesmo nível
        const nodeAbove = siblings[targetIndex - 1];
        if (nodeAbove && nodeAbove.operacao_global) return false;
      } else if (position === 'below') {
        // Verificar se há uma operação global imediatamente abaixo no mesmo nível
        const nodeBelow = siblings[targetIndex + 1];
        if (nodeBelow && nodeBelow.operacao_global) return false;
      }
    }

    return true;
  };

  // Função auxiliar para verificar se um nó é descendente de outro
  const isDescendant = (draggedNode, targetNode) => {
    if (!draggedNode || !targetNode) return false;
    let parent = targetNode;
    while (parent) {
      if (parent.id === draggedNode.id) return true;
      parent = findOperation(parent.operacao_pai_id);
    }
    return false;
  };

  drag(drop(ref));

  const opacity = isDragging ? 0.5 : 1;

  // Definir handleToggle
  const handleToggle = () => {
    handleToggleNode(node.nodeId);
  };

  // Definir isExpanded
  const isExpanded = expandedNodeIds[node.nodeId] || false;

  // Visual feedback styles
  let dropIndicatorStyle = {};

  if (isOverCurrent && canDrop && dropPosition) {
    if (dropPosition === 'above') {
      dropIndicatorStyle = { borderTop: '2px solid #fca22d' };
    } else if (dropPosition === 'below') {
      dropIndicatorStyle = { borderBottom: '2px solid #fca22d' };
    } else if (dropPosition === 'inside') {
      dropIndicatorStyle = { backgroundColor: '#5a5a5a' }; // Cor mais clara para o drop inside
    }
  }

  return (
    <div ref={ref} style={{ opacity, ...dropIndicatorStyle, marginBottom: '2px' }}> {/* Aumenta a distância entre as linhas */}
      <div className="treeItem" style={{ marginLeft: level * 15 }}>
        <div className="treeItemContent">
          <span onClick={handleToggle} className={`expandIcon ${isExpanded ? 'expanded' : ''}`}>
            {node.children && node.children.length > 0 ? (
              isExpanded ? <ExpandMore /> : <ChevronRight />
            ) : null}
          </span>
          <span className={`operationName ${getNodeColor(node.tipo_operacao)}`}>{node.nome}</span>
        </div>
        <div className="operationIcons">
          {node.operacao_global && (
            <Public titleAccess="Operação Global" className="icon" />
          )}
          {isOwner ? (
            <Edit
              onClick={(e) => handleEditCategory(e, node)}
              className="icon"
              titleAccess="Editar Operação"
            />
          ) : (
            <Visibility
              onClick={(e) => handleViewCategory(e, node)}
              className="icon"
              titleAccess="Visualizar Operação"
            />
          )}
          <Add
            onClick={(e) => handleAddSubCategory(e, node)}
            className="icon"
            titleAccess="Adicionar Suboperação"
          />
        </div>
      </div>
      {isExpanded && node.children && node.children.length > 0 && (
        <div className="treeItemChildren">
          {node.children.map(childNode => (
            <DraggableTreeItem
              key={childNode.nodeId}
              node={childNode}
              userCpf={userCpf}
              getNodeColor={getNodeColor}
              handleAddSubCategory={handleAddSubCategory}
              handleEditCategory={handleEditCategory}
              handleViewCategory={handleViewCategory}
              moveOperation={moveOperation}
              findOperation={findOperation}
              findOperationByNodeId={findOperationByNodeId}
              expandedNodeIds={expandedNodeIds}
              handleToggleNode={handleToggleNode}
              checkOwnership={checkOwnership}
              treeData={treeData}
              level={level + 1} // Incrementa o nível para a próxima camada
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FinancialOperationsCard;