import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, MenuItem, Box } from '@mui/material';
import { tiposDeConta, moedas } from '../../../../config/constants';
import { BankSelector, TitularSelector } from '../../../../utils/formUtils';

import { createAccount } from '../../../../services/finance/accountsService';

const RegisterAccountCard = ({ onAccountRegistered }) => {
  const [accountData, setAccountData] = useState({
    agencia: '',
    numero_conta: '',
    digito_conta: '',
    tipo_conta: '',
    moeda: 'BRL',
    descricao: '',
    id_banco: '',
    titular: ''
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData({ ...accountData, [name]: value });
  };

  const handleRegister = async () => {
    try {
      const newAccount = await createAccount(accountData);
      onAccountRegistered(newAccount); // Passa a nova conta criada
      setErrorMessage('');
    } catch (error) {
      console.error("Erro ao registrar nova conta:", error);
      setErrorMessage(`Erro ao registrar nova conta: ${error.message}`);
    }
  };

  return (
    <div className="accountInfoCard">
      <Typography variant="h5">Cadastrar Nova Conta</Typography>

      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      <Box my={2}></Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TitularSelector
            value={accountData.titular}
            onChange={(identifier) => {
              setAccountData({
                ...accountData,
                titular: identifier
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BankSelector
            value={accountData.id_banco}
            onChange={(e) => handleChange({ target: { name: 'id_banco', value: e.target.value } })}
          />
        </Grid>
        <Grid item xs={4}>
            <TextField
              fullWidth
              label="Agência"
              variant="outlined"
              value={accountData.agencia}
              name="agencia"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Conta"
              variant="outlined"
              value={accountData.numero_conta}
              name="numero_conta"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Dígito"
              variant="outlined"
              value={accountData.digito_conta}
              name="digito_conta"
              onChange={handleChange}
            />
          </Grid>
        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            label="Tipo de Conta"
            variant="outlined"
            name="tipo_conta"
            value={accountData.tipo_conta}
            onChange={handleChange}
          >
            {tiposDeConta.map((tipo) => (
              <MenuItem key={tipo.valor} value={tipo.valor}>
                {tipo.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            label="Moeda"
            variant="outlined"
            name="moeda"
            value={accountData.moeda}
            onChange={handleChange}
          >
            {moedas.map((moeda) => (
              <MenuItem key={moeda.sigla} value={moeda.sigla}>
                {moeda.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descrição"
            variant="outlined"
            multiline
            rows={2}
            value={accountData.descricao}
            name="descricao"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box my={2}></Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRegister}
      >
        Cadastrar Conta
      </Button>
    </div>
  );
};

export default RegisterAccountCard;
