import React, { useState } from 'react';
import { TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import { estados } from '../../../../config/constants';
import { fetchAddressByCep, getCompanyDataByCnpj, formatCepForServer, formatCnpjForServer } from '../../../../utils/helpers';
import { createCompany } from '../../../../services/profile/companyProfileService';

const RegisterCompanyCard = ({ onCompanyRegistered }) => {
  const [companyData, setCompanyData] = useState({
    razao_social: '',
    cnpj: '',
    inscricao_estadual: '',
    inscricao_municipal: '',
    cep: '',
    cidade: '',
    estado: '',
    logradouro: '',
    bairro: '',
    numero: '',
    complemento: '',
    telefone: '',
    cargo_responsavel: '',
    setor_atividade: '',
    tipo_empresa: '',
    numero_funcionarios: '',
    cpf_responsavel: ''
  });
  const [cepError, setCepError] = useState('');
  const [cnpjError, setCnpjError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');  // Estado para mensagem de erro
  const [successMessage, setSuccessMessage] = useState('');  // Estado para mensagem de sucesso
  const userCpf = localStorage.getItem('userCpf');

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });

    if (name === 'cep') {
      const cleanCep = formatCepForServer(value);
      if (cleanCep) {
        try {
          const addressData = await fetchAddressByCep(cleanCep);
          setCompanyData((prevData) => ({
            ...prevData,
            logradouro: addressData.logradouro,
            bairro: addressData.bairro,
            cidade: addressData.localidade,
            estado: addressData.uf,
            numero: '',          // Limpa o campo número
            complemento: ''      // Limpa o campo complemento
          }));
          setCepError('');
        } catch (error) {
          setCepError('Erro ao buscar endereço');
        }
      } else {
        setCepError('');
      }
    }

    if (name === 'cnpj') {
      const cleanCnpj = formatCnpjForServer(value);
      if (cleanCnpj) {
        try {
          const companyInfo = await getCompanyDataByCnpj(cleanCnpj);
          if (companyInfo.error) {
            setCnpjError(companyInfo.error);
          } else if (Object.keys(companyInfo).length === 0) {
            setCnpjError('CNPJ não encontrado nas bases da API.');
          } else {
            setCompanyData((prevData) => ({
              ...prevData,
              ...companyInfo
            }));
            setCnpjError('');
          }
        } catch (error) {
          setCnpjError('Erro ao buscar dados da empresa');
        }
      } else {
        setCnpjError('');
      }
    }
  };

  const handleRegister = async () => {
    try {
      await createCompany(companyData, userCpf);
      setSuccessMessage('Nova empresa cadastrada com sucesso!');
      setErrorMessage('');  // Limpa a mensagem de erro após o sucesso
      onCompanyRegistered(companyData.cnpj);
    } catch (error) {
      console.error("Erro ao registrar nova empresa:", error);
      setErrorMessage(`Erro ao registrar nova empresa: ${error.message}`);
      setSuccessMessage('');  // Limpa a mensagem de sucesso se ocorrer um erro
    }
  };

  return (
    <div className="companyCard">
      <Typography variant="h5">Cadastrar Nova Empresa</Typography>
      <Typography variant="body2" color="primary">
        Você deve ser o responsável pela empresa para fazer o cadastro.
      </Typography>

      {/* Espaço para exibir a mensagem de erro ou sucesso */}
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}

      <div className="profileInfo">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="CNPJ"
              variant="outlined"
              value={companyData.cnpj}
              name="cnpj"
              onChange={handleChange}
              error={!!cnpjError}
              helperText={cnpjError || "Informe o CNPJ para preencher automaticamente os dados da empresa."}
            />
            <Box flexGrow={1} /> {/* Espaço flexível */}
          </Stack>
          <TextField
            fullWidth
            label="Razão Social"
            variant="outlined"
            value={companyData.razao_social}
            name="razao_social"
            onChange={handleChange}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Inscrição Estadual"
              variant="outlined"
              value={companyData.inscricao_estadual}
              name="inscricao_estadual"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Inscrição Municipal"
              variant="outlined"
              value={companyData.inscricao_municipal}
              name="inscricao_municipal"
              onChange={handleChange}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="CEP"
              variant="outlined"
              value={companyData.cep}
              name="cep"
              onChange={handleChange}
              error={!!cepError}
              helperText={cepError || "Ao digitar o CEP, preencheremos automaticamente os campos equivalentes."}
            />
            <Box flexGrow={1} /> {/* Espaço flexível */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Cidade"
              variant="outlined"
              value={companyData.cidade}
              name="cidade"
              onChange={handleChange}
            />
            <TextField
              select
              fullWidth
              label="Estado"
              variant="outlined"
              name="estado"
              value={companyData.estado}
              onChange={handleChange}
            >
              {estados.map((estado) => (
                <MenuItem key={estado.sigla} value={estado.sigla}>
                  {estado.nome}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <TextField
            fullWidth
            label="Logradouro"
            variant="outlined"
            value={companyData.logradouro}
            name="logradouro"
            onChange={handleChange}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Número"
              variant="outlined"
              value={companyData.numero}
              name="numero"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Complemento"
              variant="outlined"
              value={companyData.complemento}
              name="complemento"
              onChange={handleChange}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Bairro"
              variant="outlined"
              value={companyData.bairro}
              name="bairro"
              onChange={handleChange}
            />
            <TextField
              fullWidth
               label="Setor de Atividade"
              variant="outlined"
              value={companyData.setor_atividade}
              name="setor_atividade"
              onChange={handleChange}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Tipo de Empresa"
              variant="outlined"
              value={companyData.tipo_empresa}
              name="tipo_empresa"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Número de Funcionários"
              variant="outlined"
              value={companyData.numero_funcionarios}
              name="numero_funcionarios"
              onChange={handleChange}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Telefone"
              variant="outlined"
              value={companyData.telefone}
              name="telefone"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Cargo do Responsável"
              variant="outlined"
              value={companyData.cargo_responsavel}
              name="cargo_responsavel"
              onChange={handleChange}
            />
          </Stack>
        </Stack>
        <Box my={2}></Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleRegister}
        >
          Cadastrar Empresa
        </Button>
      </div>
    </div>
  );
};

export default RegisterCompanyCard;
