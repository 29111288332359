import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, Button, Typography } from '@mui/material';

import { getInitials } from '../../../../utils/helpers';

import { getProfileDataById } from '../../../../services/profile/userProfileService';


const ViewTeamMemberModal = ({ open, handleClose, member }) => {
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (member) {
        const data = await getProfileDataById(member.id);
        setProfileData(data);
      }
    };
    fetchProfile();
  }, [member]);

  if (!profileData) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Detalhes do Membro da Equipe</DialogTitle>
      <DialogContent>
        <div className="profileCard">
          <div className="profileHeader">
            <div className="profileAvatarContainer">
              {profileData.profile_image ? (
                <img src={profileData.profile_image} alt="User" className="profileAvatar" />
              ) : (
                <div className="profileAvatar">
                  {getInitials(profileData.nome, profileData.sobrenome)}
                </div>
              )}
            </div>
            <div className="profileName">
              <Typography variant="h5" display="inline">{profileData.nome} {profileData.sobrenome}</Typography>
            </div>
          </div>
          <div className="profileInfo">
            <Stack spacing={2}>
              {profileData.cpf && (
                <TextField
                  fullWidth
                  label="CPF"
                  variant="outlined"
                  value={profileData.cpf}
                  disabled
                  className="textFieldReadOnly"
                />
              )}

              {profileData.data_nascimento && (
                <TextField
                  fullWidth
                  label="Data de Nascimento"
                  variant="outlined"
                  value={profileData.data_nascimento}
                  disabled
                  className="textFieldReadOnly"
                />
              )}

              {profileData.cep && (
                <TextField
                  fullWidth
                  label="CEP"
                  variant="outlined"
                  value={profileData.cep}
                  disabled
                  className="textFieldReadOnly"
                />
              )}

              <Stack direction="row" spacing={2}>
                {profileData.cidade && (
                  <TextField
                    fullWidth
                    label="Cidade"
                    variant="outlined"
                    value={profileData.cidade}
                    disabled
                    className="textFieldReadOnly"
                  />
                )}

                {profileData.estado && (
                  <TextField
                    fullWidth
                    label="Estado"
                    variant="outlined"
                    value={profileData.estado}
                    disabled
                    className="textFieldReadOnly"
                  />
                )}
              </Stack>

              {profileData.logradouro && (
                <TextField
                  fullWidth
                  label="Logradouro"
                  variant="outlined"
                  value={profileData.logradouro}
                  disabled
                  className="textFieldReadOnly"
                />
              )}

              <Stack direction="row" spacing={2}>
                {profileData.numero && (
                  <TextField
                    fullWidth
                    label="Número"
                    variant="outlined"
                    value={profileData.numero}
                    disabled
                    className="textFieldReadOnly"
                  />
                )}

                {profileData.complemento && (
                  <TextField
                    fullWidth
                    label="Complemento"
                    variant="outlined"
                    value={profileData.complemento}
                    disabled
                    className="textFieldReadOnly"
                  />
                )}
              </Stack>

              {profileData.telefone && (
                <TextField
                  fullWidth
                  label="Telefone"
                  variant="outlined"
                  value={profileData.telefone}
                  disabled
                  className="textFieldReadOnly"
                />
              )}
            </Stack>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTeamMemberModal;
