import React, { createContext, useState, useEffect } from 'react';
import { isProfileSelected } from '../utils/profileUtils'; // Importa a função utilitária

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const userCpf = localStorage.getItem('userCpf'); // Identifica o usuário atual
  const localStorageKey = `selectedProfiles_${userCpf}`; // Chave para o localStorage
  const [selectedProfiles, setSelectedProfiles] = useState(() => {
    // Carrega perfis do localStorage ao inicializar o contexto
    const storedProfiles = localStorage.getItem(localStorageKey);
    return storedProfiles ? JSON.parse(storedProfiles) : [];
  });

  useEffect(() => {
    // Atualiza o localStorage sempre que o selectedProfiles for alterado
    localStorage.setItem(localStorageKey, JSON.stringify(selectedProfiles));
  }, [selectedProfiles, localStorageKey]);

  const addProfile = (profile) => {
    if (isProfileSelected(selectedProfiles, profile.cpf || profile.cnpj)) {
      return;
    }
    const updatedProfiles = [...selectedProfiles, profile];
    setSelectedProfiles(updatedProfiles);
  };

  const removeProfile = (identifier) => {
    const updatedProfiles = selectedProfiles.filter(
      profile => profile.cpf !== identifier && profile.cnpj !== identifier
    );
    setSelectedProfiles(updatedProfiles);
  };

  return (
    <ProfileContext.Provider value={{ selectedProfiles, addProfile, removeProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
