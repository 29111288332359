// src/modules/finance/bankAccounts/bankAccounts.js

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Modal, Stack } from '@mui/material';

import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import './bankAccounts.css';
import { ProfileContext } from '../../../context/profileContext';
import withAuth from '../../../hooks/withAuth';

import { getAccountByID } from '../../../services/finance/accountsService';

import ListAccounts from './cards/listAccounts';
import AccountInfo from './cards/accountInfo';
import RegisterAccount from './cards/registerAccount';
import LastTransactions from '../transactions/cards/lastTransactions';
import SingleTransactionForm from '../transactions/cards/singleTransactionForm';
import MultipleTransactionsForm from '../transactions/cards/multipleTransactionsForm';
import TransactionInfo from '../transactions/cards/transactionInfo';

const BankAccounts = () => {
  const { selectedProfiles } = useContext(ProfileContext);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [showRegisterAccount, setShowRegisterAccount] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [lastTransactionsKey, setLastTransactionsKey] = useState(0);
  const [lastTransactionsFilters, setLastTransactionsFilters] = useState({
    data_inicio: '',
    data_fim: '',
    tipo_data: 'data_hora',
    id_conta_bancaria: '',
    dateRange: '30',
  });
  const [showSingleTransactionModal, setShowSingleTransactionModal] = useState(false);
  const [showMultipleTransactionsModal, setShowMultipleTransactionsModal] = useState(false);
  const [transactionInitialData, setTransactionInitialData] = useState({
    titular: '',
    accountId: '',
  });

  const handleSelectAccount = useCallback(async (accountId) => {
    setSelectedAccountId(accountId);
    setShowRegisterAccount(false);
    setIsInitialLoad(false);
    
    try {
      const account = await getAccountByID(accountId);
      setSelectedAccount(account);
    } catch (error) {
      console.error('Erro ao buscar detalhes da conta:', error);
    }
  }, []);

  const handleRegisterNewAccount = useCallback(() => {
    setSelectedAccountId(null);
    setShowRegisterAccount(true);
    setIsInitialLoad(false);
  }, []);

  const handleAccountRegistered = useCallback((newAccount) => {
    setAccounts((prevAccounts) => [...prevAccounts, newAccount]);
    setSelectedAccountId(newAccount.id);
    setShowRegisterAccount(false);

    setTimeout(() => {
      setIsInitialLoad(true);
    }, 100);
  }, []);

  const handleUpdateAccountList = useCallback((updatedAccount) => {
    setAccounts((prevAccounts) =>
      prevAccounts.map((account) =>
        account.id === updatedAccount.id ? updatedAccount : account
      )
    );
  }, []);

  const handleSelectTransaction = (transactionId) => {
    setSelectedTransactionId(transactionId);
    setOpenTransactionModal(true);
  };

  const fetchUpdatedAccount = useCallback(
    async (accountId) => {
      try {
        const updatedAccount = await getAccountByID(accountId);
        setAccounts((prevAccounts) =>
          prevAccounts.map((account) =>
            account.id === updatedAccount.id ? updatedAccount : account
          )
        );
      } catch (error) {
        console.error('Erro ao atualizar dados da conta:', error);
      }
    },
    [setAccounts]
  );

  const handleLastTransactionsFiltersChange = useCallback((newFilters) => {
    setLastTransactionsFilters(newFilters);
  }, []);

  const handleTransactionUpdated = useCallback(() => {
    setLastTransactionsKey((prevKey) => prevKey + 1);
  }, []);

  const handleRegisterTransaction = ({ isMultiple, titular, accountId }) => {
    if (isMultiple) {
      setShowMultipleTransactionsModal(true);
    } else {
      setShowSingleTransactionModal(true);
    }
    setTransactionInitialData({
      titular: titular || selectedAccount?.titular_cpf || selectedAccount?.titular_cnpj,
      accountId: accountId || selectedAccountId,
    });
  };

  // Reset state when switching profiles
  useEffect(() => {
    if (selectedProfiles.length > 0) {
      setSelectedAccountId(null);
      setShowRegisterAccount(false);
      setIsInitialLoad(true);
    } else {
      setAccounts([]);
      setSelectedAccountId(null);
    }
  }, [selectedProfiles]);

  useEffect(() => {
    if (isInitialLoad && accounts.length > 0) {
      handleSelectAccount(accounts[0]?.id);
    } else if (isInitialLoad && accounts.length === 0) {
      setShowRegisterAccount(true);
    }
  }, [accounts, isInitialLoad, handleSelectAccount]);

  // Ensure selected account still belongs to active profile
  useEffect(() => {
    if (selectedAccountId && !accounts.some((account) => account.id === selectedAccountId)) {
      setSelectedAccountId(accounts[0]?.id || null);
      if (!accounts[0]) {
        setShowRegisterAccount(true);
      }
    }
  }, [selectedAccountId, accounts]);

  return (
    <div className="bankAccountsContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="bankAccountsContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Gestão de Contas Bancárias
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {/* Left Side */}
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                {/* ListAccounts */}
                <ListAccounts
                  onSelectAccount={handleSelectAccount}
                  onRegisterNewAccount={handleRegisterNewAccount}
                  selectedAccountId={selectedAccountId}
                  setAccounts={setAccounts}
                  accounts={accounts}
                  showRegisterButton={true}
                  showInactiveToggle={true}
                  showManageLink={false}
                />
                {/* AccountInfo or RegisterAccount */}
                {selectedProfiles.length > 0 ? (
                  showRegisterAccount ? (
                    <RegisterAccount onAccountRegistered={handleAccountRegistered} />
                  ) : selectedAccountId ? (
                    <AccountInfo
                      accountId={selectedAccountId}
                      onUpdateAccountList={handleUpdateAccountList}
                    />
                  ) : (
                    <Typography>
                      Não há contas para este perfil. Clique em 'Cadastrar nova conta' para começar.
                    </Typography>
                  )
                ) : (
                  <Typography>
                    Selecione um perfil para visualizar as contas associadas.
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* Right Side */}
            <Grid item xs={12} md={6}>
              {/* LastTransactions */}
              {selectedAccountId && (
                <LastTransactions
                  key={lastTransactionsKey}
                  transactions={transactions}
                  setTransactions={setTransactions}
                  showRegisterButton={true}
                  onRegisterTransaction={handleRegisterTransaction}
                  onSelectTransaction={handleSelectTransaction}
                  showAccountFilter={false}
                  showCreditCardFilter={false}
                  selectedAccountId={selectedAccountId}
                  selectedTitular={selectedAccount?.titular_cpf || selectedAccount?.titular_cnpj}
                  initialFilters={{
                    ...lastTransactionsFilters,
                    id_conta_bancaria: selectedAccountId,
                  }}
                  onFiltersChange={handleLastTransactionsFiltersChange}
                  passAccountOrCardInfoToModals={true} // Passar informações para os modais
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Modal for TransactionInfo */}
      <Modal open={openTransactionModal} onClose={() => setOpenTransactionModal(false)}>
        <Box className="modalBox" sx={{ width: '80%', maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
          <TransactionInfo
            transactionId={selectedTransactionId}
            onClose={() => setOpenTransactionModal(false)}
            onUpdateTransactionList={(updatedTransaction) => {
              setTransactions((prevTransactions) =>
                prevTransactions.map((transaction) =>
                  transaction.id === updatedTransaction.id ? updatedTransaction : transaction
                )
              );
              fetchUpdatedAccount(selectedAccountId);
              handleTransactionUpdated();
            }}
          />
        </Box>
      </Modal>

      {/* Modal for SingleTransactionForm */}
      <Modal open={showSingleTransactionModal} onClose={() => setShowSingleTransactionModal(false)}>
        <Box className="modalBox" sx={{ width: '80%', maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
          <SingleTransactionForm
            initialAccountId={transactionInitialData.accountId}
            initialTitular={transactionInitialData.titular}
            onTransactionRegistered={(newTransaction) => {
              setTransactions((prevTransactions) => [newTransaction, ...prevTransactions]);
              fetchUpdatedAccount(selectedAccountId);
              setShowSingleTransactionModal(false);
            }}
          />
        </Box>
      </Modal>

      {/* Modal for MultipleTransactionsForm */}
      <Modal open={showMultipleTransactionsModal} onClose={() => setShowMultipleTransactionsModal(false)}>
        <Box className="modalBox" sx={{ width: '80%', maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
          <MultipleTransactionsForm
            initialAccountId={transactionInitialData.accountId}
            initialTitular={transactionInitialData.titular}
            onTransactionRegistered={(newTransactions) => {
              setTransactions((prevTransactions) => [...newTransactions, ...prevTransactions]);
              fetchUpdatedAccount(selectedAccountId);
              setShowMultipleTransactionsModal(false);
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default withAuth(BankAccounts);
