import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';

import './userProfiles.css';
import withAuth from '../../../hooks/withAuth';

import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import PersonalInfo from './cards/personalInfo';
import UserInfo from './cards/userInfo';
import PersonalProfiles from './cards/personalProfiles';
import ProfileAccess from './cards/profileAccess';

import { getUserData } from '../../../services/profile/userProfileService';

const UserProfiles = () => {
  const [selectedPersonalProfile, setSelectedPersonalProfile] = useState(null);
  const [userCpf, setUserCpf] = useState('');
  const [personalProfiles, setPersonalProfiles] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('authToken'));
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshAllComponents = () => {
    setUserCpf(''); // Reseta CPF do usuário
    setPersonalProfiles([]); // Reseta perfis pessoais
    setSelectedPersonalProfile(null); // Reseta perfil selecionado
    setRefreshKey(oldKey => oldKey + 1); // Força a atualização dos componentes
  };

  useEffect(() => {
    const handleTokenChange = () => {
      const newToken = localStorage.getItem('authToken');
      if (newToken !== token) {
        setToken(newToken);
        refreshAllComponents(); // Força a atualização dos componentes
      }
    };

    window.addEventListener('storage', handleTokenChange);
    return () => {
      window.removeEventListener('storage', handleTokenChange);
    };
  }, [token]);

useEffect(() => {
  let active = true;
  const fetchUserData = async () => {
    const data = await getUserData();
    if (active && data) {
      setUserCpf(data.cpf_usuario);
    }
  };

  if (token) {
    fetchUserData();
  }

  return () => { active = false; };
}, [token]);

  useEffect(() => {
    if (userCpf && personalProfiles.length > 0) {
      const userProfile = personalProfiles.find(profile => profile.cpf === userCpf);
      if (userProfile) {
        setSelectedPersonalProfile(userProfile.cpf);
      } else {
        setSelectedPersonalProfile(userCpf); // Se o perfil do usuário não for encontrado, define o próprio CPF.
      }
    }
  }, [personalProfiles, userCpf]);

  const handleSelectPersonalProfile = (cpf) => {
    setSelectedPersonalProfile(cpf);
  };

  const refreshProfileAccess = () => {
    setRefreshKey(oldKey => oldKey + 1); // Atualiza o estado de controle
  };

  return (
    <div className="profileContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="profileContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Perfil do Usuário
            </Typography>
          </Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={7}>
              {userCpf && (
                <PersonalInfo 
                  cpf={selectedPersonalProfile || userCpf} 
                  token={token} 
                  key={`personalInfo-${refreshKey}`}  // Garantir unicidade combinando nome com refreshKey
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <UserInfo token={token} key={`userInfo-${refreshKey}`} />
              <PersonalProfiles 
                onSelectProfile={handleSelectPersonalProfile} 
                selectedProfile={selectedPersonalProfile}
                setPersonalProfiles={setPersonalProfiles} 
                personalProfiles={personalProfiles} 
                refreshProfileAccess={refreshProfileAccess} 
                token={token} 
                key={`personalProfiles-${refreshKey}`}  // Garantir unicidade combinando nome com refreshKey
              />
              <ProfileAccess 
                userCpf={userCpf} 
                token={token} 
                refreshKey={refreshKey} 
                refreshProfileAccess={refreshProfileAccess}  // Passa a função para o componente
                key={`profileAccess-${refreshKey}`}  // Garantir unicidade combinando nome com refreshKey
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withAuth(UserProfiles);
