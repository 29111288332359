// src/modules/finance/transactions/cards/transactionInfo.js

import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, MenuItem,  Box, Grid } from '@mui/material';

import { tiposTransacao, statusPagamento } from '../../../../config/constants';
import { TitularSelector,AccountSelector, CreditCardSelector, OperationSelector } from '../../../../utils/formUtils';

import { getTransactionByID, updateTransaction } from '../../../../services/finance/transactionService';
import { getCompanyData } from '../../../../services/profile/companyProfileService';
import { getProfileDataByCpf } from '../../../../services/profile/userProfileService';
import { checkOwnership } from '../../../../services/auth/authService';

const TransactionInfo = ({ transactionId, onUpdateTransactionList }) => {
  const [transactionData, setTransactionData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [titularName, setTitularName] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showParcelasDetails, setShowParcelasDetails] = useState(false);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        setErrorMessage('');
        setSuccessMessage('');
        const transaction = await getTransactionByID(transactionId);
        const formattedData = {
          ...transaction,
          nome_resumido: transaction.nome_resumido || '',
          tipo_transacao: transaction.tipo_transacao || '',
          numero_documento: transaction.numero_documento || '',
          valor: transaction.valor || '',
          parcelas: transaction.parcelas,
          parcela_detalhes: transaction.parcela_detalhes || [],
          id_conta_bancaria: transaction.id_conta_bancaria || '',
          id_cartao: transaction.id_cartao || '',
          data_hora: transaction.data_hora ? transaction.data_hora.split('T')[0] : new Date().toISOString().split('T')[0],
          descricao: transaction.descricao || '',
          notas_adicionais: transaction.notas_adicionais || '',
          operacao_financeira: transaction.operacao_financeira || '',
          titular_cpf: transaction.titular_cpf || '',
          titular_cnpj: transaction.titular_cnpj || '',
        };

        // Garante que todas as parcelas tenham status_pagamento 'pago' se for transação com cartão
        if (formattedData.id_cartao) {
          formattedData.parcela_detalhes = formattedData.parcela_detalhes.map(parcela => ({
            ...parcela,
            status_pagamento: 'pago'
          }));
        }

        setTransactionData(formattedData);
        setOriginalData(formattedData);
        const isOwnerResult = await checkOwnership(formattedData);
        setIsOwner(isOwnerResult);
        if (formattedData.titular_cpf) {
          await fetchTitularName(formattedData.titular_cpf);
        } else if (formattedData.titular_cnpj) {
          await fetchTitularCompanyName(formattedData.titular_cnpj);
        }
        setLoading(false);
      } catch (err) {
        setError('Erro ao buscar dados da transação');
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, [transactionId]);

  const fetchTitularName = async (cpf) => {
    try {
      const profileData = await getProfileDataByCpf(cpf);
      setTitularName(`${profileData.nome} ${profileData.sobrenome}`);
    } catch (err) {
      setError('Erro ao buscar dados do titular');
    }
  };

  const fetchTitularCompanyName = async (cnpj) => {
    try {
      const companyData = await getCompanyData(cnpj);
      setTitularName(companyData.razao_social);
    } catch (err) {
      setError('Erro ao buscar dados da empresa titular');
    }
  };

  const calcularParcelas = (valor, numeroParcelas, dataTransacao) => {
    const valorTotal = parseFloat(valor);
    const numParcelas = parseInt(numeroParcelas, 10);
    let dataInicial;

    // Verifica se a data é válida antes de criar o objeto Date
    if (dataTransacao && dataTransacao.length === 10) {
      const [ano, mes, dia] = dataTransacao.split('-');
      dataInicial = new Date(ano, mes - 1, dia);
      if (isNaN(dataInicial.getTime())) {
        // Se a data for inválida, use a data atual
        dataInicial = new Date();
      }
    } else {
      // Se a data não estiver completa, use a data atual
      dataInicial = new Date();
    }
    
    if (numParcelas > 0 && !isNaN(valorTotal) && valorTotal > 0) {
      const valorParcelaExato = valorTotal / numParcelas;
      const valorParcelaArredondado = Math.floor(valorParcelaExato * 100) / 100;
      const diferencaTotal = valorTotal - (valorParcelaArredondado * numParcelas);
      
      const novasParcelas = Array.from({ length: numParcelas }, (_, index) => {
        let valorParcela = valorParcelaArredondado;
        if (index === 0) {
          valorParcela = (valorParcelaArredondado + diferencaTotal).toFixed(2);
        }
        const dataVencimento = new Date(dataInicial);
        dataVencimento.setMonth(dataVencimento.getMonth() + index);
        return {
          numero_parcela: index + 1,
          valor_parcela: parseFloat(valorParcela).toFixed(2),
          data_vencimento: dataVencimento.toISOString().split('T')[0],
          numero_documento: transactionData.numero_documento,
          status_pagamento: transactionData.id_cartao ? 'pago' : ''
        };
      });

      return novasParcelas;
    }
    return [];
  };

  const handleChange = (event) => {
    let name, value;
    
    if (event.target) {
      // Para eventos normais de input
      ({ name, value } = event.target);
    } else {
      // Para seletores personalizados que passam o nome e o valor diretamente
      ({ name, value } = event);
    }

    let updatedData = { ...transactionData, [name]: value };

    if (name === 'tipo_transacao') {
      if (value === 'receita' || value === 'despesa') {
        // Mudando para transação simples
        updatedData.parcelas = '1';
        updatedData.parcela_detalhes = [{
          numero_parcela: 1,
          valor_parcela: updatedData.valor,
          data_vencimento: updatedData.data_hora,
          numero_documento: updatedData.numero_documento,
          status_pagamento: updatedData.id_cartao ? 'pago' : ''
        }];
      } else if (value === 'receita_parcelada' || value === 'despesa_parcelada') {
        // Mudando para transação parcelada
        updatedData.parcelas = updatedData.parcelas || '2'; // Definindo um mínimo de 2 parcelas
        updatedData.parcela_detalhes = calcularParcelas(
          updatedData.valor,
          updatedData.parcelas,
          updatedData.data_hora
        );
      }
    }

    if (name === 'id_conta_bancaria' && value) {
      updatedData.id_cartao = '';
      updatedData.parcela_detalhes = updatedData.parcela_detalhes.map(parcela => ({ ...parcela, status_pagamento: '' }));
    } else if (name === 'id_cartao' && value) {
      updatedData.id_conta_bancaria = '';
      updatedData.parcela_detalhes = updatedData.parcela_detalhes.map(parcela => ({ ...parcela, status_pagamento: 'pago' }));
    }

    if (name === 'valor' || name === 'parcelas' || name === 'data_hora') {
      // Só recalcula as parcelas se todos os campos necessários estiverem preenchidos
      if (updatedData.valor && updatedData.parcelas && updatedData.data_hora) {
        updatedData.parcela_detalhes = calcularParcelas(
          updatedData.valor,
          updatedData.parcelas,
          updatedData.data_hora
        );
      }
    }

    setTransactionData(updatedData);
    setIsChanged(JSON.stringify(updatedData) !== JSON.stringify(originalData));
  };

  const getModifiedFields = () => {
    const modifiedFields = {};
    Object.keys(transactionData).forEach((key) => {
      if (transactionData[key] !== originalData[key]) {
        modifiedFields[key] = transactionData[key];
      }
    });
    return modifiedFields;
  };

  const handleSaveChanges = async () => {
    const modifiedFields = getModifiedFields();
    if (Object.keys(modifiedFields).length > 0) {
      try {
        const updatedTransaction = await updateTransaction(transactionId, modifiedFields);
        setOriginalData(transactionData);
        setIsChanged(false);
        setSuccessMessage('Dados atualizados com sucesso!');
        setErrorMessage('');

        if (onUpdateTransactionList) {
          onUpdateTransactionList(updatedTransaction);
        }
      } catch (err) {
        setErrorMessage('Erro ao salvar alterações');
        setSuccessMessage('');
      }
    }
  };

  const handleParcelaChange = (index, field, value) => {
    const updatedParcelas = [...transactionData.parcela_detalhes];
    updatedParcelas[index] = { ...updatedParcelas[index], [field]: value };
    setTransactionData({ ...transactionData, parcela_detalhes: updatedParcelas });
    setIsChanged(true);
  };

  const renderTransactionFields = () => {
    switch (transactionData.tipo_transacao) {
      case 'receita':
      case 'despesa':
        return renderSingleParcelaDetails();
      case 'receita_parcelada':
      case 'despesa_parcelada':
        return (
          <>
            {renderParcelasInput()}
            {showParcelasDetails && renderParcelasDetails()}
          </>
        );
      case 'transferencia':
        return renderTransferenciaFields();
      case 'pagamento_cartao':
        return renderPagamentoCartaoFields();
      default:
        return null;
    }
  };

  const renderSingleParcelaDetails = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '10px' }}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Data Vencimento"
          variant="outlined"
          type="date"
          value={transactionData.parcela_detalhes[0]?.data_vencimento ? transactionData.parcela_detalhes[0].data_vencimento.split('T')[0] : ''}
          name="data_vencimento"
          onChange={(e) => handleParcelaChange(0, 'data_vencimento', e.target.value)}
          size="small"
          disabled={!isOwner}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Número Documento"
          variant="outlined"
          value={transactionData.parcela_detalhes[0]?.numero_documento || ''}
          name="numero_documento"
          onChange={(e) => handleParcelaChange(0, 'numero_documento', e.target.value)}
          size="small"
          disabled={!isOwner}
        />
      </Grid>
      {transactionData.id_conta_bancaria && !transactionData.id_cartao && (
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            label="Status de Pagamento"
            variant="outlined"
            value={transactionData.parcela_detalhes[0]?.status_pagamento || ''}
            name="status_pagamento"
            onChange={(e) => handleParcelaChange(0, 'status_pagamento', e.target.value)}
            size="small"
            disabled={!isOwner}
          >
            {statusPagamento.map((status) => (
              <MenuItem key={status.valor} value={status.valor}>
                {status.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );

  const renderParcelasInput = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Número de Parcelas"
          variant="outlined"
          type="number"
          inputProps={{ min: 1, step: 1 }}
          value={transactionData.parcelas}
          name="parcelas"
          onChange={handleChange}
          required
          disabled={!isOwner}
        />
      </Grid>
      <Grid item xs={12} sm={8} style={{ marginTop: '8px' }}>
        <Button
          variant="outlined"
          onClick={() => setShowParcelasDetails(!showParcelasDetails)}
        >
          {showParcelasDetails ? "Ocultar" : "Exibir"} Detalhes das Parcelas
        </Button>
      </Grid>
    </Grid>
  );

  const renderParcelasDetails = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      {transactionData.parcela_detalhes.map((parcela, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className="parcelaTitle" style={{ fontWeight: 'bold', marginBottom: '-8px' }}>
              Parcela {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Data Vencimento"
                  variant="outlined"
                  type="date"
                  value={parcela.data_vencimento ? parcela.data_vencimento.split('T')[0] : ''}
                  onChange={(e) => handleParcelaChange(index, 'data_vencimento', e.target.value)}
                  disabled={!transactionData.valor}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Número Documento"
                  variant="outlined"
                  value={parcela.numero_documento}
                  onChange={(e) => handleParcelaChange(index, 'numero_documento', e.target.value)}
                  disabled={!transactionData.valor}
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Valor"
                  variant="outlined"
                  type="number"
                  value={parcela.valor_parcela}
                  onChange={(e) => handleParcelaChange(index, 'valor_parcela', parseFloat(e.target.value))}
                  disabled={!transactionData.valor}
                  size="small"
                  required
                />
              </Grid>
              {transactionData.id_conta_bancaria && !transactionData.id_cartao && (
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    label="Status de Pagamento"
                    variant="outlined"
                    value={parcela.status_pagamento || ''}
                    onChange={(e) => handleParcelaChange(index, 'status_pagamento', e.target.value)}
                    size="small"
                    required
                    disabled={!isOwner}
                  >
                    {statusPagamento.map((status) => (
                      <MenuItem key={status.valor} value={status.valor}>
                        {status.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );

  const renderTransferenciaFields = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      <Grid item xs={12} sm={4}>
        <TitularSelector
          value={transactionData.titular_destino}
          onChange={(value) => handleChange({ name: 'titular_destino', value })}
          label="Titular de Destino"
          disabled={!isOwner}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AccountSelector
          value={transactionData.id_conta_bancaria_destino}
          onChange={(value) => handleChange({ name: 'id_conta_bancaria_destino', value })}
          titular={transactionData.titular_destino}
          label="Conta Destino"
          disabled={!isOwner}
        />
      </Grid>
      {transactionData.id_conta_bancaria && !transactionData.id_cartao && (
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Status de Pagamento"
            variant="outlined"
            value={transactionData.parcela_detalhes[0]?.status_pagamento || ''}
            name="status_pagamento"
            onChange={(e) => handleParcelaChange(0, 'status_pagamento', e.target.value)}
            disabled={!isOwner}
          >
            {statusPagamento.map((status) => (
              <MenuItem key={status.valor} value={status.valor}>
                {status.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );

  const renderPagamentoCartaoFields = () => (
    <Grid container spacing={2} style={{ marginLeft: '20px', marginTop: '0px' }}>
      <Grid item xs={12} sm={4}>
        <TitularSelector
          value={transactionData.titular_destino}
          onChange={(value) => handleChange({ name: 'titular_destino', value })}
          label="Titular do Cartão"
          disabled={!isOwner}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CreditCardSelector
          value={transactionData.id_cartao_destino}
          onChange={(value) => handleChange({ name: 'id_cartao_destino', value })}
          titular={transactionData.titular_destino}
          label="Cartão a Pagar"
          disabled={!isOwner}
        />
      </Grid>
      {transactionData.id_conta_bancaria && !transactionData.id_cartao && (
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Status de Pagamento"
            variant="outlined"
            value={transactionData.parcela_detalhes[0]?.status_pagamento || ''}
            name="status_pagamento"
            onChange={(e) => handleParcelaChange(0, 'status_pagamento', e.target.value)}
            disabled={!isOwner}
          >
            {statusPagamento.map((status) => (
              <MenuItem key={status.valor} value={status.valor}>
                {status.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!transactionData) {
    return <Typography color="error">Transação não encontrada.</Typography>;
  }

  return (
    <div className="transactionInfoCard">
      <Typography variant="h5">Detalhes da Transação</Typography>

      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}
      <Box my={2}></Box>
      <Grid container spacing={2}>
                {/* Informações do Titular */}
                {transactionData.titular_cpf && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome do Titular"
                variant="outlined"
                value={titularName}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CPF do Titular"
                variant="outlined"
                value={transactionData.titular_cpf}
                fullWidth
                disabled
              />
            </Grid>
          </>
        )}
        {transactionData.titular_cnpj && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Razão Social da Empresa"
                variant="outlined"
                value={titularName}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CNPJ do Titular"
                variant="outlined"
                value={transactionData.titular_cnpj}
                fullWidth
                disabled
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <AccountSelector
            value={transactionData.id_conta_bancaria}
            onChange={(newValue) => handleChange({ target: { name: 'id_conta_bancaria', value: newValue } })}
            titular={transactionData.titular_cpf || transactionData.titular_cnpj}
            disabled={!isOwner}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CreditCardSelector
            value={transactionData.id_cartao}
            onChange={(newValue) => handleChange({ target: { name: 'id_cartao', value: newValue } })}
            titular={transactionData.titular_cpf || transactionData.titular_cnpj}
            disabled={!isOwner}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Nome da Transação"
            variant="outlined"
            value={transactionData.nome_resumido}
            name="nome_resumido"
            onChange={handleChange}
            required
            disabled={!isOwner}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Data da Transação"
            variant="outlined"
            type="date"
            value={transactionData.data_hora.split('T')[0]}
            name="data_hora"
            onChange={handleChange}
            required
            disabled={!isOwner}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="Tipo de Transação"
            variant="outlined"
            name="tipo_transacao"
            value={transactionData.tipo_transacao}
            onChange={handleChange}
            required
            disabled={!isOwner}
          >
            {tiposTransacao.map((tipo) => (
              <MenuItem key={tipo.valor} value={tipo.valor}>
                {tipo.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Valor"
            variant="outlined"
            type="number"
            value={transactionData.valor}
            name="valor"
            onChange={handleChange}
            required
            disabled={!isOwner}
          />
        </Grid>

        {renderTransactionFields()}

        <Grid item xs={12} sm={6}>
          <OperationSelector
            value={transactionData.operacao_financeira}
            onChange={(value) => handleChange({ 
              target: { 
                name: 'operacao_financeira',
                value 
              } 
            })}
            titular={transactionData.titular_cpf || transactionData.titular_cnpj}
            disabled={!isOwner}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descrição"
            variant="outlined"
            multiline
            rows={2}
            value={transactionData.descricao}
            name="descricao"
            onChange={handleChange}
            disabled={!isOwner}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Notas Adicionais"
            variant="outlined"
            multiline
            rows={3}
            value={transactionData.notas_adicionais}
            name="notas_adicionais"
            onChange={handleChange}
            disabled={!isOwner}
          />
        </Grid>
      </Grid>
      <Box my={2}></Box>
      {isChanged && isOwner && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
        >
          Salvar Alterações
        </Button>
      )}
    </div>
  );
};

export default TransactionInfo;
